.rtl {
  @media (max-width: 480px) {
    .border-end.bd-xs-e-0 {
      border-left: 0 !important;
      border-right: inherit !important;
    }

    .border-end.bd-xs-s-0 {
      border-right: 0 !important;
      border-left: inherit !important;
    }
  }

  @media (max-width: 991px) {
    .border-end.bd-md-e-0 {
      border-left: 0 !important;
      border-right: inherit !important;
    }
  }

  @media (max-width: 1024px) {
    .border-end.bd-lg-e-0 {
      border-left: 0 !important;
      border-right: inherit !important;
    }
  }

  @media (max-width: 1366px) {
    .border-end.bd-xl-e-0 {
      border-left: 0 !important;
      border-right: inherit !important;
    }
  }

  .panel-group1 .panel-collapse.collapse.show:before {
    left: 10px;
    right: inherit;
  }

  .nav.nav-item.header-icons.navbar-nav-right {
    padding-right: 0;
  }

  @media (min-width: 992px) {
    .float-md-end {
      float: left !important;
    }
  }

  @media (min-width: 992px) {
    &.horizontal .main-sidemenu .slide-right {
      right: initial !important;
      left: 0px;
      transform: rotate(180deg);
    }

    &.horizontal .main-sidemenu .slide-left {
      left: initial !important;
      right: 0px;
      transform: rotate(180deg);
    }
  }

  .button-icon i {
    float: right;
    margin-top: 1px;
  }

  @media (min-width: 992px) {
    &.horizontal .main-footer {
      padding: 0.9rem 1rem 0.9rem 1rem !important;
    }
  }

  &.transparent-theme {
    .sidebar.sidebar-right {
      box-shadow: none !important;
    }

    .app-sidebar {
      border-left: 1px solid $transparent-border;
      border-right: 0;
    }

    .main-sidebar-header {
      border-left-color: $transparent-border;
    }

    @media (min-width: 992px) {
      .main-header-left .btn {
        border-left: 1px solid $transparent-border  !important;
      }
    }
  }

  .la-angle-right:before {
    content: "\f104";
  }

  .la-angle-left:before {
    content: "\f105";
  }

  .pri-tabs-heading {
    .nav {
      padding-right: 0 !important;
    }
  }

  .demo_changer {
    z-index: 999999;
  }

  .prof-details {
    span {
      display: inline-flex;
    }

    .me-3 {
      margin-left: 1rem !important;
      margin-right: auto !important;
    }

    .me-2 {
      margin-left: 0.5rem !important;
      margin-right: auto !important;
    }
  }

  @media (min-width: 992px) {
    #checkoutsteps .checkoutline {
      margin: 0px 8%;
    }
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    left: 1px;
    right: inherit;
  }

  .table> :not(:first-child) {
    border-top: 1px solid #404353 !important;
  }

  @media (min-width: 1400px) {
    &.layout-boxed .app-sidebar {
      right: auto !important;
      left: inherit !important;
    }
  }

  &.closed-menu {
    @media (min-width: 992px) {
      &.app.sidenav-toggled {
        .app-content {
          margin-right: 0px !important;
        }

        .main-header {
          padding-right: 0px !important;
        }
      }

      .app-sidebar {
        position: fixed;
      }

      .side-header {
        position: fixed;
      }
    }
  }

  &.icontext-menu {
    @media (min-width: 992px) {
      &.app.sidenav-toggled {
        .main-header {
          padding-right: 110px !important;
          padding-left: inherit !important;
        }

        .app-content {
          margin-right: 110px;
          margin-left: inherit;
        }
      }
    }
  }

  &.hover-submenu {
    @media (min-width: 992px) {

      &.app.sidenav-toggled.sidenav-toggled-open .side-menu .slide .side-menu__item.active,
      &.app.sidenav-toggled .side-menu .slide .side-menu__item.active {
        padding: 11px 0px 11px 0px !important;
      }

      .side-menu .slide .side-menu__item {
        padding: 10px 22px 10px 20px !important;
      }

      &.app.sidenav-toggled-open .side-menu .slide .side-menu__item {
        text-align: center !important;
      }

      &.app.sidenav-toggled .app-sidebar {
        position: absolute !important;
      }

      .side-header {
        position: fixed;
      }

      .slide-item {
        padding: 8px 35px 8px 18px !important;
      }

      .slide-menu {
        border-radius: 5px 0px 0px 5px;
      }

      &.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
        right: 108px;
        left: inherit;
      }

      &.app.sidenav-toggled-open {
        .slide-menu {
          padding-right: 0 !important;
          right: 110px;
        }

        .side-menu__label {
          right: 0;
          left: inherit;
        }

        .side-menu .slide .side-menu__item.active {
          border-left: 0 !important;
          border-right: inherit;
        }

        .side-menu .slide .side-menu__item {
          padding: 11px 0px 11px 0px !important;
        }
      }

      &.app.sidenav-toggled {
        .main-header {
          padding-right: 110px;
          padding-left: inherit;
        }

        .app-content {
          margin-right: 110px;
          margin-left: inherit;
        }

        .app-sidebar {
          right: 0;
          left: inherit;
        }
      }
    }
  }

  &.hover-submenu1 {
    @media (min-width: 992px) {

      &.app.sidenav-toggled.sidenav-toggled-open .side-menu .slide .side-menu__item.active,
      &.app.sidenav-toggled .side-menu .slide .side-menu__item.active {
        padding: 11px 0px 11px 0px !important;
      }

      .side-menu .slide .side-menu__item {
        padding: 10px 22px 10px 20px !important;
      }

      &.app.sidenav-toggled-open .side-menu .slide .side-menu__item {
        text-align: center !important;
      }

      &.app.sidenav-toggled .app-sidebar {
        position: absolute !important;
      }

      .side-header {
        position: fixed;
      }

      .slide-item {
        padding: 8px 35px 8px 18px !important;
      }

      .slide-menu {
        border-radius: 5px 0px 0px 5px;
      }

      &.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
        right: 108px;
        left: inherit;
      }

      &.app.sidenav-toggled-open {
        .slide-menu {
          padding-right: 0 !important;
          right: 110px;
        }

        .side-menu__label {
          right: 0;
          left: inherit;
        }

        .side-menu .slide .side-menu__item.active {
          border-left: 0 !important;
          border-right: inherit;
        }

        .side-menu .slide .side-menu__item {
          padding: 11px 0px 11px 0px !important;
        }
      }

      &.app.sidenav-toggled {
        .main-header {
          padding-right: 110px;
          padding-left: inherit;
        }

        .app-content {
          margin-right: 110px;
          margin-left: inherit;
        }

        .app-sidebar {
          right: 0;
          left: inherit;
        }
      }
    }
  }


  @media (min-width: 992px) {
    &.horizontal .side-menu>li>a {
      padding: 17px 13px 17px 13px !important;
    }

    &.horizontal .main-sidemenu i.angle {
      left: 0;
      right: inherit;
      transform: rotate(270deg);
    }
  }

  ul.timeline {
    padding-right: 2rem !important;
    padding-left: inherit !important;
  }

  .dt-button.dropdown-item.buttons-columnVisibility.active {
    text-align: right;
  }

  .dt-button.dropdown-item.buttons-columnVisibility {
    text-align: right;
  }

  .btn-group>.btn-group:not(:first-child)>.btn,
  .btn-group>.btn:nth-child(n + 3),
  .btn-group> :not(.btn-check)+.btn {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .ql-snow .ql-tooltip {
    margin-right: 20rem;
    margin-left: inherit;
  }

  .richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li.inline {
    float: right;
  }

  .richText .richText-toolbar ul li a .richText-dropdown-outer .richText-dropdown .richText-dropdown-close {
    left: 10px;
    right: inherit;
  }

  .richText .richText-toolbar ul li {
    float: right;
  }

  .richText .richText-toolbar ul {
    padding-right: 0;
  }

  .note-dropdown-menu.dropdown-menu.note-check.dropdown-fontname.show {
    text-align: right !important;
  }

  .note-editor .note-toolbar .note-color .dropdown-toggle,
  .note-popover .popover-content .note-color .dropdown-toggle {
    padding-right: 5px !important;
    padding-left: inherit !important;
  }

  .was-validated .form-control:invalid,
  .form-control.is-invalid,
  input.form-control.parsley-error,
  textarea.form-control.parsley-error {
    padding-left: calc(1.5em + 0.75rem);
    padding-right: 0.75rem;
    background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
  }

  .form-select {
    padding: 0.375rem 0.75rem 0.375rem 2.25rem;
  }

  @media (min-width: 992px) {
    &.app.sidebar-mini.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
      padding: 11px 22px 11px 20px !important;
    }
  }

  .SumoSelect .select-all {
    border-radius: 3px 0px 0 3px;
    padding: 8px 35px 3px 0px;
  }

  .SumoSelect>.optWrapper.multiple>.options li.opt {
    padding-right: 35px;
  }

  .SumoSelect>.optWrapper.multiple>.options li.opt span,
  .SumoSelect .select-all>span {
    margin-right: -35px;
  }

  .dropify-wrapper .dropify-clear {
    left: 10px;
    right: inherit;
  }

  .intl-tel-input .flag-dropdown .country-list .flag1,
  .intl-tel-input .flag-dropdown .country-list .country-name {
    margin-left: 6px;
  }

  .intl-tel-input input {
    padding-right: 47px;
    border-radius: 0px 5px 5px 0px;
  }

  .intl-tel-input .flag-dropdown .selected-flag {
    border-radius: 0px 5px 5px 0px;
  }

  .form-select.is-invalid:not([multiple]):not([size]),
  .form-select.is-invalid:not([multiple])[size="1"],
  .was-validated .form-select:invalid:not([multiple]):not([size]),
  .was-validated .form-select:invalid:not([multiple])[size="1"] {
    padding-left: 4.125rem;
    padding-right: 0.75rem;
    background-position: left .75rem center, center left 2.25rem !important;
  }

  .was-validated .form-control:valid,
  .form-control.is-valid,
  input.form-control.parsley-success,
  textarea.form-control.parsley-success {
    padding-left: calc(1.5em + 0.75rem);
    padding-right: 0.75rem;
    background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
  }

  .input-group.has-validation>.dropdown-toggle:nth-last-child(n + 4),
  .input-group.has-validation> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .telephone-input .btn {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }

  .intl-tel-input .flag-dropdown .selected-flag .down-arrow {
    right: 20px;
    left: inherit;
  }

  .SumoSelect>.CaptionCont>span {
    padding-left: 30px;
    padding-right: inherit;
  }

  .SumoSelect>.CaptionCont>label {
    left: 0;
    right: inherit;
  }

  .SumoSelect>.CaptionCont>label>i:before {
    margin-right: 0.255em;
    margin-left: inherit;
    border-right: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
  }

  .form-select {
    background-position: left 0.75rem center !important;
  }

  .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3),
  .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-right: -1px;
    margin-left: inherit;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .main-icon-group,
  .icons-list-wrap {
    ul {
      padding-right: 0;
    }
  }

  .main-content-app {
    .option-dots {
      left: 15px;
      right: inherit;
    }
  }

  .chat-profile {
    padding: 8px !important;
  }

  .emai-img h6 small {
    float: left;
  }

  .product-details .media img {
    margin-left: 20px;
    margin-right: inherit;
  }

  &.error-page1 .demo-icon svg {
    left: 60px;
    right: inherit;
  }

  .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button.ff_fileupload_start_upload {
    margin-left: 0.5em;
    margin-right: inherit;
  }

  .select2-results__options {
    padding-right: 0rem;
    padding-left: inherit;
  }

  div.dataTables_wrapper div.dataTables_length label {
    text-align: right;
  }

  .carousel {
    overflow: hidden;
  }

  .carousel-control-prev {
    left: 0;
    right: inherit;
  }

  .carousel-control-next {
    right: 0;
    left: inherit;
  }

  .active.carousel-item-start,
  .carousel-item-prev:not(.carousel-item-end) {
    transform: translateX(100%) !important;
  }

  .active.carousel-item-end {
    transform: translateX(-100%) !important;
  }

  .carousel-item-next:not(.carousel-item-start) {
    transform: translateX(-100%) !important;
    transform: translateX(-100%) !important;
  }

  .accordion i {
    float: right;
  }

  .panel-heading1.collapsed::before {
    left: 10px;
    right: inherit;
  }

  .tabs-style-2 .main-nav-line .nav-link {
    padding: 0.7rem 2rem;
  }

  .main-nav-column .nav-link {
    padding: 7px 14px;
  }

  .alert-inner--icon {
    i {
      float: right;
      padding-left: 5px;
    }
  }

  .alert-heading i {
    float: right;
  }

  .alert-dismissible .btn-close {
    left: 0;
    right: inherit;
  }

  .main-content-left-mail {
    .card-header i {
      float: right;
    }
  }

  .tree li i {
    margin-left: 10px;
    margin-right: inherit;
    float: right;
    padding-top: 5px;
  }

  .dropdown-item i {
    float: right;
  }

  .dropdown-item {
    text-align: right;
  }

  .ps>.ps__rail-y {
    width: 3px;
  }

  @media (min-width: 992px) {
    .main-nav-line-chat .nav-link {
      padding: 8px 20px !important;
    }

    &.horizontal .main-sidemenu .slide.is-expanded i.angle {
      left: 0px !important;
      right: inherit;
    }

    &.horizontal .sub-slide .sub-slide-menu {
      right: 180px;
      left: inherit;
    }

    &.horizontal .sub-slide .sub-angle {
      left: -20px;
      right: auto;
      margin-left: 0 !important;
    }

    &.horizontal .horizontal-main .slide .slide-menu .slide-item:before,
    .horizontal .horizontal-main .slide .sub-slide-menu .slide-item:before {
      right: 15px;
      left: inherit;
      margin-left: 8px;
      margin-right: inherit;
    }

    &.horizontal .main-sidemenu i.angle {
      left: 0 !important;
      right: inherit;
    }

    &.horizontal .side-menu .slide .side-menu__item.active {
      border-left: none !important;
      border-right: inherit;
    }
  }

  .main-profile-menu.nav-link {
    padding: 0rem 0rem !important;
    margin: auto 6px;
  }

  @media (max-width: 991px) {
    &.app.sidenav-toggled .side-menu .side-menu__icon {
      margin-left: 13px !important;
      margin-right: 0 !important;
    }

    &.app .app-sidebar {
      right: -240px;
    }

    &.app.sidebar-gone.sidenav-toggled .app-sidebar {
      right: 0;
    }
  }

  .side-menu {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
  }

  .apexcharts-yaxis-title {
    transform: translate(-35px, 10px);
  }

  .apexcharts-tooltip-marker {
    margin-left: 10px;
    margin-right: inherit;
  }

  .sub-slide.is-expanded .sub-angle {
    transform: rotate(90deg);
  }

  .sub-slide2.is-expanded .sub-angle2 {
    transform: rotate(90deg);
  }

  .main-sidemenu .slide.is-expanded i.angle {
    left: 20px !important;
    right: inherit;
    transform: rotate(90deg);
  }

  .modal-header .btn-close {
    margin: -1rem auto -1rem -1rem;
  }

  @media (min-width: 992px) {

    .main-header>.container,
    .main-header>.container-fluid {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  @media (min-width: 992px) {
    &.app.sidebar-mini.sidenav-toggled-open .side-menu .slide .side-menu__item {
      padding: 10px 22px 10px 20px !important;
      text-align: right;
    }

    &.app.sidebar-mini.sidenav-toggled-open .side-menu .side-menu__icon {
      margin-left: 12px;
      margin-right: inherit;
    }

    &.app.sidenav-toggled .side-menu__item {
      padding: 11px 0 11px 0 !important;
    }
  }

  &.sidebar-mini.sidenav-toggled .side-menu .slide .side-menu__item.active {
    border-left: 0px !important;
    border-right: inherit !important;
  }

  .sidebar.sidebar-right {
    transform: translate(-100%, 0);
  }

  .sidebar.sidebar-right.sidebar-open {
    transform: translate(0, 0);
  }

  .sidebar .tabs-menu ul li a {
    padding: 8px 8px !important;
  }

  .sidebar-right {
    left: 0;
    right: inherit;
  }

  .fe-chevron-right:before {
    content: "\e92e";
  }

  .fe-chevron-left:before {
    content: "\e92f";
  }

  .accordion>.card:first-of-type {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .accordion>.card:last-of-type {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .accordion .card-header a[aria-expanded="true"] {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .accordion .collapse.show .card-body {
    border-radius: 0px 5px 5px 0px;
  }

  .main-accordion .accordion-body {
    padding: 0 20px 20px;
  }

  .accor div a[aria-expanded="true"] {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  #accordion11 .panel-body {
    border-radius: 0px 0px 5px 5px;
  }

  .panel-heading2.collapsed:before {
    left: 10px;
    right: inherit !important;
  }

  .panel-group2 .panel-collapse.collapse.show:before {
    left: 10px;
    right: inherit;
  }

  .collapse.show .accstyle {
    border-radius: 0px 0px 5px 5px;
  }

  .alert-dismissible {
    padding-left: 3.8125rem;
  }

  .badge-pill {
    padding-right: 8px;
    padding-left: 8px;
  }

  .badge {
    padding: 4px 6px 4px 6px;
  }

  @media (prefers-reduced-motion: reduce) {
    .badge {
      -webkit-transition: none;
      transition: none;
    }
  }

  .badge-pill {
    padding-left: 0.6em;
    padding-right: 0.6em;
  }

  .breadcrumb-4 ol {
    text-align: left;
  }

  .breadcrumb-4 li {
    text-align: left;
  }

  .breadcrumb-item+.breadcrumb-item {
    padding-right: 0.5rem;
  }

  .breadcrumb-item+.breadcrumb-item::before {
    padding-left: 0.5rem;
  }

  .breadcrumb-item+.breadcrumb-item::before {
    padding-left: 0.5rem;
  }

  .btn-group {
    border-radius: 5px !important;
  }

  .btn-group>.btn:not(:first-child),
  .sp-container .btn-group>button:not(:first-child) {
    margin-right: -1px;
    margin-left: 0;
  }

  .btn-group>.btn-group:not(:first-child) {
    margin-right: -1px;
  }

  .btn-group>.btn:not(:first-child),
  .note-editor.note-frame .btn-group>.btn-group:not(:first-child)>.btn {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }


  .btn-group>.btn:not(:first-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .btn-group>.btn:not(:first-child):not(.dropdown-toggle) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .btn-rounded {
    border-radius: 50px !important;
  }

  .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .btn-sm+.dropdown-toggle-split,
  .btn-group-sm>.btn+.dropdown-toggle-split,
  .sp-container .btn-group-sm>button+.dropdown-toggle-split {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }

  .btn-lg+.dropdown-toggle-split,
  .btn-group-lg>.btn+.dropdown-toggle-split,
  .sp-container .btn-group-lg>button+.dropdown-toggle-split {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .sp-container .btn-group-vertical>button:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical>.btn-group:not(:last-child)>.btn,
  .sp-container .btn-group-vertical>.btn-group:not(:last-child)>button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .btn-group-vertical>.btn:not(:first-child),
  .sp-container .btn-group-vertical>button:not(:first-child),
  .btn-group-vertical>.btn-group:not(:first-child)>.btn,
  .sp-container .btn-group-vertical>.btn-group:not(:first-child)>button {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .btn-with-icon i {
    margin-left: 5px;
  }

  .btn-icon-list .btn+.btn,
  .btn-icon-list .sp-container button+.btn {
    margin-right: 5px;
  }

  .sp-container .btn-icon-list button+.btn,
  .btn-icon-list .sp-container .btn+button,
  .sp-container .btn-icon-list .btn+button,
  .btn-icon-list .sp-container button+button,
  .sp-container .btn-icon-list button+button {
    margin-right: 5px;
  }

  .btn-list>.btn:not(:last-child),
  .btn-list>.dropdown:not(:last-child) {
    margin-left: 0.4rem;
    margin-right: inherit;
  }

  .btn-animation .btn-loaders:after {
    left: 10px !important;
    right: inherit !important;
  }

  .btn-animation .btn-spiner:after {
    left: 12px !important;
    right: inherit !important;
  }

  .btn-animation .loading {
    margin-left: 30px;
  }

  .card>hr {
    margin-left: 0;
    margin-right: 0;
  }

  .card>.list-group:first-child .list-group-item:first-child {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

  .card>.list-group:last-child .list-group-item:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .card-header-tabs {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }

  .card-header-pills {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }

  .card-img-overlay {
    left: 0;
    right: 0;
  }

  .card-img-top {
    border-top-right-radius: calc(5px - 1px);
    border-top-left-radius: calc(5px - 1px);
  }

  .card-img-bottom {
    border-bottom-left-radius: calc(10px - 1px);
    border-bottom-right-radius: calc(10px - 1px);
  }

  @media (min-width: 576px) {
    .card-deck {
      margin-left: -15px;
      margin-right: -15px;
    }

    .card-deck .card {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  @media (min-width: 576px) {
    .card-group>.card+.card {
      margin-right: 0;
      border-right: 0;
    }

    .card-group>.card:not(:last-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .card-group>.card:not(:last-child) .card-img-top,
    .card-group>.card:not(:last-child) .card-header {
      border-top-left-radius: 0;
    }

    .card-group>.card:not(:last-child) .card-img-bottom,
    .card-group>.card:not(:last-child) .card-footer {
      border-bottom-left-radius: 0;
    }

    .card-group>.card:not(:first-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .card-group>.card:not(:first-child) .card-img-top,
    .card-group>.card:not(:first-child) .card-header {
      border-top-right-radius: 0;
    }

    .card-group>.card:not(:first-child) .card-img-bottom,
    .card-group>.card:not(:first-child) .card-footer {
      border-bottom-right-radius: 0;
    }
  }

  .card-header {
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
  }

  .card-footer {
    border-bottom-right-radius: 7px !important;
    border-bottom-left-radius: 7px !important;
  }

  .card-category {
    margin: 0 0 0.5rem;
  }

  .card-img-top.card-img-bottom {
    border-bottom-right-radius: calc(10px - 1px);
    border-bottom-left-radius: calc(10px - 1px);
  }

  .square-box div:nth-child(1) {
    right: 42%;
  }

  .square-box div:nth-child(2) {
    right: 50%;
  }

  .square-box div:nth-child(4) {
    right: 60%;
  }

  .square-box div:nth-child(3) {
    right: 6%;
  }

  .square-box div:nth-child(5) {
    right: 10%;
  }

  .square-box div:nth-child(6) {
    right: 70%;
  }

  .square-box div:nth-child(7) {
    right: 80%;
  }

  .square-box div:nth-child(8) {
    right: 25%;
  }

  .square-box div:nth-child(9) {
    right: 80%;
  }

  .square-box div:nth-child(10) {
    right: 72%;
  }

  .square-box div:nth-child(11) {
    right: 30%;
  }

  .square-box div:nth-child(12) {
    right: 16%;
  }

  .square-box div:nth-child(13) {
    right: 3%;
  }

  .square-box div:nth-child(14) {
    right: 55%;
  }

  .square-box div:nth-child(15) {
    right: 90%;
  }

  .card-fullscreen {
    right: 0;
    left: 0;
  }

  .card-title:before {
    right: 11px;
  }

  .custom-title:before {
    right: 11px;
  }

  #thumbcarousel .carousel-control-prev {
    right: 0;
  }

  #thumbcarousel .carousel-control-next {
    left: 0;
  }

  .carousel-item {
    float: right;
    margin-left: -100%;
    margin-right: inherit;
  }

  .carousel-indicators {
    padding-right: 0;
  }

  dd {
    margin-right: 0;
  }

  caption {
    text-align: right;
  }

  .list-unstyled,
  .list-inline {
    padding-right: 0;
  }

  .list-inline-item:not(:last-child) {
    margin-left: 0.5rem;
  }

  .float-start {
    float: right !important;
  }

  .float-end {
    float: left !important;
  }

  @media (min-width: 576px) {
    .float-sm-left {
      float: right !important;
    }

    .float-sm-right {
      float: left !important;
    }
  }

  @media (min-width: 992px) {
    .float-md-left {
      float: left !important;
    }

    .float-md-right {
      float: right !important;
    }
  }

  @media (min-width: 992px) {
    .float-lg-left {
      float: left !important;
    }

    .float-lg-right {
      float: right !important;
    }
  }

  @media (min-width: 1200px) {
    .float-xl-left {
      float: left !important;
    }

    .float-xl-right {
      float: right !important;
    }
  }

  .dropdown-toggle::after {
    margin-right: 0.255em;
    border-left: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
  }

  .dropdown-toggle:empty::after {
    margin-right: 0;
  }

  .dropdown-menu-start {
    left: auto;
    right: 0;
  }

  .dropdown-menu-end {
    left: 0;
    right: auto;
  }

  @media (min-width: 576px) {
    .dropdown-menu-sm-left {
      left: auto;
      right: 0;
    }

    .dropdown-menu-sm-right {
      left: 0;
      right: auto;
    }
  }

  @media (min-width: 992px) {
    .dropdown-menu-md-left {
      left: auto;
      right: 0;
    }

    .dropdown-menu-md-right {
      left: 0;
      right: auto;
    }
  }

  @media (min-width: 992px) {
    .dropdown-menu-lg-left {
      left: auto;
      right: 0;
    }

    .dropdown-menu-lg-right {
      left: 0;
      right: auto;
    }
  }

  @media (min-width: 1200px) {
    .dropdown-menu-xl-left {
      left: auto;
      right: 0;
    }

    .dropdown-menu-xl-right {
      left: 0;
      right: auto;
    }
  }

  .dropup .dropdown-toggle::after {
    margin-right: 0.255em;
    border-left: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
  }

  .dropup .dropdown-toggle:empty::after {
    margin-right: 0;
  }

  .dropright .dropdown-menu {
    left: auto;
    right: 100%;
    margin-right: 0.125rem;
  }

  .dropright .dropdown-toggle::after {
    margin-right: 0.255em;
    border-left: 0;
    border-right: 0.3em solid;
  }

  .dropright .dropdown-toggle:empty::after {
    margin-right: 0;
  }

  .dropleft .dropdown-menu {
    left: 100%;
    right: auto;
    margin-left: 0.125rem;
  }

  .dropleft .dropdown-toggle::after {
    margin-right: 0.255em;
  }

  .dropleft .dropdown-toggle::before {
    margin-left: 0.255em;
    border-left: 0.3em solid;
  }

  .dropleft .dropdown-toggle:empty::after {
    margin-right: 0;
  }

  .dropdown-menu[data-popper-placement^="top"],
  .dropdown-menu[data-popper-placement^="right"],
  .dropdown-menu[data-popper-placement^="bottom"],
  .dropdown-menu[data-popper-placement^="left"] {
    left: auto;
  }

  .dropdown-toggle-split::after {
    margin-right: 0;
  }

  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-right: 0;
  }

  .dropleft .dropdown-toggle-split::before {
    margin-left: 0;
  }

  .user-lock .dropdown {
    left: 20px;
  }

  .form-check {
    padding-right: 1.25rem;
  }

  .form-check-input {
    margin-right: -1.25rem;
  }

  .form-check-inline {
    padding-right: 0;
    margin-left: 0.75rem;
  }

  .form-check-inline .form-check-input {
    margin-left: 0.3125rem;
    margin-right: 0;
  }

  .was-validated textarea.form-control:valid {
    padding-left: calc(1.5em + 0.75rem);
    padding-right: 0.75rem;
  }

  textarea.form-control.is-valid,
  textarea.form-control.parsley-success {
    padding-left: calc(1.5em + 0.75rem);
    padding-right: 0.75rem;
  }

  .was-validated .custom-select:valid,
  .custom-select.is-valid,
  input.custom-select.parsley-success,
  textarea.custom-select.parsley-success {
    padding-left: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  }

  .was-validated .form-control:invalid,
  .form-control.is-invalid,
  input.form-control.parsley-error,
  textarea.form-control.parsley-error {
    padding-left: calc(1.5em + 0.75rem);
  }

  .was-validated textarea.form-control:invalid {
    padding-left: calc(1.5em + 0.75rem);
  }

  textarea.form-control.is-invalid,
  textarea.form-control.parsley-error {
    padding-left: calc(1.5em + 0.75rem);
  }

  .was-validated .custom-select:invalid,
  .custom-select.is-invalid,
  input.custom-select.parsley-error,
  textarea.custom-select.parsley-error {
    padding-left: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  }

  @media (min-width: 576px) {
    .form-inline .form-check {
      padding-right: 0;
    }

    .form-inline .form-check-input {
      margin-left: 0.25rem;
      margin-right: 0;
    }
  }

  .main-header-center .form-control::-webkit-input-placeholder {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .main-header-center .form-control:-ms-input-placeholder {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .main-header-center .form-control::-ms-input-placeholder {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .main-header-center .form-control::placeholder {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .browse-file.form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .file-browser .btn-default {
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
  }

  .input-group>.form-control+.form-control,
  .input-group>.form-control+.custom-select,
  .input-group>.form-control+.custom-file {
    margin-right: -1px;
  }

  .input-group>.form-control-plaintext+.form-control,
  .input-group>.form-control-plaintext+.custom-select,
  .input-group>.form-control-plaintext+.custom-file {
    margin-right: -1px;
  }

  .input-group>.custom-select+.form-control,
  .input-group>.custom-select+.custom-select,
  .input-group>.custom-select+.custom-file {
    margin-right: -1px;
  }

  .input-group>.custom-file+.form-control,
  .input-group>.custom-file+.custom-select,
  .input-group>.custom-file+.custom-file {
    margin-right: -1px;
  }

  .input-group>.form-control:not(:last-child),
  .input-group>.custom-select:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group>.form-control:not(:first-child),
  .input-group>.custom-select:not(:first-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group>.custom-file:not(:last-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group>.custom-file:not(:last-child) .custom-file-label::after {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group-prepend .btn+.btn,
  .input-group-prepend .sp-container button+.btn {
    margin-right: -1px;
  }

  .sp-container .input-group-prepend button+.btn,
  .input-group-prepend .sp-container .btn+button,
  .sp-container .input-group-prepend .btn+button,
  .input-group-prepend .sp-container button+button,
  .sp-container .input-group-prepend button+button {
    margin-right: -1px;
  }

  .input-group-prepend .btn+.input-group-text,
  .input-group-prepend .sp-container button+.input-group-text {
    margin-right: -1px;
  }

  .sp-container .input-group-prepend button+.input-group-text {
    margin-right: -1px;
  }

  .input-group-prepend .input-group-text+.input-group-text,
  .input-group-prepend .input-group-text+.btn {
    margin-right: -1px;
  }

  .input-group-prepend .sp-container .input-group-text+button {
    margin-right: -1px;
  }

  .sp-container .input-group-prepend .input-group-text+button {
    margin-right: -1px;
  }

  .input-group-append .btn+.btn,
  .input-group-append .sp-container button+.btn {
    margin-right: -1px;
  }

  .sp-container .input-group-append button+.btn,
  .input-group-append .sp-container .btn+button,
  .sp-container .input-group-append .btn+button,
  .input-group-append .sp-container button+button,
  .sp-container .input-group-append button+button {
    margin-right: -1px;
  }

  .input-group-append .btn+.input-group-text,
  .input-group-append .sp-container button+.input-group-text {
    margin-right: -1px;
  }

  .sp-container .input-group-append button+.input-group-text {
    margin-right: -1px;
  }

  .input-group-append .input-group-text+.input-group-text,
  .input-group-append .input-group-text+.btn {
    margin-right: -1px;
  }

  .input-group-append .sp-container .input-group-text+button {
    margin-right: -1px;
  }

  .sp-container .input-group-append .input-group-text+button {
    margin-right: -1px;
  }

  .input-group-prepend {
    margin-left: -1px;
  }

  .input-group-append {
    margin-right: -1px;
  }

  .input-group-lg>.custom-select,
  .input-group-sm>.custom-select {
    padding-left: 1.75rem;
  }

  .input-group>.input-group-prepend>.btn,
  .sp-container .input-group>.input-group-prepend>button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group>.input-group-prepend>.input-group-text,
  .input-group>.input-group-append:not(:last-child)>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .input-group>.input-group-append>.input-group-text,
  .input-group>.input-group-prepend:not(:first-child)>.btn {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .sp-container .input-group>.input-group-append:not(:last-child)>button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group>.input-group-append:not(:last-child)>.input-group-text,
  .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .sp-container .input-group>.input-group-append:last-child>button:not(:last-child):not(.dropdown-toggle) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group>.input-group-append>.btn {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .sp-container .input-group>.input-group-append>button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .sp-container .input-group>.input-group-prepend:not(:first-child)>button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group>.input-group-prepend:not(:first-child)>.input-group-text,
  .input-group>.input-group-prepend:first-child>.btn:not(:first-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .sp-container .input-group>.input-group-prepend:first-child>button:not(:first-child),
  .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group>.input-group-append>.input-group-text,
  .input-group>.input-group-prepend:not(:first-child)>.btn {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }

  .modal {
    right: 0;
  }

  .modal-backdrop {
    right: 0;
  }

  .modal-header {
    border-top-right-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
  }

  .modal-header .close {
    margin: -1rem auto -1rem -1rem;
  }

  .modal-footer {
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }

  .modal-footer> :not(:first-child) {
    margin-right: 0.25rem;
  }

  #modaldemo8 .modal-footer> :not(:first-child) {
    margin-left: 14px;
  }

  .modal-footer> :not(:last-child) {
    margin-left: 0.25rem;
  }

  .nav-tabs .nav-link+.nav-link {
    margin-right: 1px;
  }

  .nav-link {
    padding: 0.2rem 0.2rem 0rem 0rem;
  }

  .nav-tabs .nav-link {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

  .nav-tabs .dropdown-menu {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  /* ###### 5.7 Nav   ###### */

  @media (min-width: 768px) {
    .main-nav .nav-link+.nav-link {
      padding-right: 15px;
      padding-left: inherit;
      margin-right: 15px;
      margin-left: inherit;
      border-right: 1px solid #ededf5;
      border-left: inherit;
    }
  }

  .main-nav-column .nav-link i {
    margin-left: 12px;
    margin-right: inherit;
  }

  .main-nav-column .nav-link span {
    margin-right: auto;
    margin-left: inherit;
  }

  .main-nav-column .nav-link.active::before {
    right: -28px;
  }

  .main-nav-tabs {
    padding: 15px 15px 0;
  }

  .main-nav-tabs .lSSlideOuter {
    padding-right: 32px;
    padding-left: 35px;
  }

  .main-nav-tabs .lSAction>a.lSPrev {
    right: -32px;
  }

  .main-nav-tabs .lSAction>a.lSNext {
    left: -35px;
  }

  .nav .nav-item .dropdown-menu {
    border-radius: 0px 5px 5px 0px;
  }

  .nav-link.with-sub .angle {
    left: 25px;
  }

  .nav-link.with-sub .side-badge {
    left: 44px;
  }

  .nav-link .side-badge {
    left: 20px;
  }

  .nav-sub-item .sub-angle {
    left: 25px;
    margin-right: auto;
  }

  @media (max-width: 320px) {
    .main-toggle.on span {
      right: 29px !important;
      left: inherit !important;
    }
  }

  @media (min-width: 768px) {
    .bg-primary .main-nav .nav-link+.nav-link {
      border-right: 1px solid #07b79c !important;
      border-left: inherit !important;
    }

    .bg-secondary .main-nav .nav-link+.nav-link {
      border-right: 1px solid #f52756 !important;
      border-left: inherit !important;
    }

    .bg-info .main-nav .nav-link+.nav-link {
      border-right: 1px solid #26b7f0 !important;
      border-left: inherit !important;
    }
  }

  .page-item+.page-item {
    margin-right: 1px;
    margin-left: 0;
  }

  .page-link {
    margin-right: 0;
  }

  .pagination li a {
    margin-left: 4px !important;
    margin-right: inherit;
  }

  .pagination {
    padding-right: 0;
  }

  .page-link {
    margin-right: -1px;
  }


  .page-item:last-child .page-link {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .pagination-lg .page-item:first-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }

  .pagination-lg .page-item:last-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
  }

  .pagination-sm .page-item:first-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }

  .pagination-sm .page-item:last-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
  }

  .page-link-outline {
    margin-right: 0;
  }

  .popover-header {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .popover-head-primary.bs-popover-start .popover-arrow,
  .popover-head-primary.bs-popover-auto[data-popper-placement^="start"] .popover-arrow {
    left: -7px;
  }

  .popover-head-primary.bs-popover-end .popover-arrow,
  .popover-head-primary.bs-popover-auto[data-popper-placement^="end"] .popover-arrow {
    right: -7px;
  }

  .popover-head-secondary.bs-popover-start .popover-arrow,
  .popover-head-secondary.bs-popover-auto[data-popper-placement^="start"] .popover-arrow {
    left: -7px;
  }

  .popover-head-secondary.bs-popover-end .popover-arrow,
  .popover-head-secondary.bs-popover-auto[data-popper-placement^="end"] .popover-arrow {
    right: -7px;
  }

  .popover-head-primary .popover-body,
  .popover-head-secondary .popover-body {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  .popover-primary.bs-popover-start .popover-arrow::after,
  .popover-primary.bs-popover-auto[data-popper-placement^="start"] .popover-arrow::after {
    border-left-color: #38cab3;
  }

  .popover-primary.bs-popover-end .popover-arrow::after,
  .popover-primary.bs-popover-auto[data-popper-placement^="end"] .popover-arrow::after {
    border-right-color: #38cab3;
  }

  .popover-secondary.bs-popover-start .popover-arrow::after,
  .popover-secondary.bs-popover-auto[data-popper-placement^="start"] .popover-arrow::after {
    border-left-color: #38cab3;
  }

  .popover-secondary.bs-popover-end .popover-arrow::after,
  .popover-secondary.bs-popover-auto[data-popper-placement^="end"] .popover-arrow::after {
    border-right-color: #38cab3;
  }

  .popover {
    right: 0;
    text-align: right;
  }

  .bs-popover-end,
  .bs-popover-auto[data-popper-placement^="end"] {
    margin-right: 0.5rem;
  }

  .bs-popover-end>.popover-arrow,
  .bs-popover-auto[data-popper-placement^="end"]>.popover-arrow {
    left: calc((0.5rem + 1px) * -1);
    right: auto;
  }

  .bs-popover-end>.popover-arrow::before,
  .bs-popover-auto[data-popper-placement^="end"]>.popover-arrow::before {
    right: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #ededf5;
  }

  .bs-popover-end>.popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="end"]>.popover-arrow::after {
    right: -1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
  }

  .bs-popover-bottom .popover-header::before,
  .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
    right: 50%;
    margin-right: -0.5rem;
  }

  .bs-popover-start,
  .bs-popover-auto[data-popper-placement^="left"] {
    margin-left: 0.5rem;
  }

  .bs-popover-start>.popover-arrow,
  .bs-popover-auto[data-popper-placement^="start"]>.popover-arrow {
    left: calc((0.5rem + 1px) * -1);
  }

  .bs-popover-start>.popover-arrow::before,
  .bs-popover-auto[data-popper-placement^="start"]>.popover-arrow::before {
    left: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #ededf5;
  }

  .bs-popover-start>.popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="start"]>.popover-arrow::after {
    left: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
  }

  .popover-header {
    border-top-right-radius: calc(0.3rem - 1px);
    border-top-left-radius: calc(0.3rem - 1px);
  }

  .popover-static-demo .bs-popover-top .popover-arrow,
  .popover-static-demo .bs-popover-auto[data-popper-placement^="top"] .popover-arrow,
  .popover-static-demo .bs-popover-bottom .popover-arrow,
  .popover-static-demo .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow {
    right: 50%;
    margin-right: -5px;
  }

  @media (min-width: 992px) {
    .table-responsive.deleted-table .data-table-btn {
      right: 185px;
      left: inherit;
    }
  }

  @media (min-width: 1200px) {
    .table-responsive.export-table .dt-buttons.btn-group {
      right: 172px;
    }
  }

  .tooltip-primary.bs-tooltip-start .tooltip-arrow::before,
  .tooltip-primary.bs-tooltip-auto[data-popper-placement^="start"] .tooltip-arrow::before {
    border-left-color: #38cab3;
  }

  .tooltip-primary.bs-tooltip-end .tooltip-arrow::before,
  .tooltip-primary.bs-tooltip-auto[data-popper-placement^="end"] .tooltip-arrow::before {
    border-right-color: #38cab3;
  }

  .tooltip-primary1.bs-tooltip-start .tooltip-arrow::before,
  .tooltip-primary1.bs-tooltip-auto[data-popper-placement^="start"] .tooltip-arrow::before {
    border-left-color: #38cab3;
  }

  .tooltip-primary1.bs-tooltip-end .tooltip-arrow::before,
  .tooltip-primary1.bs-tooltip-auto[data-popper-placement^="end"] .tooltip-arrow::before {
    border-right-color: #38cab3;
  }

  .tooltip-secondary.bs-tooltip-start .tooltip-arrow::before,
  .tooltip-secondary.bs-tooltip-auto[data-popper-placement^="start"] .tooltip-arrow::before {
    border-left-color: #38cab3;
  }

  .tooltip-secondary.bs-tooltip-end .tooltip-arrow::before,
  .tooltip-secondary.bs-tooltip-auto[data-popper-placement^="end"] .tooltip-arrow::before {
    border-right-color: #38cab3;
  }

  .tooltip-secondary1.bs-tooltip-start .tooltip-arrow::before,
  .tooltip-secondary1.bs-tooltip-auto[data-popper-placement^="start"] .tooltip-arrow::before {
    border-left-color: #38cab3;
  }

  .tooltip-secondary1.bs-tooltip-end .tooltip-arrow::before,
  .tooltip-secondary1.bs-tooltip-auto[data-popper-placement^="end"] .tooltip-arrow::before {
    border-right-color: #38cab3;
  }

  .tooltip {
    text-align: right;
  }

  .bs-tooltip-top .tooltip-arrow::before,
  .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    border-width: 0.4rem 0.4rem 0;
  }

  .bs-tooltip-end .tooltip-arrow::before,
  .bs-tooltip-auto[data-popper-placement^="start"] .tooltip-arrow::before {
    left: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
  }

  .bs-tooltip-bottom .tooltip-arrow::before,
  .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    border-width: 0 0.4rem 0.4rem;
  }

  .bs-tooltip-start .tooltip-arrow,
  .bs-tooltip-auto[data-popper-placement^="start"] .tooltip-arrow {
    right: 0;
  }

  .bs-tooltip-start .tooltip-arrow::before,
  .bs-tooltip-auto[data-popper-placement^="start"] .tooltip-arrow::before {
    right: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
  }

  .bs-tooltip-auto[data-popper-placement^="end"] .tooltip-arrow,
  .bs-tooltip-end .tooltip-arrow {
    left: 0;
    right: auto;
  }

  .tooltip-static-demo .bs-tooltip-top .tooltip-arrow,
  .tooltip-static-demo .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
  .tooltip-static-demo .bs-tooltip-bottom .tooltip-arrow,
  .tooltip-static-demo .bs-tooltip-auto[data-popper-placement^="bottom"] {
    right: 50%;
    margin-right: -2px;
  }

  .tabs-style-2 .main-nav-line .nav-link {
    border-left: 0;
  }

  .tabs-style-2 .main-nav-line:first-child {
    border-right: 0;
  }

  .tabs-style-3 .nav.panel-tabs li a {
    margin: 0 0px 3px 3px;
  }

  .tab_wrapper.right_side>ul {
    float: left;
    padding-right: 0;
  }

  .tab_wrapper.right_side>ul li {
    text-align: left;
  }

  .tab_wrapper.right_side>ul li:after {
    left: 0;
    right: inherit;
  }

  .tab_wrapper.right_side>ul li.active:after {
    left: 0;
    right: inherit;
  }

  .tab_wrapper.right_side>ul li.active:before {
    right: -2px;
    left: inherit;
  }

  .tab_wrapper.right_side .content_wrapper {
    float: right;
  }

  .tab_wrapper>ul li {
    float: right;
    margin: 0 0 0 5px;
  }

  .tab_wrapper>ul li.active:after {
    right: 0;
  }

  .tab_wrapper .controller {
    left: 0;
  }

  .tab_wrapper .controller span.next {
    float: left;
    margin-right: 10px;
  }

  .tab_wrapper .content_wrapper {
    float: right;
  }

  .tab_wrapper .content_wrapper .accordian_header.active:after {
    top: 0;
    right: 0;
  }

  .tab_wrapper .content_wrapper .accordian_header .arrow {
    float: left;
    border-right: 3px solid #cdd2e0;
    border-left: inherit;
  }

  .tab_wrapper.left_side>ul {
    float: right;
    padding-right: 0;
  }

  .tab_wrapper.left_side>ul li:after {
    right: 0;
  }

  .tab_wrapper.left_side>ul li.active:after {
    right: 0;
  }

  .tab_wrapper.left_side>ul li.active:before {
    left: -1px;
  }

  .tab_wrapper.left_side .content_wrapper {
    float: right;
  }

  .tab_wrapper.right_side>ul {
    float: left;
  }

  .tab_wrapper.right_side>ul li {
    text-align: left;
  }

  .tab_wrapper.right_side>ul li:after {
    left: 0;
    right: inherit;
  }

  .tab_wrapper.right_side>ul li.active:after {
    left: 0;
    right: inherit;
  }

  .tab_wrapper.right_side>ul li.active:before {
    right: -2px;
    left: inherit;
  }

  .tab_wrapper.right_side .content_wrapper {
    float: right;
  }

  .tab_wrapper .active_tab {
    padding-left: 50px;
  }

  .tab_wrapper .active_tab .arrow {
    left: 6px;
  }

  .tab_wrapper .active_tab .arrow:after {
    border-left: 3px solid #000;
    right: 50%;
    margin-right: -6px;
  }

  @media only screen and (max-width: 992px) {
    .tab_wrapper.show-as-dropdown>.tab_list:before {
      right: 50%;
      margin-right: -7px;
    }
  }

  .dark-theme .tab_wrapper .content_wrapper .accordian_header .arrow {
    border-right: 3px solid rgba(240, 242, 247, 0.4);
  }

  .ckbox span {
    padding-right: 15px;
    padding-left: inherit;
  }

  .ckbox span:empty {
    float: right;
  }

  .ckbox span:before {
    right: 0;
  }

  .ckbox span:after {
    right: 0;
  }

  .ckbox span:empty {
    padding-right: 0;
    padding-left: inherit;
  }

  #count-down .clock-presenter {
    float: right;
    margin: 0 0px 0 20px;
  }

  .custom-control {
    padding-right: 1.5rem;
  }

  .custom-control-inline {
    margin-left: 1rem;
  }

  .custom-control-label::before {
    right: -1.5rem;
  }

  .custom-control-label::after {
    right: -1.5rem;
  }

  .custom-switch {
    padding-right: 2.25rem;
    padding-left: inherit;
  }

  .custom-switch .custom-control-label::before {
    right: -2.25rem;
  }

  .custom-switch .custom-control-label::after {
    right: calc(-2.25rem + 2px);
  }

  .custom-select[multiple],
  .custom-select[size]:not([size="1"]) {
    padding-left: 0.75rem;
  }

  .custom-select-sm {
    padding-right: 0.5rem;
  }

  .custom-select-lg {
    padding-right: 1rem;
  }

  .custom-file-label::after {
    left: 0;
    border-right: inherit;
    border-radius: 0 0px 3px 3px;
  }

  .custom-range::-ms-fill-upper {
    margin-left: 15px;
  }

  .custom-control-label-lg::before {
    right: -l 0.5rem;
  }

  .custom-control-label-lg::after {
    right: -l 0.5rem;
  }

  .custom-control-label-md {
    padding-right: 8px;
  }

  .custom-control-label-lg {
    padding-right: 15px;
  }

  .custom-switch-indicator:before {
    right: 1px;
  }

  .custom-switch-input:checked~.custom-switch-indicator:before {
    right: calc(1rem + 1px);
  }

  .custom-switch-indicator-lg:before {
    right: 1px;
  }

  .custom-switch-input:checked~.custom-switch-indicator-lg:before {
    right: calc(1.46rem + 1px);
  }

  .custom-switch-indicator-xl:before {
    right: 1px;
  }

  .custom-switch-input:checked~.custom-switch-indicator-xl:before {
    right: calc(1.96rem + 1px) !important;
  }

  .item-card .cardprice-2 {
    left: 15px;
    right: inherit;
  }

  .p-text {
    margin-right: 12px;
    margin-left: 10px;
  }

  .example .demo-avatar-group .main-img-user::after {
    left: 3px;
  }

  .demo-avatar-group.main-avatar-list-stacked .main-img-user::after {
    left: 1px;
  }

  .main-img-user::after {
    left: 0px;
  }

  .main-avatar::after {
    left: 3px;
  }

  .avatar-md::after {
    left: 2px;
  }

  .avatar-lg::after {
    left: 4px;
  }

  .avatar-xl::after {
    left: 5px;
  }

  .avatar-xxl::after {
    left: 7px;
  }

  .demo-avatar-group .main-img-user+.main-img-user,
  .demo-avatar-group .main-img-user+.main-avatar {
    margin-right: 5px;
  }

  .demo-avatar-group .main-avatar+.main-img-user,
  .demo-avatar-group .main-avatar+.main-avatar {
    margin-right: 5px;
  }

  @media (min-width: 576px) {

    .demo-avatar-group .main-img-user+.main-img-user,
    .demo-avatar-group .main-img-user+.main-avatar {
      margin-right: 5px;
      margin-left: inherit;
    }

    .demo-avatar-group .main-avatar+.main-img-user,
    .demo-avatar-group .main-avatar+.main-avatar {
      margin-right: -5px;
    }

    .avatar-list .avatar:not(:last-child) {
      margin-left: 5px !important;
      margin-right: 0 !important;
    }
  }

  .wrapper.image-group .img-xs.rounded-circle {
    margin-left: -5px;
  }

  .avatar-list .avatar:not(:last-child) {
    margin-left: 0.5rem;
  }

  .main-img-user1.avatar-sm .number-badge {
    left: -7px;
  }

  .main-img-user1.avatar-md .number-badge {
    left: -7px;
  }

  .main-img-user1.avatar-lg .number-badge {
    left: -7px;
  }

  .main-img-user3.avatar-sm .icon-badgeavatar {
    left: -4px;
  }

  .main-img-user3.avatar-md .icon-badgeavatar {
    left: -4px;
  }

  .main-img-user3.avatar-lg .icon-badgeavatar {
    left: -4px;
  }

  .avatar-list-stacked .avatar1 {
    margin-left: -0.8em !important;
  }

  .main-list-item>div:last-child {
    text-align: left;
  }

  .list-group-horizontal .list-group-item {
    margin-left: -1px;
  }

  .list-group-horizontal .list-group-item:first-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal .list-group-item:last-child {
    margin-left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .list-group-horizontal>.list-group-item+.list-group-item {
    border-left-width: 1px;
    border-right-width: 0;
  }

  @media (min-width: 576px) {
    .list-group-horizontal-sm .list-group-item {
      margin-left: -1px;
    }

    .list-group-horizontal-sm .list-group-item:first-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .list-group-horizontal-sm .list-group-item:last-child {
      margin-left: 0;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
      border-left-width: 1px;
      border-right-width: 0;
    }
  }

  @media (min-width: 992px) {
    .list-group-horizontal-md .list-group-item {
      margin-left: -1px;
    }

    .list-group-horizontal-md .list-group-item:first-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-top-left-radius: 0;
    }

    .list-group-horizontal-md .list-group-item:last-child {
      margin-left: 0;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 0;
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
      border-left-width: 1px;
      border-right-width: 0;
    }
  }

  @media (min-width: 992px) {
    .list-group-horizontal-lg .list-group-item {
      margin-left: -1px;
    }

    .list-group-horizontal-lg .list-group-item:first-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-top-left-radius: 0;
    }

    .list-group-horizontal-lg .list-group-item:last-child {
      margin-left: 0;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 0;
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
      border-left-width: 1px;
      border-right-width: 0;
    }
  }

  @media (min-width: 1200px) {
    .list-group-horizontal-xl .list-group-item {
      margin-left: -1px;
    }

    .list-group-horizontal-xl .list-group-item:first-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-top-left-radius: 0;
    }

    .list-group-horizontal-xl .list-group-item:last-child {
      margin-left: 0;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 0;
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
      border-left-width: 1px;
      border-right-width: 0;
    }
  }

  .list-style-1 ul {
    padding-right: 30px;
  }

  .list-style2 ul {
    padding-right: 30px;
  }

  .list-style3 {
    padding-right: inherit;
  }

  .list-style3 ul {
    padding-right: 30px;
  }

  .list-style4 {
    padding-right: inherit;
  }

  .list-style4 ul {
    padding-right: 30px;
  }

  .list-style5 {
    padding-right: inherit;
  }

  .list-style5 ul {
    padding-right: 30px;
  }

  .list-style6 {
    padding-right: inherit;
  }

  .list-style6 ul {
    padding-right: 30px;
  }

  .paragraph-list {
    padding-right: 20px;
  }

  .main-navbar {
    right: 0;
  }

  .main-navbar .nav-link i {
    margin-left: 10px;
  }

  .main-navbar .nav-link.with-sub::after {
    margin-right: auto;
  }

  .main-navbar .nav-link.with-sub::before {
    right: 50%;
    margin-right: -7px;
  }

  .main-navbar .nav-sub-item {
    margin-right: 30px;
  }

  .main-navbar .nav-sub-item>.nav-sub {
    right: 176px;
  }

  .main-navbar .nav-sub-link.with-sub::after {
    margin-right: auto;
  }

  .main-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
    margin-right: 30px;
  }

  @media (min-width: 992px) {
    .main-navbar {
      right: auto;
    }
  }

  @media (min-width: 992px) {
    .main-navbar .nav-item+.nav-item {
      margin-right: 15px;
      padding-right: 15px;
      border-right: 1px dotted #f0f0f8;
    }
  }

  @media (min-width: 1200px) {
    .main-navbar .nav-item+.nav-item {
      margin-right: 20px;
      padding-right: 20px;
    }
  }

  @media (min-width: 992px) {
    .main-navbar .nav-link i {
      margin-left: 8px;
    }
  }

  @media (min-width: 992px) {
    .main-navbar .nav-link.with-sub::after {
      margin-right: 4px;
    }
  }

  @media (min-width: 992px) {
    .main-navbar .nav-sub {
      right: 0;
    }
  }

  @media (min-width: 992px) {
    .main-navbar .nav-sub-item {
      margin-right: 0;
    }
  }

  @media (max-width: 991.98px) {
    .main-navbar .nav-sub-item>.nav-sub>.nav-sub-item {
      margin-right: 37px;
    }

    .main-navbar .nav-sub-item>.nav-sub>.nav-sub-item>.nav-sub-link {
      padding-right: 10px;
    }
  }

  @media (min-width: 992px) {
    .main-navbar .nav-sub-link.with-sub::after {
      margin-right: 4px;
    }
  }

  @media (min-width: 992px) {

    .main-navbar .nav-sub-mega .container>div+div,
    .main-navbar .nav-sub-mega .container-fluid>div+div {
      border-right: 1px solid rgba(28, 39, 60, 0.05);
    }
  }

  @media (min-width: 992px) {
    .main-navbar .nav-sub-mega .nav+.nav {
      margin-right: 20px;
    }
  }

  @media (min-width: 1200px) {
    .main-navbar .nav-sub-mega .nav+.nav {
      margin-right: 25px;
    }
  }

  @media (min-width: 992px) {
    .main-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
      margin-right: 0;
    }
  }

  .main-navbar-search .btn,
  .main-navbar-search .sp-container button {
    left: 0;
  }

  .sp-container .main-navbar-search button {
    left: 0;
  }

  .navbar-nav {
    padding-right: 0;
  }

  .navbar-form.active .input-group-btn {
    left: 12px;
    right: inherit;
  }

  #verticallpill .nav-pills .nav-link {
    padding: 0.75rem 0.75rem 0.75rem 0rem;
  }

  .ps>.ps__rail-y {
    right: auto !important;
    left: 0;
  }

  .ps>.ps__rail-y>.ps__thumb-y {
    right: 4px;
  }

  .rdiobox span {
    padding-right: 4px;
  }

  .rdiobox span:before {
    right: 0;
  }

  .rdiobox span:after {
    right: 5px;
  }

  .rdiobox span:empty {
    padding-right: 0;
  }

  .form-group-rdiobox .rdiobox+.rdiobox {
    margin-right: 30px;
  }

  .form-group-rdiobox .rdiobox span {
    padding-right: 0;
  }

  .tag-addon {
    margin: 0 0.5rem 0 -0.4rem;
  }

  .tag-avatar {
    border-radius: 3px 3px 0 0px;
    margin: 0 -0.5rem 0 0.5rem;
  }

  .tags>.tag:not(:last-child) {
    margin-left: 0.2rem;
  }

  .vtimeline:before {
    right: 50%;
    margin-right: -1.5px;
  }

  .vtimeline .timeline-wrapper .timeline-panel {
    margin-right: 15%;
    margin-left: inherit;
  }

  .vtimeline .timeline-wrapper .timeline-panel:after {
    left: -14px;
    right: inherit;
    border-right: 14px solid #f9f9fb;
    border-left: 0 solid #f9f9fb;
  }

  .vtimeline .timeline-wrapper .timeline-badge {
    right: calc(50% - 20px);
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  .vtimeline .timeline-wrapper.timeline-inverted {
    padding-left: 0;
    padding-right: 90px;
  }

  .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
    margin-right: auto;
    margin-left: 15%;
  }

  .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel:after {
    border-left-width: 14px;
    border-right-width: 0px;
    right: -14px;
    left: auto;
  }

  @media (max-width: 992px) {
    .vtimeline:before {
      right: 26px !important;
    }

    .vtimeline .timeline-wrapper {
      padding-left: 0 !important;
    }

    .vtimeline .timeline-wrapper .timeline-badge {
      right: 0 !important;
    }

    .vtimeline .timeline-wrapper .timeline-panel {
      margin-right: 16% !important;
    }

    .vtimeline .timeline-wrapper .timeline-panel:after {
      border-left: 14px solid #f9f9fb !important;
      border-right: 0 solid #f9f9fb !important;
      right: -14px !important;
      left: auto !important;
    }

    .vtimeline .timeline-wrapper.timeline-inverted {
      padding-right: 0 !important;
    }

    .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
      margin-left: 0 !important;
    }
  }

  /*latetes news timeline */

  .latest-timeline-1 ul.timeline-1:before {
    right: 20px;
    border-right: 2px dotted #c0ccda;
  }

  .latest-timeline-1 ul.timeline-1>li {
    padding-right: 0.9rem;
  }

  .latest-timeline-1 ul.timeline-1>li .product-icon {
    right: 1px;
  }

  .latest-tasks .tasks {
    margin: 0 0 1.15rem 0;
  }

  .latest-tasks .tasks .span {
    margin-right: 1.3rem;
  }

  .latest-tasks .label {
    margin-right: 1.3rem;
  }

  .latest-tasks .time {
    margin-right: 1.3rem;
  }

  .latest-tasks .check-box .ckbox span:before {
    right: 0;
  }

  .image-grouped img {
    margin-left: -0.6em !important;
  }

  .activity-list:before {
    margin: 20px 22px 0 20px;
  }

  .activity .item-activity {
    margin-right: 60px;
  }

  @media (max-width: 576px) {
    .vtimeline .timeline-wrapper .timeline-panel {
      margin-right: 24% !important;
      margin-left: 0 !important;
    }
  }

  .main-toggle span {
    right: 3px;
    left: inherit;
  }

  .main-toggle span::before {
    right: -25px;
    left: inherit;
  }

  .main-toggle span::after {
    right: 24px;
  }

  .main-toggle.on span {
    right: 37px;
    left: inherit;
  }

  .main-toggle-group-demo .main-toggle+.main-toggle {
    margin-right: 10px;
    margin-left: inherit;
  }

  .close {
    float: left;
  }

  @media (max-width: 991px) {
    .close {
      left: 10px;
      right: inherit;
    }
  }

  .spinner-border {
    border-right-color: transparent;
  }

  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    right: 0;
    left: inherit;
  }

  .main-body::before {
    right: 70%;
  }

  .main-home-slider .card-columns {
    right: 24%;
  }

  @media (min-width: 576px) {
    .main-home-slider .card-columns {
      right: 16%;
    }
  }

  @media (min-width: 992px) {
    .main-home-slider .card-columns {
      right: auto;
      left: -45%;
    }
  }

  @media (min-width: 992px) {
    .main-home-slider .card-columns {
      left: -30%;
    }
  }

  @media (min-width: 1200px) {
    .main-home-slider .card-columns {
      left: -15%;
    }
  }

  @media (min-width: 1300px) {
    .main-home-slider .card-columns {
      left: -5%;
    }
  }

  .main-home-content .logo-group i+i {
    margin-right: 10px;
  }

  @media (min-width: 576px) {
    .main-home-content {
      right: 40px;
      left: auto;
    }
  }

  .main-home-content p:last-of-type .btn+.btn,
  .main-home-content p:last-of-type .sp-container button+.btn {
    margin-right: 5px;
  }

  .sp-container .main-home-content p:last-of-type button+.btn,
  .main-home-content p:last-of-type .sp-container .btn+button,
  .sp-container .main-home-content p:last-of-type .btn+button,
  .main-home-content p:last-of-type .sp-container button+button,
  .sp-container .main-home-content p:last-of-type button+button {
    margin-right: 5px;
  }

  .main-home-content nav:last-child a+a {
    margin-right: 8px;
  }

  .main-page-content-list li i {
    margin-left: 10px;
  }

  .main-syntax-wrapper .badge {
    left: 5px;
  }

  .horizontal-nav .mega-dropdown-menu:before {
    border-width: 0 10px 10px 10px;
    left: auto;
    right: 16px;
  }

  .mega-dropdown-menu:after {
    left: auto;
    right: 16px;
  }

  .drop-icon-wrap .drop-icon-item {
    float: right;
  }

  .flag>a::before {
    right: 50%;
    margin-right: -7px;
  }

  .dropdown-toggle-1::after {
    left: 9px;
  }

  .dropdown-toggle-2::after {
    left: 0px;
  }

  .arrow-ribbon {
    right: 0px;
  }

  .arrow-ribbon:before {
    left: 0;
    left: -15px;
    border-right: 15px solid #000;
  }

  .mega-menu:before {
    right: 14px;
  }

  .mega-menu:after {
    right: 14px;
  }

  .flag-text:before {
    left: 0;
  }

  .drop-flag {
    margin-left: 2.3rem !important;
  }

  .drop-flag .dropdown-menu {
    right: auto;
    left: -10px;
    padding: 10px 10px 10px;
  }

  .dropdown-item .content {
    margin-right: 15px;
  }

  .avatar-status {
    left: 5px;
  }

  .user-pro-body .profile-status {
    left: 18px;
  }

  .user-pro-body .profile-status:before {
    left: -6px;
  }

  .pulse {
    left: 8px;
  }

  .pulse:before {
    left: -4px;
  }

  .pulse-danger {
    left: 5px;
  }

  .pulse-danger:before {
    left: -4px;
  }

  .main-message-list a .desc {
    margin-right: 10px;
  }

  .main-message-list a .name {
    margin-right: 10px;
  }

  .notifyimg i {
    float: right;
  }

  .nav-badge {
    margin-right: auto !important;
    left: 12px;
  }

  .nav-text-badge {
    margin-right: auto !important;
    left: 7px;
  }

  .line-list {
    padding-right: 30px;
  }

  .line-list li:before {
    right: 0;
    margin-left: 15px;
  }

  .line-list li:after {
    right: 0;
  }

  .sales-card .sales-icon {
    padding: 40px 20px 20px 10px;
    left: -30px;
  }

  .sales-session {
    margin-right: 0;
    padding-right: 0;
  }

  .product-timeline ul.timeline-1:before {
    margin: 20px 22px 0 20px;
    right: -2px;
  }

  .product-timeline ul.timeline-1>li {
    padding-right: 1rem;
  }

  .product-timeline ul.timeline-1>li .product-icon {
    right: 3px;
  }

  .order-list .list {
    padding-right: 0;
  }

  .top-selling-product .table th,
  .top-selling-product .table td {
    padding: 7px 13px 7px 7px !important;
  }

  .out-of-stock:before {
    right: 0;
  }

  .semi-circle {
    border-radius: 0 300px 300px 0px;
  }

  .project-card i {
    margin: 0 -30px 0 24px;
  }

  .project-card .project-content ul span {
    float: left;
    text-align: left;
    margin-right: auto;
  }

  .crypto .nav-link+.nav-link {
    border-right: 1px solid #ededf5;
  }

  .crypto .coin-logo {
    margin-left: 15px;
  }

  .project-list .list-unstyled li {
    border-right: 1px solid #000;
  }

  .project-list .list-unstyled li .media-body {
    margin-right: 13px;
  }

  .pricing-card .list-unstyled {
    padding-right: 0;
  }

  .demo-gallery>ul>li a {
    float: right;
  }

  .demo-gallery>ul>li a .demo-gallery-poster>img {
    right: 50%;
    margin-right: -10px;
  }

  .demo-gallery .justified-gallery>a .demo-gallery-poster>img {
    right: 50%;
    margin-right: -10px;
  }

  .preview-thumbnail.nav-tabs li {
    margin-left: 2.5%;
  }

  .preview-thumbnail.nav-tabs li:last-of-type {
    margin-left: 0;
  }

  .size {
    margin-left: 10px;
  }

  .size:first-of-type {
    margin-right: 40px;
  }

  .colorinput-color:before {
    right: 0.25rem;
  }

  .item-card .cardprice {
    left: 15px;
    right: inherit;
  }

  .icons-list {
    margin: 0 0px -1px -1px;
  }

  .icons-list-item .bs-tooltip-start {
    padding: 10px 0.4rem;
  }

  .highlight {
    border-top-left-radius: 8px;
  }

  pre {
    border-top-left-radius: 7px;
  }

  .clipboard-icon {
    left: 10px;
  }

  @media (max-width: 568px) {
    .main-nav .nav-link+.nav-link {
      border-right: 0px !important;
    }
  }

  .sub-menu li a:before {
    margin-left: 8px;
    right: 8px;
  }

  .mega-menubg.hor-mega-menu li a:before {
    right: 10px;
  }

  .qunatity-list {
    margin-right: 0;
    padding-right: 6px;
  }

  .chips .chip {
    margin: 0 0 0.5rem 0.5rem;
  }

  .chip .avatar {
    float: right;
    margin: 0 -0.75rem 0 0.5rem;
  }

  .team i {
    margin-right: 10px;
    float: left;
  }

  /*-----Back to Top-----*/
  #back-to-top {
    left: 20px !important;
    right: inherit !important;
  }

  .email-button {
    left: 20px;
    right: inherit;
  }

  .main-star span {
    margin-right: 5px;
  }

  @media (min-width: 992px) {
    .main-header-left .btn {
      left: 11px;
    }
  }

  .main-content-header-right .media+.media {
    margin-right: 20px;
    padding-right: 20px;
    border-right: 1px solid #ededf5;
  }

  .main-content-header-right .btn:first-of-type,
  .main-content-header-right .sp-container button:first-of-type {
    margin-right: 30px;
  }

  .sp-container .main-content-header-right button:first-of-type {
    margin-right: 30px;
  }

  .main-content-header-right .btn+.btn,
  .main-content-header-right .sp-container button+.btn {
    margin-right: 5px;
  }

  .sp-container .main-content-header-right button+.btn,
  .main-content-header-right .sp-container .btn+button,
  .sp-container .main-content-header-right .btn+button,
  .main-content-header-right .sp-container button+button,
  .sp-container .main-content-header-right button+button {
    margin-right: 5px;
  }

  .chart-legend div+div {
    margin-right: 15px;
  }

  .chart-legend span {
    margin-left: 5px;
  }

  @media (min-width: 576px) {
    .chart-legend div+div {
      margin-right: 30px;
    }
  }

  @media (min-width: 576px) {
    .chart-legend span {
      margin-left: 10px;
    }
  }

  .chart-wrapper {
    margin-right: -13px;
    margin-left: -13px;
  }

  @media (min-width: 576px) {
    .chart-wrapper {
      margin-right: -10px;
      margin-left: -15px;
    }
  }

  .dropdown-menu-arrow:after {
    left: 16px;
  }

  .dropdown-menu-start {
    left: 0 !important;
    right: auto !important;
  }

  .progress-style.progress .progress-bar:after {
    left: -5px;
    right: inherit;
    top: -5px;
  }

  .progress-style .progress-bar {
    float: right;
  }

  .success-widget h3:after {
    right: 0.5em;
    left: inherit;
  }

  .success-widget h3:before {
    left: 0.5em;
    right: inherit;
  }

  .danger-widget h3:after {
    right: 0.5em;
    left: inherit;
  }

  .danger-widget h3:before {
    left: 0.5em;
    right: inherit;
  }

  .warning-widget h3:after {
    right: 0.5em;
    left: inherit;
  }

  .warning-widget h3:before {
    left: 0.5em;
    right: inherit;
  }

  .card .box {
    right: 0;
    left: inherit;
  }

  .dot-label {
    right: 7px;
  }

  @media (min-width: 992px) {
    .top-header .main-header-center {
      margin-right: 0 !important;
    }
  }

  .featured_icon {
    margin-right: -35px !important;
  }

  ul.timeline:before {
    right: 25px;
  }

  .img-card .img-card-circle {
    margin-left: -48px;
    text-align: left;
    left: 0;
  }

  .feature-1 {
    left: 40px;
    right: inherit;
  }

  .product-grid6 .product-link a:nth-child(2n) {
    border-left: none;
  }

  .handle-counter input {
    float: right;
  }

  .handle-counter .counter-minus,
  .handle-counter .handle-counter .counter-plus {
    float: right;
  }

  .handle-counter .counter-minus {
    border-radius: 0 3px 3px 0 !important;
  }

  .handle-counter .counter-plus {
    border-radius: 3px 0px 0 3px !important;
  }

  .edit {
    margin-right: 30px;
  }

  .card-options a:not(.btn) {
    margin-right: 0;
    margin-left: inherit;
  }

  .card-options {
    margin-right: auto;
    margin-left: inherit;
  }

  .lds-heart div:before {
    right: -17px;
    left: inherit;
    border-radius: 0 50% 50% 0;
  }

  .lds-heart div {
    right: 19px;
    left: inherit;
  }

  .lds-spinner div:after {
    right: 29px;
  }

  .lds-facebook div:nth-child(1) {
    right: 6px;
  }

  .lds-facebook div {
    right: 6px;
  }

  .lds-facebook div:nth-child(2) {
    right: 26px;
  }

  .lds-facebook div:nth-child(3) {
    right: 45px;
  }

  @media (max-width: 568px) {
    .feature-1 {
      left: 4px;
    }

    .btn,
    .sp-container button {
      margin-left: 0px;
    }

    .main-contact-action {
      left: 0px !important;
      right: inherit !important;
    }
  }

  .main-sidemenu .slide.is-expanded i.angle {
    left: 20px;
  }

  .main-sidemenu i.angle {
    left: 20px;
    right: inherit;
  }

  @media (max-width: 600px) {
    .main-nav-line .nav-link+.nav-link {
      margin-right: 0;
    }
  }

  @media (max-width: 991px) {
    .profile-cover__info .nav li:not(:first-child) {
      margin-right: 0px !important;
    }
  }

  @media (max-width: 568px) {

    .btn.btn-rounded.plus,
    .btn.btn-rounded.comment {
      margin-left: -25px;
    }
  }

  ul.inbox-pagination {
    float: left;
  }

  .inbox-pagination li span {
    margin-left: 5px;
  }

  .nav.panel-tabs .side-menu__icon {
    margin-left: 5px;
  }

  .circle-icon {
    left: 40px;
    right: inherit;
  }

  .dot-label-1 {
    margin-left: 5px;
  }

  @media (max-width: 991px) {
    .demo-icon .nav-link {
      padding: 12px 10px 4px 4px !important;
    }
  }

  .pos-absolute.bg-black-9 {
    border-radius: 0px 0px 5px 5px;
  }

  div.dt-button-info {
    right: 50%;
    margin-right: -200px;
  }

  @media (min-width: 992px) {
    .main-footer {
      padding: 0.9rem 250px 0.9rem 1rem !important;
    }
  }

  @media (max-width: 992px) {
    .main-footer {
      padding: 0.9rem 10px 0.9rem 1rem;
    }
  }

  .bootstrap-tagsinput .badge [data-role="remove"]:after {
    margin-right: 6px;
    margin-left: inherit;
  }

  .img-card-circle1 {
    margin-left: 0px;
    text-align: left;
    left: 0;
  }

  .demo-icon {
    margin-left: 20px !important;
    margin-right: inherit !important;
  }

  .demo-icon.nav-link {
    padding: 0rem 0.2rem 0rem 0rem !important;
  }

  .intl-tel-input .flag-dropdown .selected-flag {
    padding: 11px 6px 11px 16px;
  }

  .image-pic {
    padding: 0px 10px 20px 0px;
  }

  .tags .tag-files span {
    margin-left: 104px;
  }

  .owl-nav .owl-next {
    left: -25px;
    right: inherit;
  }

  .owl-nav .owl-prev {
    right: -25px;
    left: inherit;
  }

  .apexcharts-legend-marker {
    margin-left: 10px !important;
  }

  .notification:before {
    right: 20%;
    margin-right: -2.5px;
  }

  .notification .notification-time {
    right: 0;
    text-align: left;
  }

  .notification .notification-icon {
    right: 15%;
    left: inherit;
  }

  .notification .notification-body {
    margin-right: 24%;
    margin-left: 18%;
  }

  .notification .notification-body:before {
    border-left-color: $white;
    right: -20px;
    left: inherit;
    border-right-color: transparent;
  }

  @media (max-width: 576px) {
    .notification .notification-time {
      left: 5px !important;
      right: inherit !important;
    }

    .notification-badge {
      right: 10px;
      left: inherit !important;
    }

    .notification .notification-time .time {
      margin-right: 5px;
      margin-left: 10px;
    }

    .notification .notification-body {
      margin-right: 0;
      margin-left: 0;
    }
  }

  *::-ms-backdrop,
  .horizontalMenucontainer .main-header-left {
    margin-right: 0 !important;
  }

  *::-ms-backdrop,
  .chat .action-header {
    padding: 10px 17px 1px 13px;
  }

  *::-ms-backdrop,
  .sidebar-mini .main-header-left .btn {
    margin-right: 60px;
    right: 250px;
  }

  *::-ms-backdrop,
  .sidebar-mini .main-content.app-content .main-header-left {
    margin-right: 0;
  }

  @media (min-width: 992px) {

    *::-ms-backdrop,
    .sidebar-mini .main-header-left {
      margin-right: 80px;
    }

    *::-ms-backdrop,
    .sidebar-mini .main-header .main-header-left .header-brand {
      margin-left: 80px;
    }
  }

  *::-ms-backdrop,
  .btn-icon-list .btn {
    margin-left: 10px;
  }

  *::-ms-backdrop,
  .fc-datepicker.main-datepicker.hasDatepicker {
    border-left: 0 !important;
  }

  @media (min-width: 992px) {

    *::-ms-backdrop,
    .tasks .ckbox {
      margin-left: 10px;
    }

    *::-ms-backdrop,
    .app-sidebar__toggle {
      margin-left: 30px !important;
    }
  }

  @media (max-width: 991px) {

    *::-ms-backdrop,
    .main-header-left .responsive-logo {
      margin-right: 40px !important;
    }

    *::-ms-backdrop,
    .sidebar-mini .main-header .main-header-left .header-brand {
      margin-right: 30px;
    }
  }

  *::-ms-backdrop,
  #list3 .me-4 {
    margin-left: 0 !important;
  }

  .adtocart {
    right: 40%;
  }

  .prod-cat li ul {
    margin-right: 44px;
  }

  .nav.prod-cat li a i {
    margin-left: 14px;
  }

  .product-sale {
    right: 13px;
    left: 5px;
  }

  .product-sale .wishlist {
    float: left;
    left: 26px;
  }

  .shopping-cart .product-item .product-thumb,
  .wishlist-table .product-item .product-thumb,
  .order-table .product-item .product-thumb {
    padding-left: 0;
  }

  .shopping-cart .product-item .product-title small,
  .wishlist-table .product-item .product-title small,
  .order-table .product-item .product-title small {
    margin-right: 6px;
  }

  .shopping-cart-footer>.column:last-child {
    text-align: left;
  }

  .shopping-cart-footer>.column:last-child .btn {
    margin-left: 0;
    margin-right: 5px;
  }

  .coupon-form .form-control {
    margin-left: 12px;
  }

  #checkoutsteps .item .purchase {
    margin-right: 21px;
  }

  #checkoutsteps .total {
    float: left;
  }

  // .card-pay .tabs-menu.nav li {
  //   border-left: 1px solid #d3dfea;
  //   border-right: 0;
  // }

  .widget-user .widget-user-image {
    right: 50%;
    margin-right: -45px;
  }

  .user-wideget .border-right {
    border-left: 1px solid #f4f4f4;
  }

  .main-footer .container {
    padding: 38px 20px 0;
  }

  .main-footer .container-fluid {
    padding: 38px 20px 0;
  }

  @media (max-width: 633px) {
    .profile-name.ms-2 {
      margin-right: 0;
    }
  }

  .main-header-left .list-items li i {
    margin-left: 15px;
  }

  .main-logo i {
    margin-left: 8px;
  }

  .main-header-menu-icon {
    margin-left: 10px;
  }

  .main-header-menu-icon span::before,
  .main-header-menu-icon span::after {
    right: 0;
  }

  @media (min-width: 992px) {
    .main-header-menu-icon {
      margin-left: 25px;
    }
  }

  .main-header-menu {
    right: 0;
  }

  .main-header-menu .nav-item::before {
    margin-right: 25px;
    border-right: 2px solid rgba(28, 39, 60, 0.12);
  }

  .main-header-menu .nav-item>.nav-link .typcn {
    margin-left: 15px;
  }

  .main-header-menu .nav-item.active>.nav-link::before {
    right: -20px;
  }

  @media (min-width: 992px) {
    .main-header-menu {
      border-left: 0;
    }
  }

  @media (min-width: 992px) {
    .main-header-menu .nav-item+.nav-item {
      margin-right: 30px;
    }
  }

  @media (min-width: 992px) {
    .main-header-menu .nav-item>.nav-link.with-sub::after {
      margin-right: 5px;
    }
  }

  @media (min-width: 992px) {
    .main-header-menu .nav-item>.nav-link .typcn {
      margin-left: 7px;
    }
  }

  .main-menu-sub {
    margin-right: 32px;
  }

  @media (min-width: 992px) {
    .main-menu-sub {
      margin-right: 0;
    }
  }

  @media (min-width: 992px) {
    .main-menu-sub-mega .container>div+div {
      border-right: 1px solid rgba(28, 39, 60, 0.05);
    }
  }

  @media (min-width: 992px) {
    .main-menu-sub-mega .nav+.nav {
      margin-right: 20px;
    }
  }

  @media (min-width: 1200px) {
    .main-menu-sub-mega .nav+.nav {
      margin-right: 25px;
    }
  }

  .main-header-right .btn-social+.btn-social {
    margin-right: 10px;
  }

  .main-header-search-link {
    margin-left: 15px;
  }

  .main-profile-menu>.profile-name::after {
    right: 50%;
    margin-right: -7px;
  }

  .main-header-notification .dropdown-menu {
    right: auto;
    left: -10px;
  }

  .main-profile-menu .dropdown-menu:before {
    right: auto !important;
    left: 15px !important;
  }

  .main-header-right .dropdown-menu:before {
    left: 28px;
    right: auto;
  }

  &.light-theme .main-sidebar-header {
    border-left: 1px solid $border;
  }

  .main-header-message .dropdown-menu {
    right: auto;
    left: -10px;
  }

  .main-notification-list .media-body {
    margin-right: 15px;
  }

  .main-profile-menu>.main-img-user::before {
    left: -90px;
    margin-right: -7px;
  }

  .main-profile-menu .dropdown-menu {
    right: auto;
    left: 0px;
  }

  .main-profile-menu .dropdown-item i {
    margin-left: 10px;
    margin-right: inherit;
  }

  .main-header-right form[role="search"] {
    left: 0;
  }

  @media (min-width: 992px) {
    .main-header-menu-icon {
      margin-right: auto;
      margin-left: 8px !important;
    }
  }

  form[role="search"].active {
    left: 0;
    border-right: 0px solid #e3e3f7;
    right: -1px;
  }


  @media (min-width: 992px) {
    .main-header-left .btn {
      left: 0px;
      right: inherit;
      border-right: inherit;
      border-left: 1px solid $border  !important;
      border-radius: 0px 0px 5px 5px;
    }
  }

  .header-megamenu-dropdown i {
    margin-left: 3px;
  }

  .main-header-arrow {
    left: auto;
  }

  @media (min-width: 992px) {
    .main-header {
      right: 0;
      padding-right: 250px;
      padding-left: inherit;
    }
  }

  .horizontalMenucontainer .main-header.hor-header {
    padding-right: 0;
  }

  .desktoplogo {
    float: right;
  }

  .horizontalMenu>.horizontalMenu-list {
    text-align: right;
  }

  .horizontalMenu>.horizontalMenu-list>li {
    float: right;
  }

  .horizontalMenu>.horizontalMenu-list>li:last-child>a {
    border-left: 0px;
  }

  .horizontalMenu>.horizontalMenu-list>li>a i {
    margin-left: 5px;
  }

  .horizontalMenu>.horizontalMenu-list>li>a .arrow:after {
    float: left;
    left: 11px;
    margin: 0 14px 0 0;
    text-align: left;
  }

  .horizontalMenu>.horizontalMenu-list>li.rightmenu {
    float: left;
  }

  .horizontalMenu>.horizontalMenu-list>li.rightmenu a {
    padding: 0px 20px 0px 30px;
    border-left: none;
  }

  .horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch {
    float: left;
  }

  .horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch input {
    float: left;
    left: 0;
  }

  .horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch .btnstyle {
    left: 0;
  }

  .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
    border-left: 0 none;
    text-align: right;
  }

  .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a>i {
    margin-left: 9px;
  }

  .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu {
    right: 100%;
  }

  .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a {
    border-left: 0 none;
    text-align: right;
  }

  .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a>i {
    margin-left: 9px;
  }

  .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu {
    right: 100%;
  }

  .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a {
    border-left: 0 none;
    text-align: right;
  }

  .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a>i {
    margin-left: 9px;
  }

  .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu {
    right: 0px;
    text-align: right;
  }

  .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .title {
    padding: 5px 0px 5px 5px;
    text-align: right;
  }

  .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li {
    text-align: right;
  }

  .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a {
    border-left: none;
    text-align: right;
  }

  .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu li i {
    margin-left: 5px;
  }

  .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu li i {
    margin-left: 5px;
  }

  .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .menu_form input[type="submit"] {
    float: left;
    margin-left: 15px;
  }

  .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .menu_form input[type="button"] {
    float: left;
  }

  @media only screen and (max-width: 991px) {
    .horizontalMenu {
      right: 0;
    }

    .horizontalMenu>.horizontalMenu-list {
      margin-right: -240px;
    }

    .horizontalMenu>.horizontalMenu-list>li {
      border-left: none;
      clear: left;
    }

    .horizontalMenu>.horizontalMenu-list>li>a {
      padding: 8px 17px 8px 32px;
      text-align: right;
      border-left: solid 0px;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
      padding: 10px 36px 10px 0px;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li span+a {
      padding-left: 30px;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu {
      right: 100%;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a {
      padding: 10px 62px 10px 0px;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:before {
      right: 45px;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li span+a {
      padding-left: 30px;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu {
      right: 100%;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a {
      padding: 10px 34px 10px 0px;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li span+a {
      padding-left: 30px;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu.halfmenu {
      padding: 5px 0px 10px 0px;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .title {
      padding: 10px 0px 10px 8px;
    }

    .horizontal-header {
      right: 0;
    }

    .callusbtn {
      left: 5px;
    }

    .animated-arrow {
      right: 0;
      padding: 14px 0px 16px 35px;
      margin: 18px 25px 0 0;
    }

    .horizontal-overlapbg {
      left: 0;
    }

    .active .horizontalMenucontainer {
      margin-right: 240px;
    }

    .active .horizontalMenu>.horizontalMenu-list {
      margin-right: 0;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click {
      left: 0;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click>i {
      margin-left: 10px;
      float: left;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click02 {
      left: 0;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click02>i {
      float: left;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click03 {
      left: 0;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click03>i {
      margin: 0px 6px 8px 6px;
      float: left;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click04 {
      left: 0;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click04>i {
      margin: 0px 6px 8px 6px;
      float: left;
    }

    .desktop-logo {
      margin-right: 2rem !important;
    }
  }

  @media (max-width: 991px) {
    .desktop-logo-1 {
      margin-right: 0;
    }

    .horizontalMenucontainer .header-brand {
      margin-right: 3rem;
    }
  }

  @media (min-width: 992px) {
    .side-badge {
      left: 17px;
    }
  }

  .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu .sub-menu-sub:after {
    left: 14px;
  }

  .sub-menu li a:before {
    right: 15px;
  }

  .mega-menubg li a:before {
    right: 9px;
  }

  .horizontalMenu>.horizontalMenu-list>li>a i.horizontal-icon {
    margin-left: 0;
  }

  .hor-icon {
    margin-left: 3px;
  }

  .horizontal-badge {
    margin-right: 1px;
  }

  .mega-menubg.hor-mega-menu h3 {
    padding: 0 2px 0 20px;
  }

  .horizontalMenucontainer .side-menu__icon {
    margin-left: 7px;
  }

  @media only screen and (max-width: 991px) {
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a {
      padding: 10px 80px 10px 0px;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a:before {
      right: 62px;
    }
  }

  .main-iconbar {
    right: 0;
  }

  .main-iconbar-logo::after {
    right: 20px;
  }

  .main-iconbar-aside {
    border-left: 1px solid #d6d6e6;
    right: 64px;
  }

  @media (min-width: 1200px) {
    .main-iconbar-aside {
      border-left: 0;
    }
  }

  @media (min-width: 1200px) {
    .main-iconbar-aside.show+.main-content {
      margin-right: 294px;
    }
  }

  .main-iconbar-header {
    padding-right: 0;
  }

  @media (min-width: 1200px) {
    .main-iconbar-toggle-menu::before {
      right: -1px;
      border-right: 2px solid #323251;
    }
  }

  @media (max-width: 991.98px) {
    .main-content-left-show .main-content-left {
      right: 0;
      border-left: 1px solid #ededf5;
    }
  }

  .main-content.horizontal-content {
    margin-right: 0;
  }

  @media (min-width: 992px) {
    .main-content {
      margin-right: 240px;
      margin-left: inherit;
    }
  }

  .main-content-left-components {
    border-left: 1px solid #f0f0f8;
  }

  .main-header-profile .main-img-user::after {
    left: 6px;
  }

  .main-profile-menu .profile-user img {
    margin-right: 0;
  }

  @media (min-width: 992px) {
    .main-content-left-profile {
      padding-left: 20px;
      border-left: 1px solid #ededf5;
    }
  }

  @media (min-width: 1200px) {
    .main-content-left-profile {
      padding-left: 25px;
    }
  }

  .main-profile-social-list .media-body {
    margin-right: 20px;
  }

  @media (min-width: 576px) {
    .main-content-body-profile .nav {
      padding: 18px 30px 17px 14px;
    }
  }

  @media (min-width: 992px) {
    .main-content-body-profile .nav {
      padding-right: 20px;
    }
  }

  @media (min-width: 1200px) {
    .main-content-body-profile .nav {
      padding-right: 25px;
    }
  }

  @media (min-width: 576px) and (max-width: 991.98px) {
    .main-content-body-profile .main-nav-line .nav-link+.nav-link {
      margin-right: 20px;
    }
  }

  @media (min-width: 992px) {
    .main-profile-body {
      padding: 25px 20px 0 0px;
    }
  }

  @media (min-width: 1200px) {
    .main-profile-body {
      padding-right: 25px;
    }
  }

  .main-profile-view-info {
    right: 0;
  }

  .main-profile-view-info span {
    margin-right: 5px;
  }

  .main-profile-work-list .media-body {
    margin-right: 20px;
  }

  .main-profile-contact-list .media-body {
    margin-right: 25px;
  }

  @media (max-width: 578px) {
    .main-profile-menu .profile-user img {
      margin-right: 0;
    }
  }

  .profile-user .profile-edit {
    left: 0;
  }

  .main-profile-body .card-header {
    padding-right: 0;
  }

  .drop-down-profile span.assigned-task {
    left: 0;
    right: inherit;
  }

  @media (max-width: 575px) {
    .main-content-body-profile .main-nav-line .nav-link.active::before {
      right: 0px;
    }
  }

  @media (max-width: 991.98px) {
    .main-profile-menu .profile-user img {
      margin-right: 0 !important;
    }
  }

  .profile.navtab-custom li a {
    margin-left: 7px;
  }

  .profile-cover__img {
    right: 50px;
  }

  @media (min-width: 601px) {
    .profile-cover__info .nav {
      padding: 15px 170px 10px 0px;
    }
  }

  .profile-cover__info .nav li:not(:first-child) {
    margin-right: 30px;
    padding-right: 30px;
    border-right: 1px solid rgba(238, 238, 238, 0.2);
  }

  @media (min-width: 769px) {
    .main-nav-line .nav-link+.nav-link {
      margin-right: 5px;
    }
  }

  @media (max-width: 992px) {
    .main-nav-line .nav-link+.nav-link {
      margin-right: 0 !important;
    }
  }

  .profile-online {
    left: -10px;
    right: inherit;
  }

  a.option-dots {
    margin-left: 5px;
    margin-right: inherit;
  }

  .main-sidebar-body .nav-sub-item {
    margin-right: 55px;
  }

  .main-sidebar {
    border-left: 0 solid #d8dfea;
  }

  @media (max-width: 991.98px) {
    .main-sidebar {
      right: 0;
    }
  }

  .main-sidebar-sticky {
    right: 0;
  }

  @media (min-width: 992px) {
    .main-sidebar-sticky+.main-content {
      margin-right: 220px;
    }
  }

  .main-sidebar .nav-pills-circle.nav .nav-item .dropdown-menu {
    right: -30px;
  }

  .main-sidebar .nav-pills-circle.nav .nav-item .dropdown-menu .dropdown-item i {
    margin-left: 3px;
  }

  .main-sidebar-loggedin .media-body {
    margin-right: 12px;
  }

  .main-sidebar-body .nav-item.active .nav-link .nav-badge {
    left: 12px !important;
  }

  .main-sidebar-body .nav-sub {
    margin-right: 32px;
  }

  .main-sidebar-body .nav-item+.nav-item::before {
    right: 30px;
    left: 0;
  }

  .nav-link.with-sub .angle {
    left: 23px;
  }

  .nav-link.with-sub .side-badge {
    left: 23px;
  }

  .main-sidebar-body .nav-sub {
    margin: 0 7px 0 0px;
  }

  .main-sidebar-body .nav-sub-item .nav-sub {
    padding: 0 18px 0 0px;
    border-left-width: 1px;
    margin-right: 0;
  }

  .main-sidebar-body .nav-sub-link.with-sub::after {
    margin-right: auto;
  }

  @media (min-width: 992px) {
    .main-sidebar-body .nav-sub-link.with-sub::after {
      margin-right: 4px;
    }
  }

  .main-sidebar-body .nav-sub li .nav-sub-link::after {
    right: -30px;
  }

  .main-sidebar-body .nav-label:after {
    right: 13px;
  }

  .sidebar-right .main-nav-line .nav-link {
    padding: 10px 21px 10px 18px;
  }

  .main-sidebar-body .nav-sub-item {
    margin-right: 0;
    text-align: right;
    padding: 2.2px 52px 2.2px 25px;
  }

  .main-sidebar-loggedin .media-body {
    margin-right: 13px;
  }

  .main-sidebar-body .nav-item.active:before {
    right: 0px;
  }

  .main-sidebar-body .nav .nav-item .nav-sub:before {
    right: 0px;
  }

  .main-sidebar-body .nav-sub .nav-sub-item .nav-sub-link:before::hover {
    right: -27px;
  }

  .main-sidebar .search .btn {
    left: 0;
  }

  .sidebar {
    border-right: 1px solid #ededf5;
  }

  .tabs-menu ul li a {
    padding: 10px 13px 11px 12px;
  }

  .sidebar .nav.panel-tabs .side-menu__icon {
    margin-left: 2px;
  }

  .main-donut-chart .slice {
    right: 0;
  }

  .legend {
    margin-left: 9px;
  }

  .dot-label {
    right: 9px;
  }

  .chart-legend div+div {
    margin-right: 15px;
  }

  .chart-legend span {
    margin-left: 5px;
  }

  @media (min-width: 576px) {
    .chart-legend div+div {
      margin-right: 30px;
    }
  }

  @media (min-width: 576px) {
    .chart-legend span {
      margin-left: 10px;
    }
  }

  @media (min-width: 576px) {
    .chart-wrapper {
      margin-right: -10px;
      margin-left: -15px;
    }
  }

  table.dataTable thead .sorting_asc::after,
  table.dataTable thead .sorting_desc::after {
    left: 10px;
    right: auto;
  }

  table.dataTable thead .sorting::before,
  table.dataTable thead .sorting::after {
    left: 10px;
    right: auto;
  }

  table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child::before {
    right: 7px;
  }

  .dataTables_wrapper .dataTables_length {
    text-align: right;
  }

  .dataTables_wrapper .dataTables_length .select2-container--default {
    margin-left: 5px;
    margin-right: auto;
  }

  .dataTables_wrapper .dataTables_filter {
    text-align: right;
  }

  .dataTables_wrapper .dataTables_filter input {
    margin-right: 0;
    margin-left: inherit;
  }

  .dataTables_wrapper .dataTables_info {
    text-align: right;
  }

  .dataTables_wrapper .dataTables_paginate {
    text-align: right;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button+.paginate_button {
    margin-right: 0px;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button.previous {
    margin-left: 3px;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button.next {
    margin-right: 3px;
  }

  @media (min-width: 576px) {
    .dataTables_wrapper .dataTables_filter {
      float: left;
    }
  }

  @media (min-width: 576px) {
    .dataTables_wrapper .dataTables_info {
      float: right;
    }
  }

  @media (min-width: 576px) {
    .dataTables_wrapper .dataTables_paginate {
      float: left;
    }
  }

  @media (max-width: 414px) {
    div.dataTables_wrapper div.dataTables_filter input {
      margin-right: -12px;
    }
  }

  .hoverable-table .btn-primary {
    margin-right: 98px;
  }

  .ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
    left: 5px;
    right: auto;
    transform: rotate(180deg);
  }

  .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
    right: 5px;
    left: auto;
    transform: rotate(180deg);
  }

  .datetimepicker table th.prev span,
  .datetimepicker table th.next span {
    transform: rotate(180deg);
  }

  .datetimepicker table th.prev span::before {
    right: 0;
  }

  .datetimepicker table th.next span::before {
    left: 0;
  }

  .main-datetimepicker>.datepicker_header a:nth-child(2)::before {
    right: 0;
  }

  .main-datetimepicker>.datepicker_header a:last-child::before {
    left: 0;
  }

  .main-datetimepicker>.datepicker_inner_container>.datepicker_timelist {
    border-right: 1px solid #ededf5;
  }

  .main-datetimepicker>.datepicker_inner_container>.datepicker_timelist>div.timelist_item {
    margin: 0 1px 0 0;
  }

  .wizard>.steps>ul li .title {
    margin-right: 5px;
    margin-left: inherit;
  }

  .wizard>.steps>ul li+li {
    margin-right: 5px;
    margin-left: inherit;
  }

  .wizard>.actions a {
    margin-left: 10px;
    margin-right: inherit;
  }

  .wizard.vertical>.steps ul li+li {
    margin-right: 10px;
    margin-left: inherit;
  }

  @media (min-width: 576px) {
    .wizard>.steps>ul li .title {
      margin-right: 10px;
      margin-left: inherit;
    }
  }

  @media (min-width: 576px) {
    .wizard>.steps>ul li+li {
      margin-right: 20px;
      margin-left: inherit;
    }
  }

  @media (min-width: 992px) {
    .wizard>.steps>ul li+li {
      margin-right: 30px;
      margin-left: inherit;
    }
  }

  @media (min-width: 576px) {
    .wizard.vertical>.steps {
      float: right;
    }
  }

  @media (min-width: 576px) {
    .wizard.vertical>.steps ul li+li {
      margin-right: 0;
      margin-left: inherit;
    }
  }

  @media (min-width: 576px) {
    .wizard.vertical>.content {
      float: left;
      border-right: 1px solid #ededf5;
      border-left: 0;
    }
  }

  @media (min-width: 576px) {
    .wizard.vertical>.actions {
      float: left;
      border-right: 1px solid #ededf5;
    }
  }

  .wizard-style-2>.steps>ul a .title,
  .wizard-style-2>.steps>ul a:hover .title,
  .wizard-style-2>.steps>ul a:active .title {
    margin-left: 20px;
    margin-right: inherit;
  }

  #jqvmap1_ca_pin {
    margin-right: -2%;
  }

  #jqvmap1_ct_pin {
    margin-right: -0.25%;
  }

  #jqvmap1_fl_pin {
    margin-right: 5%;
  }

  #jqvmap1_id_pin {
    margin-right: -1%;
  }

  #jqvmap1_ky_pin {
    margin-right: 2%;
  }

  #jqvmap1_la_pin {
    margin-right: -2%;
  }

  #jqvmap1_mi_pin {
    margin-right: 3%;
  }

  #jqvmap1_mn_pin {
    margin-right: -2%;
  }

  #jqvmap1_nh_pin {
    margin-right: -0.25%;
  }

  #jqvmap1_ok_pin,
  #jqvmap1_va_pin {
    margin-right: 2%;
  }

  #jqvmap1_wv_pin {
    margin-right: -1%;
  }

  .ql-bubble .ql-toolbar {
    padding: 0 8px 8px;
  }

  .ql-bubble .ql-toolbar .ql-formats {
    margin: 8px 0 0 8px;
  }

  .ql-bubble .ql-toolbar .ql-formats:first-child {
    margin-right: 0;
  }

  @media (min-width: 576px) {
    .ql-bubble .ql-toolbar .ql-formats {
      margin: 8px 0 0;
    }

    .ql-bubble .ql-toolbar .ql-formats:first-child {
      margin-right: 0;
    }
  }

  .ql-snow .ql-formats button+button {
    margin: 0 2px 0 0;
  }

  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    left: 3px;
    right: inherit;
  }

  .ql-editor {
    text-align: right;
  }

  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    float: right;
  }

  .ql-snow .ql-picker-label {
    padding-right: 10px;
  }

  @media (min-width: 992px) {
    .ql-scrolling-demo {
      padding-right: 0;
    }
  }

  .parsley-errors-list li {
    margin: 5px 0 0;
  }

  .irs-bar-edge {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .irs-line-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .irs-line-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .irs-slider:before {
    right: 50%;
    margin-right: -1px;
  }

  .irs-modern .irs-slider::before {
    margin-right: -2px;
  }

  .irs-outline .irs-slider::before {
    margin-right: -4px;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    margin-left: 4px;
    padding: 3px 20px 3px 10px;
    float: right;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    right: 7px;
  }

  .select2-container--default .select2-search--inline .select2-search__field {
    padding-right: 7px;
  }

  .select2-container--default.select2-container--disabled .select2-selection__choice {
    padding-right: 10px;
  }

  .select2-lg .select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 4px !important;
    padding-left: inherit;
  }

  .sp-picker-container {
    border-right: 0;
  }

  .sp-dd::before {
    right: 0;
  }

  .card--calendar .card--events .list-group-item {
    padding: 13px 40px 13px 20px;
  }

  .card--calendar .card--events .list-group-item {
    padding: 13px 40px 13px 20px;
  }

  .card--events .list-group-item {
    padding: 10px 40px 10px 18px;
  }

  .card--events .event-indicator {
    right: 20px;
  }

  .card--events .event-user .main-img-user+.main-img-user {
    margin-right: -6px;
  }

  .card--events .event-user a:last-child {
    margin-right: 10px;
  }

  .ui-datepicker-multi .ui-datepicker-group {
    float: right;
  }

  .ui-datepicker-multi .ui-datepicker-group .ui-datepicker-prev::before {
    right: 10px;
  }

  .ui-datepicker-multi .ui-datepicker-group .ui-datepicker-next::before {
    left: 10px;
  }

  .ui-datepicker-multi .ui-datepicker-group-last {
    padding-left: 0;
  }

  .main-nav-calendar-event .nav-link i {
    margin-left: 10px;
  }

  @media (min-width: 992px) {
    .main-content-body-calendar {
      padding-right: 25px;
    }
  }

  .main-calendar .fc-view .fc-day-number {
    margin: 2px 0px 0 2px;
  }

  .main-calendar .fc-view.fc-listMonth-view .fc-list-item>td.fc-list-item-time,
  .main-calendar .fc-view.fc-listWeek-view .fc-list-item>td.fc-list-item-time {
    border-left: 1px solid #f0f0f8;
  }

  .main-calendar .fc-view.fc-listMonth-view .fc-list-item>td.fc-list-item-title,
  .main-calendar .fc-view.fc-listWeek-view .fc-list-item>td.fc-list-item-title {
    border-left: 1px solid #f0f0f8;
  }

  .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
  .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
    margin-right: 20%;
  }

  .main-calendar .fc-time-grid-event {
    border-left-width: 0;
  }

  @media (max-width: 575px) {

    .main-calendar .fc-header-toolbar button.fc-month-button::before,
    .main-calendar .fc-header-toolbar button.fc-agendaWeek-button::before,
    .main-calendar .fc-header-toolbar button.fc-agendaDay-button::before,
    .main-calendar .fc-header-toolbar button.fc-listWeek-button::before,
    .main-calendar .fc-header-toolbar button.fc-listMonth-button::before {
      right: 10px;
    }

    .main-calendar .fc-header-toolbar button.fc-listWeek-button::before,
    .main-calendar .fc-header-toolbar button.fc-listMonth-button::before {
      right: 11px;
    }
  }

  @media (max-width: 991.98px) {

    .main-calendar .fc-view>table>thead th:first-child,
    .main-calendar .fc-view>table>thead td:first-child {
      border-left-width: 0;
    }

    .main-calendar .fc-view>table>thead th:last-child,
    .main-calendar .fc-view>table>thead td:last-child {
      border-right-width: 0;
    }
  }

  @media (max-width: 991.98px) {
    .main-calendar .fc-view>table>tbody>tr>td:first-child {
      border-left-width: 0;
    }

    .main-calendar .fc-view>table>tbody>tr>td:last-child {
      border-right-width: 0;
    }
  }

  @media (min-width: 576px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
      margin-right: 15%;
    }
  }

  @media (min-width: 992px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
      margin-right: 12%;
    }
  }

  @media (min-width: 992px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
      margin-right: 10%;
    }
  }

  @media (min-width: 1200px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
      margin-right: 8%;
    }
  }

  @media (min-width: 576px) {
    .main-calendar .fc-event {
      border-left-width: 2px;
    }
  }

  .main-modal-calendar-schedule .modal-content>.close {
    left: 25px;
  }

  .main-modal-calendar-schedule .form-group-date>i {
    margin-left: 10px;
  }

  .main-modal-calendar-event .nav-modal-event .nav-link+.nav-link {
    margin-right: 15px;
  }

  .main-nav-line-chat {
    padding-right: 20px;
  }

  .main-nav-line-chat .nav-link+.nav-link {
    margin-right: 5px;
  }

  .main-chat-contacts-wrapper .main-img-user::after,
  .main-chat-list .main-img-user::after,
  .main-chat-header .main-img-user::after,
  .main-chat-body .main-img-user::after {
    left: 1px;
  }

  .main-chat-contacts-wrapper .main-content-label,
  .main-chat-contacts-wrapper .card-table-two .card-title {
    padding-right: 20px;
  }

  @media (min-width: 992px) {
    .main-chat-contacts-wrapper {
      padding: 10px 0px 10px 15px;
    }
  }

  .main-chat-contacts {
    padding-right: 20px;
  }

  .main-chat-list .media::after {
    right: -1px;
  }

  .main-chat-list .media+.media::before {
    right: 65px;
    left: 0;
  }

  .main-chat-list .media.selected {
    border-right: 1px solid #38cab3;
    border-left: inherit;
  }

  .main-chat-list .main-img-user span {
    right: -2px;
  }

  .main-chat-list .media-body {
    margin-right: 15px;
    margin-left: inherit;
  }

  .main-chat-header .nav {
    margin-right: auto;
    margin-left: inherit;
  }

  .main-chat-header .nav-link+.nav-link {
    margin-right: 10px;
    margin-left: inherit;
  }

  .main-chat-msg-name {
    margin-right: 15px;
  }

  .main-chat-body .media.flex-row-reverse .media-body {
    margin-right: 0;
    margin-left: 20px;
  }

  .main-chat-body .media-body {
    margin-right: 20px;
  }

  @media (min-width: 576px) {
    .main-chat-body .media.flex-row-reverse .media-body {
      margin-right: 55px;
    }
  }

  @media (min-width: 576px) {
    .main-chat-body .media-body {
      margin-left: 55px;
    }
  }

  .main-chat-footer .nav-link+.nav-link {
    margin-right: 10px;
  }

  .main-chat-footer .nav-link:last-child {
    margin-right: 0;
  }

  @media (min-width: 576px) {
    .main-chat-footer .form-control {
      margin-right: 20px;
    }
  }

  @media (min-width: 992px) {
    .main-chat-footer .form-control {
      margin: 0 0px 0 20px;
    }
  }

  @media (max-width: 991px) and (min-width: 992px) {
    .app.sidebar-mini .main-chat-footer {
      margin-right: 0px;
    }

    .app.sidebar-mini.sidenav-toggled .main-chat-footer {
      margin-right: 0;
    }
  }

  @media (max-width: 380px) and (min-width: 305px) {
    .main-nav-line-chat .nav-link+.nav-link {
      margin-right: 0px;
    }
  }

  #action_menu_btn {
    left: 36px;
  }

  .action_menu {
    left: 15px;
  }

  .action-header {
    padding: 15px 17px 15px 13px;
  }

  .ah-actions {
    float: left !important;
  }

  .chat .dropdown-menu.dropdown-menu-end.show {
    right: 0px;
  }

  .chat .msb-reply textarea {
    margin-right: -42px;
  }

  .chat .video_cam {
    margin-right: 50px;
  }

  .chat .video_cam span {
    margin-left: 20px;
  }

  .chat .msg_cotainer {
    margin-right: 10px;
  }

  .chat .msg_cotainer_send {
    margin-left: 10px;
  }

  .chat .msg_time {
    right: 0;
  }

  .chat .msg_time_send {
    left: 0;
  }

  @media (max-width: 449px) {
    .actions {
      float: right !important;
    }
  }

  .main-chat-footer .form-control {
    padding-right: 10px !important;
    padding-left: 0;
  }

  .main-msg-wrapper.left:after,
  .main-msg-wrapper.left:before {
    left: 100%;
    right: inherit;
  }

  .main-msg-wrapper.left:nth-child(1):before {
    border-left-color: #38cab3;
  }

  .main-msg-wrapper.left:nth-child(1):after {
    border-left-color: $primary-02;
    border-right-color: transparent !important;
  }

  .main-msg-wrapper.right:after,
  .main-msg-wrapper.right:before {
    right: 100%;
  }

  .main-msg-wrapper.right:nth-child(1):before {
    border-right-color: rgba(56, 202, 179, 0.08);
  }

  .main-msg-wrapper.right:nth-child(1):after {
    border-right-color: $primary;
    border-left-color: transparent;
  }

  .main-content-left-contacts .main-content-breadcrumb,
  .main-content-left-contacts .main-content-title {
    padding-right: 20px;
  }

  @media (min-width: 992px) {

    .main-content-left-contacts .main-content-breadcrumb,
    .main-content-left-contacts .main-content-title {
      padding-right: 0;
    }
  }

  .main-contact-label {
    padding-right: 20px;
    margin-right: -10px;
  }

  @media (min-width: 992px) {
    .main-contact-label {
      padding-right: 30px;
    }
  }

  .main-contact-item {
    border-right-width: 0;
  }

  .main-contact-item.selected {
    border-left-color: #38cab3;
  }

  .main-contact-body {
    margin-right: 15px;
  }

  .main-contact-info-header {
    padding-right: 20px;
  }

  .main-contact-info-header .main-img-user a {
    left: 0;
  }

  .main-contact-info-header .media-body .nav-link i {
    margin-left: 5px;
  }

  .main-contact-info-header .media-body .nav-link+.nav-link {
    margin-right: 10px;
  }

  @media (min-width: 992px) {
    .main-contact-info-header {
      padding-right: 30px;
    }
  }

  @media (min-width: 992px) {
    .main-contact-info-header .media-body {
      margin-right: 30px;
      margin-left: inherit;
    }
  }

  @media (min-width: 576px) {
    .main-contact-info-header .media-body .nav-link i {
      margin-left: 7px;
    }
  }

  @media (min-width: 576px) {
    .main-contact-info-header .media-body .nav-link+.nav-link {
      margin-right: 25px;
    }
  }

  .main-contact-action {
    left: 20px;
    right: inherit;
  }

  @media (min-width: 992px) {
    .main-contact-action {
      left: 20px;
    }
  }

  .main-contact-info-body {
    padding-right: 20px;
  }

  .main-contact-info-body .media+.media::before {
    right: -19px;
    left: 0;
  }

  .main-contact-info-body .media-body {
    margin-right: 0;
  }

  .main-contact-item .main-img-user::after {
    left: 0;
  }

  .contact-icon {
    margin-right: 3px;
    margin-left: inherit;
  }

  .page-signin-style:before {
    right: 0;
  }

  .page-signin-style {
    right: 0;
  }

  @media (min-width: 992px) {
    .main-column-signup {
      border-right: 1px solid #e3e3e3;
    }
  }

  .main-signup-header .row>div .btn i,
  .main-signup-header .row>div .sp-container button i {
    margin-left: 5px;
  }

  .sp-container .main-signup-header .row>div button i {
    margin-left: 5px;
  }

  .construction .input-group-prepend,
  .construction .input-group-append {
    left: 0;
  }

  .construction .input-group>.input-group-append>.btn {
    border-radius: 0 0px 4px 4px;
    float: left;
  }

  .construction .input-group>.form-control:not(:last-child),
  .construction .input-group>.custom-select:not(:last-child) {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .main-content-left-invoice .main-content-breadcrumb,
  .main-content-left-invoice .main-content-title {
    padding-right: 20px;
  }

  @media (min-width: 992px) {
    .main-content-left-invoice {
      border-left: 1px solid #ededf5;
    }
  }

  @media (min-width: 992px) {

    .main-content-left-invoice .main-content-breadcrumb,
    .main-content-left-invoice .main-content-title {
      padding-right: 0;
    }
  }

  .main-invoice-list .media+.media::before {
    right: 55px;
    left: 0;
  }

  .main-invoice-list .media-body {
    margin-right: 15px;
  }

  .main-invoice-list .selected {
    border-left-color: #38cab3;
  }

  @media (min-width: 992px) {
    .main-content-left-mail .btn-compose {
      margin-left: 0;
    }
  }

  .main-mail-menu {
    padding-left: 0;
  }

  @media (min-width: 992px) {
    .main-mail-menu {
      padding-left: 0;
    }
  }

  .main-mail-header>div:last-child>span {
    margin-left: 10px;
  }

  @media (min-width: 992px) {
    .main-mail-header {
      padding: 0 25px 0 0px;
    }
  }

  .main-mail-header .btn-group .btn+.btn,
  .main-mail-header .btn-group .sp-container button+.btn {
    margin-right: -2px;
  }

  .sp-container .main-mail-header .btn-group button+.btn,
  .main-mail-header .btn-group .sp-container .btn+button,
  .sp-container .main-mail-header .btn-group .btn+button,
  .main-mail-header .btn-group .sp-container button+button,
  .sp-container .main-mail-header .btn-group button+button {
    margin-right: -2px;
  }

  .main-mail-options {
    padding: 5px 20px 5px 10px;
  }

  @media (min-width: 992px) {
    .main-mail-options {
      padding-right: 25px;
    }
  }

  .main-mail-item .main-img-user,
  .main-mail-item .main-avatar {
    margin-left: 15px;
    margin-right: inherit;
  }

  .main-mail-checkbox {
    margin-left: 15px;
  }

  .main-mail-star {
    margin-left: 15px;
    margin-right: inherit;
    left: 0;
    right: inherit;
  }

  @media (min-width: 992px) {
    .main-mail-body {
      margin-left: 15px;
      margin-right: inherit;
    }
  }

  .main-mail-attachment {
    margin-left: 15px;
  }

  @media (min-width: 992px) {
    .main-mail-attachment {
      left: 63px;
    }
  }

  .main-mail-date {
    left: 15px;
    right: inherit;
    margin-right: auto;
  }

  .main-mail-compose {
    right: 0;
  }

  .main-mail-compose-header .nav-link+.nav-link {
    margin-right: 15px;
  }

  .main-mail-compose-body .form-group>div {
    margin-right: 10px;
  }

  .main-mail-compose-body .form-group .nav-link+.nav-link {
    margin-right: 15px;
  }

  .main-mail-compose-compress,
  .main-mail-compose-minimize {
    right: auto;
    left: 30px;
  }

  .main-mail-two .main-header-menu-icon {
    margin-left: 0;
  }

  .main-mail-left .main-mail-menu {
    padding-left: 0;
  }

  .border-end {
    border-left: 1px solid #ededf5 !important;
    border-right: inherit !important;
  }

  .border-start {
    border-right: 1px solid #ededf5 !important;
    border-left: inherit !important;
  }

  .border-end-0 {
    border-left: 0 !important;
  }

  .border-start-0 {
    border-right: 0 !important;
  }

  .rounded-end {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }

  .rounded-start {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }

  .bd-e {
    border-left: 1px solid #ededf5;
    border-right: inherit;
  }

  .bd-s {
    border-right: 1px solid #ededf5;
    border-left: inherit;
  }

  .bd-l {
    border-right: 1px solid #ededf5;
    border-left: inherit;
  }

  .bd-x {
    border-right: 1px solid #ededf5;
    border-left: 1px solid #ededf5;
  }

  .bd-e-0 {
    border-left: 0;
  }

  .bd-s-0 {
    border-right: 0;
  }

  @media (min-width: 480px) {
    .bd-xs-e {
      border-left: 1px solid #ededf5;
    }

    .bd-xs-e {
      border-right: 1px solid #ededf5;
    }
  }

  @media (min-width: 576px) {
    .bd-sm-e {
      border-left: 1px solid #ededf5;
    }

    .bd-sm-s {
      border-right: 1px solid #ededf5;
    }

    .ms-sm-auto {
      margin-right: auto !important;
      margin-left: inherit !important;
    }
  }

  @media (min-width: 992px) {
    .bd-md-e {
      border-left: 1px solid #ededf5;
    }

    .bd-md-s {
      border-right: 1px solid #ededf5;
    }
  }

  @media (min-width: 992px) {
    .bd-lg-enf {
      border-left: 1px solid #ededf5;
    }

    .bd-lg-s {
      border-right: 1px solid #ededf5;
    }
  }

  @media (min-width: 1200px) {
    .bd-xl-e {
      border-left: 1px solid #ededf5;
    }

    .bd-xl-s {
      border-right: 1px solid #ededf5;
    }
  }

  .br-ts-0 {
    border-top-right-radius: 0 !important;
  }

  .br-bs-0 {
    border-bottom-right-radius: 0 !important;
  }

  .br-te-0 {
    border-top-left-radius: 0 !important;
  }

  .br-be-0 {
    border-bottom-left-radius: 0 !important;
  }

  .br-ts-5 {
    border-top-right-radius: 5px !important;
  }

  .br-te-5 {
    border-top-left-radius: 5px !important;
  }

  .br-be-5 {
    border-bottom-left-radius: 5px !important;
  }

  .br-bs-5 {
    border-bottom-right-radius: 5px !important;
  }

  .border-start-primary {
    border-left-color: #38cab3 !important;
  }

  .border-start-success {
    border-left-color: #1a9c86 !important;
  }

  .border-start-warning {
    border-left-color: #ffbd5a !important;
  }

  .me-0 {
    margin-left: 0 !important;
    margin-right: inherit !important;
  }

  .ms-0 {
    margin-right: 0 !important;
    margin-left: inherit !important;
  }

  .me-1 {
    margin-left: 0.25rem !important;
    margin-right: inherit !important;
  }

  .ms-1 {
    margin-right: 0.25rem !important;
    margin-left: inherit !important;
  }

  .me-2 {
    margin-left: 0.5rem !important;
    margin-right: initial !important;
  }

  .ms-2 {
    margin-right: 0.5rem !important;
    margin-left: inherit !important;
  }

  .me-3 {
    margin-left: 1rem !important;
    margin-right: inherit !important;
  }

  .ms-3 {
    margin-right: 1rem !important;
    margin-left: inherit !important;
  }

  .me-4 {
    margin-left: 1.5rem !important;
    margin-right: inherit !important;
  }

  .ms-4 {
    margin-right: 1.5rem !important;
    margin-left: inherit !important;
  }

  .me-5 {
    margin-left: 3rem !important;
    margin-right: inherit !important;
  }

  .ms-5 {
    margin-right: 3rem !important;
  }

  .me-auto {
    margin-left: auto !important;
    margin-right: inherit !important;
  }

  .ms-auto {
    margin-right: auto !important;
    margin-left: initial !important;
  }

  @media (max-width: 991px) {
    .me-sm-0 {
      margin-left: 0 !important;
    }

    .ms-sm-0 {
      margin-right: 0 !important;
    }

    .me-sm-1 {
      margin-left: 0.25rem !important;
    }

    .ms-sm-1 {
      margin-right: 0.25rem !important;
    }

    .me-sm-2 {
      margin-left: 0.5rem !important;
    }

    .ms-sm-2 {
      margin-right: 0.5rem !important;
    }

    .me-sm-3 {
      margin-left: 1rem !important;
    }

    .ms-sm-3 {
      margin-right: 1rem !important;
    }

    .me-sm-4 {
      margin-left: 1.5rem !important;
    }

    .ms-sm-4 {
      margin-right: 1.5rem !important;
    }

    .me-sm-5 {
      margin-left: 3rem !important;
    }

    .ms-sm-5 {
      margin-right: 3rem !important;
    }
  }

  @media (min-width: 768px) {
    .me-md-0 {
      margin-left: 0 !important;
    }

    .ms-md-0 {
      margin-right: 0 !important;
    }

    .me-md-1 {
      margin-left: 0.25rem !important;
    }

    .ms-md-1 {
      margin-right: 0.25rem !important;
    }

    .me-md-2 {
      margin-left: 0.5rem !important;
    }

    .ms-md-2 {
      margin-right: 0.5rem !important;
    }

    .me-md-3 {
      margin-left: 1rem !important;
    }

    .ms-md-3 {
      margin-right: 1rem !important;
    }

    .me-md-4 {
      margin-left: 1.5rem !important;
    }

    .ms-md-4 {
      margin-right: 1.5rem !important;
    }

    .me-md-5 {
      margin-left: 3rem !important;
    }

    .ms-md-5 {
      margin-right: 3rem !important;
    }

    .me-md-auto {
      margin-left: auto !important;
    }

    .ms-md-auto {
      margin-right: auto !important;
    }
  }

  @media (min-width: 992px) {
    .me-lg-0 {
      margin-left: 0 !important;
    }

    .ms-lg-0 {
      margin-right: 0 !important;
    }

    .me-lg-1 {
      margin-left: 0.25rem !important;
    }

    .ms-lg-1 {
      margin-right: 0.25rem !important;
    }

    .me-lg-2 {
      margin-left: 0.5rem !important;
    }

    .ms-lg-2 {
      margin-right: 0.5rem !important;
    }

    .me-lg-3 {
      margin-left: 1rem !important;
    }

    .ms-lg-3 {
      margin-right: 1rem !important;
    }

    .me-lg-4 {
      margin-left: 1.5rem !important;
    }

    .ms-lg-4 {
      margin-right: 1.5rem !important;
    }

    .me-lg-5 {
      margin-left: 3rem !important;
    }

    .ms-lg-5 {
      margin-right: 3rem !important;
    }

    .me-lg-auto {
      margin-left: auto !important;
    }

    .ms-lg-auto {
      margin-right: auto !important;
    }
  }

  @media (min-width: 1200px) {
    .me-xl-0 {
      margin-left: 0 !important;
    }

    .ms-xl-0 {
      margin-right: 0 !important;
    }

    .me-xl-1-1 {
      margin-left: 0.25rem !important;
    }

    .ms-xl-1 {
      margin-right: 0.25rem !important;
    }

    .me-xl-2 {
      margin-left: 0.5rem !important;
    }

    .ms-xl-2 {
      margin-right: 0.5rem !important;
    }

    .me-xl-3 {
      margin-left: 1rem !important;
    }

    .ms-xl-3 {
      margin-right: 1rem !important;
    }

    .me-xl-4 {
      margin-left: 1.5rem !important;
    }

    .ms-xl-4 {
      margin-right: 1.5rem !important;
    }

    .me-xl-5 {
      margin-left: 3rem !important;
    }

    .ms-xl-5 {
      margin-right: 3rem !important;
    }

    .me-xl-auto {
      margin-left: auto !important;
    }

    .ms-xl-auto {
      margin-right: auto !important;
    }
  }

  .mg-r-0 {
    margin-left: 0px;
  }

  .mg-l-0 {
    margin-right: 0px;
  }

  .mg-r-1 {
    margin-left: 1px;
  }

  .mg-l-1 {
    margin-right: 1px;
  }

  .mg-r-2 {
    margin-left: 2px;
  }

  .mg-l-2 {
    margin-right: 2px;
  }

  .mg-r-3 {
    margin-left: 3px;
  }

  .mg-l-3 {
    margin-right: 3px;
  }

  .mg-r-4 {
    margin-left: 4px;
  }

  .mg-l-4 {
    margin-right: 4px;
  }

  .mg-r-5 {
    margin-left: 5px;
  }

  .mg-l-5 {
    margin-right: 5px;
  }

  .mg-r-6 {
    margin-left: 6px;
  }

  .mg-l-6 {
    margin-right: 6px;
  }

  .mg-r-7 {
    margin-left: 7px;
  }

  .mg-l-7 {
    margin-right: 7px;
  }

  .mg-r-8 {
    margin-left: 8px;
  }

  .mg-l-8 {
    margin-right: 8px;
  }

  .mg-r-9 {
    margin-left: 9px;
  }

  .mg-l-9 {
    margin-right: 9px;
  }

  .mg-r-10 {
    margin-left: 10px;
    margin-right: inherit;
  }

  .mg-l-10 {
    margin-right: 10px;
  }

  .mg-r-15 {
    margin-left: 15px;
  }

  .mg-l-15 {
    margin-right: 15px;
  }

  .mg-r-20 {
    margin-left: 20px;
    margin-right: inherit;
  }

  .mg-l-20 {
    margin-right: 20px;
    margin-left: 0;
  }

  .mg-r-25 {
    margin-left: 25px;
  }

  .mg-l-25 {
    margin-right: 25px;
  }

  .mg-r-30 {
    margin-left: 30px;
  }

  .mg-b-30 {
    margin-bottom: 30px;
  }

  .mg-l-30 {
    margin-right: 30px;
  }

  .mg-r-35 {
    margin-left: 35px;
  }

  .mg-l-35 {
    margin-right: 35px;
  }

  .mg-r-40 {
    margin-left: 40px;
  }

  .mg-l-40 {
    margin-right: 40px;
  }

  .mg-r-45 {
    margin-left: 45px;
  }

  .mg-l-45 {
    margin-right: 45px;
  }

  .mg-r-50 {
    margin-left: 50px;
  }

  .mg-l-50 {
    margin-right: 50px;
  }

  .mg-r-55 {
    margin-left: 55px;
  }

  .mg-l-55 {
    margin-right: 55px;
  }

  .mg-r-60 {
    margin-left: 60px;
  }

  .mg-l-60 {
    margin-right: 60px;
  }

  .mg-r-65 {
    margin-left: 65px;
  }

  .mg-l-65 {
    margin-right: 65px;
  }

  .mg-r-70 {
    margin-left: 70px;
  }

  .mg-l-70 {
    margin-right: 70px;
  }

  .mg-r-75 {
    margin-left: 75px;
  }

  .mg-l-75 {
    margin-right: 75px;
  }

  .mg-r-80 {
    margin-left: 80px;
  }

  .mg-l-80 {
    margin-right: 80px;
  }

  .mg-r-85 {
    margin-left: 85px;
  }

  .mg-l-85 {
    margin-right: 85px;
  }

  .mg-r-90 {
    margin-left: 90px;
  }

  .mg-l-90 {
    margin-right: 90px;
  }

  .mg-r-95 {
    margin-left: 95px;
  }

  .mg-l-95 {
    margin-right: 95px;
  }

  .mg-r-100 {
    margin-left: 100px;
  }

  .mg-l-100 {
    margin-right: 100px;
  }

  .mg-r-105 {
    margin-left: 105px;
  }

  .mg-l-105 {
    margin-right: 105px;
  }

  .mg-r-110 {
    margin-left: 110px;
  }

  .mg-l-110 {
    margin-right: 110px;
  }

  .mg-r-115 {
    margin-left: 115px;
  }

  .mg-l-115 {
    margin-right: 115px;
  }

  .mg-r-120 {
    margin-left: 120px;
  }

  .mg-l-120 {
    margin-right: 120px;
  }

  .mg-r-auto {
    margin-left: auto;
  }

  .mg-l-auto {
    margin-right: auto;
  }

  @media (min-width: 480px) {
    .mg-xs-r-0 {
      margin-left: 0px;
    }

    .mg-xs-l-0 {
      margin-right: 0px;
    }

    .mg-xs-r-1 {
      margin-left: 1px;
    }

    .mg-xs-l-1 {
      margin-right: 1px;
    }

    .mg-xs-r-2 {
      margin-left: 2px;
    }

    .mg-xs-l-2 {
      margin-right: 2px;
    }

    .mg-xs-r-3 {
      margin-left: 3px;
    }

    .mg-xs-l-3 {
      margin-right: 3px;
    }

    .mg-xs-r-4 {
      margin-left: 4px;
    }

    .mg-xs-l-4 {
      margin-right: 4px;
    }

    .mg-xs-r-5 {
      margin-left: 5px;
    }

    .mg-xs-l-5 {
      margin-right: 5px;
    }

    .mg-xs-r-6 {
      margin-left: 6px;
    }

    .mg-xs-l-6 {
      margin-right: 6px;
    }

    .mg-xs-r-7 {
      margin-left: 7px;
    }

    .mg-xs-l-7 {
      margin-right: 7px;
    }

    .mg-xs-r-8 {
      margin-left: 8px;
    }

    .mg-xs-l-8 {
      margin-right: 8px;
    }

    .mg-xs-r-9 {
      margin-left: 9px;
    }

    .mg-xs-l-9 {
      margin-right: 9px;
    }

    .mg-xs-r-10 {
      margin-left: 10px;
    }

    .mg-xs-l-10 {
      margin-right: 10px;
    }

    .mg-xs-r-15 {
      margin-left: 15px;
    }

    .mg-xs-l-15 {
      margin-right: 15px;
    }

    .mg-xs-r-20 {
      margin-left: 20px;
    }

    .mg-xs-l-20 {
      margin-right: 20px;
    }

    .mg-xs-r-25 {
      margin-left: 25px;
    }

    .mg-xs-l-25 {
      margin-right: 25px;
    }

    .mg-xs-r-30 {
      margin-left: 30px;
    }

    .mg-xs-l-30 {
      margin-right: 30px;
    }

    .mg-xs-r-35 {
      margin-left: 35px;
    }

    .mg-xs-l-35 {
      margin-right: 35px;
    }

    .mg-xs-r-40 {
      margin-left: 40px;
    }

    .mg-xs-l-40 {
      margin-right: 40px;
    }

    .mg-xs-r-45 {
      margin-left: 45px;
    }

    .mg-xs-l-45 {
      margin-right: 45px;
    }

    .mg-xs-r-50 {
      margin-left: 50px;
    }

    .mg-xs-l-50 {
      margin-right: 50px;
    }

    .mg-xs-r-55 {
      margin-left: 55px;
    }

    .mg-xs-l-55 {
      margin-right: 55px;
    }

    .mg-xs-r-60 {
      margin-left: 60px;
    }

    .mg-xs-l-60 {
      margin-right: 60px;
    }

    .mg-xs-r-65 {
      margin-left: 65px;
    }

    .mg-xs-l-65 {
      margin-right: 65px;
    }

    .mg-xs-r-70 {
      margin-left: 70px;
    }

    .mg-xs-l-70 {
      margin-right: 70px;
    }

    .mg-xs-r-75 {
      margin-left: 75px;
    }

    .mg-xs-l-75 {
      margin-right: 75px;
    }

    .mg-xs-r-80 {
      margin-left: 80px;
    }

    .mg-xs-l-80 {
      margin-right: 80px;
    }

    .mg-xs-t-85 {
      margin-top: 85px;
    }

    .mg-xs-r-85 {
      margin-left: 85px;
    }

    .mg-xs-l-85 {
      margin-right: 85px;
    }

    .mg-xs-r-90 {
      margin-left: 90px;
    }

    .mg-xs-l-90 {
      margin-right: 90px;
    }

    .mg-xs-r-95 {
      margin-left: 95px;
    }

    .mg-xs-l-95 {
      margin-right: 95px;
    }

    .mg-xs-r-100 {
      margin-left: 100px;
    }

    .mg-xs-l-100 {
      margin-right: 100px;
    }

    .mg-xs-r-auto {
      margin-left: auto;
    }

    .mg-xs-l-auto {
      margin-right: auto;
    }
  }

  @media (min-width: 576px) {
    .mg-sm-r-0 {
      margin-left: 0px;
    }

    .mg-sm-l-0 {
      margin-right: 0px;
    }

    .mg-sm-r-1 {
      margin-left: 1px;
    }

    .mg-sm-l-1 {
      margin-right: 1px;
    }

    .mg-sm-r-2 {
      margin-left: 2px;
    }

    .mg-sm-l-2 {
      margin-right: 2px;
    }

    .mg-sm-r-3 {
      margin-left: 3px;
    }

    .mg-sm-l-3 {
      margin-right: 3px;
    }

    .mg-sm-r-4 {
      margin-left: 4px;
    }

    .mg-sm-l-4 {
      margin-right: 4px;
    }

    .mg-sm-r-5 {
      margin-left: 5px;
    }

    .mg-sm-l-5 {
      margin-right: 5px;
    }

    .mg-sm-r-6 {
      margin-left: 6px;
    }

    .mg-sm-l-6 {
      margin-right: 6px;
    }

    .mg-sm-r-7 {
      margin-left: 7px;
    }

    .mg-sm-l-7 {
      margin-right: 7px;
    }

    .mg-sm-r-8 {
      margin-left: 8px;
    }

    .mg-sm-l-8 {
      margin-right: 8px;
    }

    .mg-sm-r-9 {
      margin-left: 9px;
    }

    .mg-sm-l-9 {
      margin-right: 9px;
    }

    .mg-sm-r-10 {
      margin-left: 10px;
    }

    .mg-sm-l-10 {
      margin-right: 10px;
    }

    .mg-sm-r-15 {
      margin-left: 15px;
    }

    .mg-sm-l-15 {
      margin-right: 15px;
    }

    .mg-sm-r-20 {
      margin-left: 20px;
      margin-right: inherit;
    }

    .mg-sm-l-20 {
      margin-right: 20px;
    }

    .mg-sm-r-25 {
      margin-left: 25px;
    }

    .mg-sm-l-25 {
      margin-right: 25px;
    }

    .mg-sm-r-30 {
      margin-left: 30px;
    }

    .mg-sm-l-30 {
      margin-right: 30px;
    }

    .mg-sm-r-35 {
      margin-left: 35px;
    }

    .mg-sm-l-35 {
      margin-right: 35px;
    }

    .mg-sm-r-40 {
      margin-left: 40px;
    }

    .mg-sm-l-40 {
      margin-right: 40px;
    }

    .mg-sm-r-45 {
      margin-left: 45px;
    }

    .mg-sm-l-45 {
      margin-right: 45px;
    }

    .mg-sm-r-50 {
      margin-left: 50px;
    }

    .mg-sm-l-50 {
      margin-right: 50px;
    }

    .mg-sm-r-55 {
      margin-left: 55px;
    }

    .mg-sm-l-55 {
      margin-right: 55px;
    }

    .mg-sm-r-60 {
      margin-left: 60px;
    }

    .mg-sm-l-60 {
      margin-right: 60px;
    }

    .mg-sm-r-65 {
      margin-left: 65px;
    }

    .mg-sm-l-65 {
      margin-right: 65px;
    }

    .mg-sm-r-70 {
      margin-left: 70px;
    }

    .mg-sm-l-70 {
      margin-right: 70px;
    }

    .mg-sm-r-75 {
      margin-left: 75px;
    }

    .mg-sm-l-75 {
      margin-right: 75px;
    }

    .mg-sm-r-80 {
      margin-left: 80px;
    }

    .mg-sm-l-80 {
      margin-right: 80px;
    }

    .mg-sm-r-85 {
      margin-left: 85px;
    }

    .mg-sm-l-85 {
      margin-right: 85px;
    }

    .mg-sm-r-90 {
      margin-left: 90px;
    }

    .mg-sm-l-90 {
      margin-right: 90px;
    }

    .mg-sm-r-95 {
      margin-left: 95px;
    }

    .mg-sm-l-95 {
      margin-right: 95px;
    }

    .mg-sm-r-100 {
      margin-left: 100px;
    }

    .mg-sm-l-100 {
      margin-right: 100px;
    }

    .mg-sm-r-auto {
      margin-left: auto;
    }

    .mg-sm-l-auto {
      margin-right: auto;
    }
  }

  @media (min-width: 992px) {
    .mg-md-r-0 {
      margin-left: 0px;
    }

    .mg-md-l-0 {
      margin-right: 0px;
    }

    .mg-md-r-1 {
      margin-left: 1px;
    }

    .mg-md-l-1 {
      margin-right: 1px;
    }

    .mg-md-r-2 {
      margin-left: 2px;
    }

    .mg-md-l-2 {
      margin-right: 2px;
    }

    .mg-md-r-3 {
      margin-left: 3px;
    }

    .mg-md-l-3 {
      margin-right: 3px;
    }

    .mg-md-r-4 {
      margin-left: 4px;
    }

    .mg-md-l-4 {
      margin-right: 4px;
    }

    .mg-md-r-5 {
      margin-left: 5px;
    }

    .mg-md-l-5 {
      margin-right: 5px;
    }

    .mg-md-r-6 {
      margin-left: 6px;
    }

    .mg-md-l-6 {
      margin-right: 6px;
    }

    .mg-md-r-7 {
      margin-left: 7px;
    }

    .mg-md-l-7 {
      margin-right: 7px;
    }

    .mg-md-r-8 {
      margin-left: 8px;
    }

    .mg-md-l-8 {
      margin-right: 8px;
    }

    .mg-md-r-9 {
      margin-left: 9px;
    }

    .mg-md-l-9 {
      margin-right: 9px;
    }

    .mg-md-r-10 {
      margin-left: 10px;
    }

    .mg-md-l-10 {
      margin-right: 10px;
    }

    .mg-md-r-15 {
      margin-left: 15px;
    }

    .mg-md-l-15 {
      margin-right: 15px;
    }

    .mg-md-r-20 {
      margin-left: 20px;
      margin-right: inherit;
    }

    .mg-md-l-20 {
      margin-right: 20px;
    }

    .mg-md-r-25 {
      margin-left: 25px;
    }

    .mg-md-l-25 {
      margin-right: 25px;
    }

    .mg-md-r-30 {
      margin-left: 30px;
    }

    .mg-md-l-30 {
      margin-right: 30px;
    }

    .mg-md-r-35 {
      margin-left: 35px;
    }

    .mg-md-l-35 {
      margin-right: 35px;
    }

    .mg-md-r-40 {
      margin-left: 40px;
    }

    .mg-md-l-40 {
      margin-right: 40px;
    }

    .mg-md-r-45 {
      margin-left: 45px;
    }

    .mg-md-l-45 {
      margin-right: 45px;
    }

    .mg-md-r-50 {
      margin-left: 50px;
    }

    .mg-md-l-50 {
      margin-right: 50px;
    }

    .mg-md-r-55 {
      margin-left: 55px;
    }

    .mg-md-l-55 {
      margin-right: 55px;
    }

    .mg-md-r-60 {
      margin-left: 60px;
    }

    .mg-md-l-60 {
      margin-right: 60px;
    }

    .mg-md-r-65 {
      margin-left: 65px;
    }

    .mg-md-l-65 {
      margin-right: 65px;
    }

    .mg-md-r-70 {
      margin-left: 70px;
    }

    .mg-md-l-70 {
      margin-right: 70px;
    }

    .mg-md-r-75 {
      margin-left: 75px;
    }

    .mg-md-l-75 {
      margin-right: 75px;
    }

    .mg-md-r-80 {
      margin-left: 80px;
    }

    .mg-md-l-80 {
      margin-right: 80px;
    }

    .mg-md-r-85 {
      margin-left: 85px;
    }

    .mg-md-l-85 {
      margin-right: 85px;
    }

    .mg-md-r-90 {
      margin-left: 90px;
    }

    .mg-md-l-90 {
      margin-right: 90px;
    }

    .mg-md-r-95 {
      margin-left: 95px;
    }

    .mg-md-l-95 {
      margin-right: 95px;
    }

    .mg-md-r-100 {
      margin-left: 100px;
    }

    .mg-md-l-100 {
      margin-right: 100px;
    }

    .mg-md-r-auto {
      margin-left: auto;
    }

    .mg-md-l-auto {
      margin-right: auto;
    }
  }

  @media (min-width: 992px) {
    .mg-lg-r-0 {
      margin-left: 0px;
    }

    .mg-lg-l-0 {
      margin-right: 0px;
    }

    .mg-lg-x-1 {
      margin-right: 1px;
      margin-left: 1px;
    }

    .mg-lg-r-1 {
      margin-left: 1px;
    }

    .mg-lg-l-1 {
      margin-right: 1px;
    }

    .mg-lg-x-2 {
      margin-right: 2px;
      margin-left: 2px;
    }

    .mg-lg-r-2 {
      margin-left: 2px;
    }

    .mg-lg-l-2 {
      margin-right: 2px;
    }

    .mg-lg-r-3 {
      margin-left: 3px;
    }

    .mg-lg-l-3 {
      margin-right: 3px;
    }

    .mg-lg-r-4 {
      margin-left: 4px;
    }

    .mg-lg-l-4 {
      margin-right: 4px;
    }

    .mg-lg-r-5 {
      margin-left: 5px;
    }

    .mg-lg-l-5 {
      margin-right: 5px;
    }

    .mg-lg-r-6 {
      margin-left: 6px;
    }

    .mg-lg-l-6 {
      margin-right: 6px;
    }

    .mg-lg-r-7 {
      margin-left: 7px;
    }

    .mg-lg-l-7 {
      margin-right: 7px;
    }

    .mg-lg-r-8 {
      margin-left: 8px;
    }

    .mg-lg-l-8 {
      margin-right: 8px;
    }

    .mg-lg-r-9 {
      margin-left: 9px;
    }

    .mg-lg-l-9 {
      margin-right: 9px;
    }

    .mg-lg-r-10 {
      margin-left: 10px;
    }

    .mg-lg-l-10 {
      margin-right: 10px;
    }

    .mg-lg-r-15 {
      margin-left: 15px;
    }

    .mg-lg-l-15 {
      margin-right: 15px;
    }

    .mg-lg-r-20 {
      margin-left: 20px;
    }

    .mg-lg-l-20 {
      margin-right: 20px;
    }

    .mg-lg-r-25 {
      margin-left: 25px;
    }

    .mg-lg-l-25 {
      margin-right: 25px;
    }

    .mg-lg-r-30 {
      margin-left: 30px;
    }

    .mg-lg-l-30 {
      margin-right: 30px;
    }

    .mg-lg-r-35 {
      margin-left: 35px;
    }

    .mg-lg-l-35 {
      margin-right: 35px;
    }

    .mg-lg-r-40 {
      margin-left: 40px;
    }

    .mg-lg-l-40 {
      margin-right: 40px;
    }

    .mg-lg-r-45 {
      margin-left: 45px;
    }

    .mg-lg-l-45 {
      margin-right: 45px;
    }

    .mg-lg-r-50 {
      margin-left: 50px;
    }

    .mg-lg-l-50 {
      margin-right: 50px;
    }

    .mg-lg-r-55 {
      margin-left: 55px;
    }

    .mg-lg-l-55 {
      margin-right: 55px;
    }

    .mg-lg-r-60 {
      margin-left: 60px;
    }

    .mg-lg-l-60 {
      margin-right: 60px;
    }

    .mg-lg-r-65 {
      margin-left: 65px;
    }

    .mg-lg-l-65 {
      margin-right: 65px;
    }

    .mg-lg-r-70 {
      margin-left: 70px;
    }

    .mg-lg-l-70 {
      margin-right: 70px;
    }

    .mg-lg-r-75 {
      margin-left: 75px;
    }

    .mg-lg-l-75 {
      margin-right: 75px;
    }

    .mg-lg-r-80 {
      margin-left: 80px;
    }

    .mg-lg-l-80 {
      margin-right: 80px;
    }

    .mg-lg-r-85 {
      margin-left: 85px;
    }

    .mg-lg-l-85 {
      margin-right: 85px;
    }

    .mg-lg-r-90 {
      margin-left: 90px;
    }

    .mg-lg-l-90 {
      margin-right: 90px;
    }

    .mg-lg-r-95 {
      margin-left: 95px;
    }

    .mg-lg-l-95 {
      margin-right: 95px;
    }

    .mg-lg-r-100 {
      margin-left: 100px;
    }

    .mg-lg-l-100 {
      margin-right: 100px;
    }

    .mg-lg-r-auto {
      margin-left: auto;
    }

    .mg-lg-l-auto {
      margin-right: auto;
    }
  }

  @media (min-width: 1200px) {
    .mg-xl-r-0 {
      margin-left: 0px;
    }

    .mg-xl-l-0 {
      margin-right: 0px;
    }

    .mg-xl-r-1 {
      margin-left: 1px;
    }

    .mg-xl-l-1 {
      margin-right: 1px;
    }

    .mg-xl-r-2 {
      margin-left: 2px;
    }

    .mg-xl-l-2 {
      margin-right: 2px;
    }

    .mg-xl-r-3 {
      margin-left: 3px;
    }

    .mg-xl-l-3 {
      margin-right: 3px;
    }

    .mg-xl-r-4 {
      margin-left: 4px;
    }

    .mg-xl-l-4 {
      margin-right: 4px;
    }

    .mg-xl-r-5 {
      margin-left: 5px;
    }

    .mg-xl-l-5 {
      margin-right: 5px;
    }

    .mg-xl-r-6 {
      margin-left: 6px;
    }

    .mg-xl-l-6 {
      margin-right: 6px;
    }

    .mg-xl-r-7 {
      margin-left: 7px;
    }

    .mg-xl-l-7 {
      margin-right: 7px;
    }

    .mg-xl-r-8 {
      margin-left: 8px;
    }

    .mg-xl-l-8 {
      margin-right: 8px;
    }

    .mg-xl-r-9 {
      margin-left: 9px;
    }

    .mg-xl-l-9 {
      margin-right: 9px;
    }

    .mg-xl-r-10 {
      margin-left: 10px;
    }

    .mg-xl-l-10 {
      margin-right: 10px;
    }

    .mg-xl-r-15 {
      margin-left: 15px;
    }

    .mg-xl-l-15 {
      margin-right: 15px;
    }

    .mg-xl-r-20 {
      margin-left: 20px;
    }

    .mg-xl-l-20 {
      margin-right: 20px;
    }

    .mg-xl-r-25 {
      margin-left: 25px;
    }

    .mg-xl-l-25 {
      margin-right: 25px;
    }

    .mg-xl-r-30 {
      margin-left: 30px;
    }

    .mg-xl-l-30 {
      margin-right: 30px;
    }

    .mg-xl-r-35 {
      margin-left: 35px;
    }

    .mg-xl-l-35 {
      margin-right: 35px;
    }

    .mg-xl-r-40 {
      margin-left: 40px;
    }

    .mg-xl-l-40 {
      margin-right: 40px;
    }

    .mg-xl-r-45 {
      margin-left: 45px;
    }

    .mg-xl-l-45 {
      margin-right: 45px;
    }

    .mg-xl-r-50 {
      margin-left: 50px;
    }

    .mg-xl-l-50 {
      margin-right: 50px;
    }

    .mg-xl-r-55 {
      margin-left: 55px;
    }

    .mg-xl-l-55 {
      margin-right: 55px;
    }

    .mg-xl-r-60 {
      margin-left: 60px;
    }

    .mg-xl-l-60 {
      margin-right: 60px;
    }

    .mg-xl-r-65 {
      margin-left: 65px;
    }

    .mg-xl-l-65 {
      margin-right: 65px;
    }

    .mg-xl-r-70 {
      margin-left: 70px;
    }

    .mg-xl-l-70 {
      margin-right: 70px;
    }

    .mg-xl-r-75 {
      margin-left: 75px;
    }

    .mg-xl-l-75 {
      margin-right: 75px;
    }

    .mg-xl-r-80 {
      margin-left: 80px;
    }

    .mg-xl-l-80 {
      margin-right: 80px;
    }

    .mg-xl-r-85 {
      margin-left: 85px;
    }

    .mg-xl-l-85 {
      margin-right: 85px;
    }

    .mg-xl-r-90 {
      margin-left: 90px;
    }

    .mg-xl-l-90 {
      margin-right: 90px;
    }

    .mg-xl-r-95 {
      margin-left: 95px;
    }

    .mg-xl-l-95 {
      margin-right: 95px;
    }

    .mg-xl-r-100 {
      margin-left: 100px;
    }

    .mg-xl-l-100 {
      margin-right: 100px;
    }

    .mg-xl-r-auto {
      margin-left: auto;
    }

    .mg-xl-l-auto {
      margin-right: auto;
    }
  }

  .pe-xl-0 {
    padding-left: 0 !important;
  }

  .ps-0 {
    padding-right: 0 !important;
  }

  .pe-1 {
    padding-left: 0.25rem !important;
  }

  .ps-1 {
    padding-right: 0.25rem !important;
  }

  .pe-2 {
    padding-left: 0.5rem !important;
  }

  .ps-2 {
    padding-right: 0.5rem !important;
  }

  .pe-3 {
    padding-left: 1rem !important;
  }

  .ps-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-4 {
    padding-right: 1.5rem !important;
  }

  .pe-5 {
    padding-left: 3rem !important;
    padding-right: inherit !important;
  }

  .ps-5 {
    padding-right: 3rem !important;
  }

  @media (min-width: 576px) {
    .pe-sm-0 {
      padding-left: 0 !important;
    }

    .ps-sm-0 {
      padding-right: 0 !important;
    }

    .pe-sm-1 {
      padding-left: 0.25rem !important;
    }

    .ps-sm-1 {
      padding-right: 0.25rem !important;
    }

    .pe-sm-2 {
      padding-left: 0.5rem !important;
    }

    .ps-sm-2 {
      padding-right: 0.5rem !important;
    }

    .pe-sm-3 {
      padding-left: 1rem !important;
    }

    .ps-sm-3 {
      padding-right: 1rem !important;
    }

    .pe-sm-4 {
      padding-left: 1.5rem !important;
    }

    .ps-sm-4 {
      padding-right: 1.5rem !important;
    }

    .pe-sm-5 {
      padding-left: 3rem !important;
    }

    .ps-sm-5 {
      padding-right: 3rem !important;
    }
  }

  @media (min-width: 992px) {
    .pe-md-0 {
      padding-left: 0 !important;
    }

    .ps-md-0 {
      padding-right: 0 !important;
    }

    .pe-md-1 {
      padding-left: 0.25rem !important;
    }

    .ps-md-1 {
      padding-right: 0.25rem !important;
    }

    .pe-md-2 {
      padding-left: 0.5rem !important;
    }

    .ps-md-2 {
      padding-right: 0.5rem !important;
    }

    .pe-md-3 {
      padding-left: 1rem !important;
    }

    .ps-md-3 {
      padding-right: 1rem !important;
    }

    .pe-md-4 {
      padding-left: 1.5rem !important;
    }

    .ps-md-4 {
      padding-right: 1.5rem !important;
    }

    .pe-md-5 {
      padding-left: 3rem !important;
    }

    .ps-md-5 {
      padding-right: 3rem !important;
    }
  }

  @media (min-width: 992px) {
    .pe-lg-0 {
      padding-left: 0 !important;
    }

    .ps-lg-0 {
      padding-right: 0 !important;
    }

    .pe-lg-1 {
      padding-left: 0.25rem !important;
    }

    .ps-lg-1 {
      padding-right: 0.25rem !important;
    }

    .pe-lg-2 {
      padding-left: 0.5rem !important;
    }

    .ps-lg-2 {
      padding-right: 0.5rem !important;
    }

    .pe-lg-3 {
      padding-left: 1rem !important;
    }

    .ps-lg-3 {
      padding-right: 1rem !important;
    }

    .pe-lg-4 {
      padding-left: 1.5rem !important;
    }

    .ps-lg-4 {
      padding-right: 1.5rem !important;
    }

    .pe-lg-5 {
      padding-left: 3rem !important;
    }

    .ps-lg-5 {
      padding-right: 3rem !important;
    }
  }

  @media (min-width: 1200px) {
    .pe-xl-0 {
      padding-left: 0 !important;
    }

    .ps-xl-0 {
      padding-right: 0 !important;
    }

    .pe-xl-1 {
      padding-left: 0.25rem !important;
    }

    .ps-xl-1 {
      padding-right: 0.25rem !important;
    }

    .pe-xl-2 {
      padding-left: 0.5rem !important;
    }

    .ps-xl-2 {
      padding-right: 0.5rem !important;
    }

    .pe-xl-3 {
      padding-left: 1rem !important;
    }

    .ps-xl-3 {
      padding-right: 1rem !important;
    }

    .pe-xl-4 {
      padding-left: 1.5rem !important;
    }

    .ps-xl-4 {
      padding-right: 1.5rem !important;
    }

    .pe-xl-5 {
      padding-left: 3rem !important;
    }

    .ps-xl-5 {
      padding-right: 3rem !important;
    }
  }

  .pd-r-0 {
    padding-left: 0px;
  }

  .pd-l-0 {
    padding-right: 0px;
  }

  .pd-r-1 {
    padding-left: 1px;
  }

  .pd-l-1 {
    padding-right: 1px;
  }

  .pd-r-2 {
    padding-left: 2px;
  }

  .pd-l-2 {
    padding-right: 2px;
  }

  .pd-r-3 {
    padding-left: 3px;
  }

  .pd-l-3 {
    padding-right: 3px;
  }

  .pd-r-4 {
    padding-left: 4px;
  }

  .pd-l-4 {
    padding-right: 4px;
  }

  .pd-r-5 {
    padding-left: 5px;
  }

  .pd-l-5 {
    padding-right: 5px;
  }

  .pd-r-6 {
    padding-left: 6px;
  }

  .pd-l-6 {
    padding-right: 6px;
  }

  .pd-r-7 {
    padding-left: 7px;
  }

  .pd-l-7 {
    padding-right: 7px;
  }

  .pd-r-8 {
    padding-left: 8px;
  }

  .pd-l-8 {
    padding-right: 8px;
  }

  .pd-r-9 {
    padding-left: 9px;
  }

  .pd-l-9 {
    padding-right: 9px;
  }

  .pd-r-10 {
    padding-left: 10px;
  }

  .pd-l-10 {
    padding-right: 10px;
  }

  .pd-r-15 {
    padding-left: 15px;
  }

  .pd-l-15 {
    padding-right: 15px;
  }

  .pd-r-20 {
    padding-left: 20px;
  }

  .pd-l-20 {
    padding-right: 20px;
  }

  .pd-r-25 {
    padding-left: 25px;
  }

  .pd-l-25 {
    padding-right: 25px;
  }

  .pd-r-30 {
    padding-left: 30px;
  }

  .pd-l-30 {
    padding-right: 30px;
  }

  .pd-r-35 {
    padding-left: 35px;
  }

  .pd-l-35 {
    padding-right: 35px;
  }

  .pd-r-40 {
    padding-left: 40px;
  }

  .pd-l-40 {
    padding-right: 40px;
  }

  .pd-r-45 {
    padding-left: 45px;
  }

  .pd-l-45 {
    padding-right: 45px;
  }

  .pd-r-50 {
    padding-left: 50px;
  }

  .pd-l-50 {
    padding-right: 50px;
  }

  .pd-r-55 {
    padding-left: 55px;
  }

  .pd-l-55 {
    padding-right: 55px;
  }

  .pd-r-60 {
    padding-left: 60px;
  }

  .pd-l-60 {
    padding-right: 60px;
  }

  .pd-r-65 {
    padding-left: 65px;
  }

  .pd-l-65 {
    padding-right: 65px;
  }

  .pd-r-70 {
    padding-left: 70px;
  }

  .pd-l-70 {
    padding-right: 70px;
  }

  .pd-r-75 {
    padding-left: 75px;
  }

  .pd-l-75 {
    padding-right: 75px;
  }

  .pd-r-80 {
    padding-left: 80px;
  }

  .pd-l-80 {
    padding-right: 80px;
  }

  .pd-r-85 {
    padding-left: 85px;
  }

  .pd-l-85 {
    padding-right: 85px;
  }

  .pd-r-90 {
    padding-left: 90px;
  }

  .pd-l-90 {
    padding-right: 90px;
  }

  .pd-r-95 {
    padding-left: 95px;
  }

  .pd-l-95 {
    padding-right: 95px;
  }

  .pd-r-100 {
    padding-left: 100px;
  }

  .pd-l-100 {
    padding-right: 100px;
  }

  .pd-r-110 {
    padding-left: 110px;
  }

  .pd-l-110 {
    padding-right: 110px;
  }

  .pd-r-120 {
    padding-left: 120px;
  }

  .pd-l-120 {
    padding-right: 120px;
  }

  .pd-r-130 {
    padding-left: 130px;
  }

  .pd-l-130 {
    padding-right: 130px;
  }

  .pd-r-140 {
    padding-left: 140px;
  }

  .pd-l-140 {
    padding-right: 140px;
  }

  .pd-r-150 {
    padding-left: 150px;
  }

  .pd-l-150 {
    padding-right: 150px;
  }

  .pd-r-160 {
    padding-left: 160px;
  }

  .pd-l-160 {
    padding-right: 160px;
  }

  .pd-r-170 {
    padding-left: 170px;
  }

  .pd-l-170 {
    padding-right: 170px;
  }

  .pd-r-180 {
    padding-left: 180px;
  }

  .pd-l-180 {
    padding-right: 180px;
  }

  .pd-r-190 {
    padding-left: 190px;
  }

  .pd-l-190 {
    padding-right: 190px;
  }

  .pd-r-200 {
    padding-left: 200px;
  }

  .pd-l-200 {
    padding-right: 200px;
  }

  @media (min-width: 480px) {
    .pd-xs-r-0 {
      padding-left: 0px;
    }

    .pd-xs-l-0 {
      padding-right: 0px;
    }

    .pd-xs-r-1 {
      padding-left: 1px;
    }

    .pd-xs-l-1 {
      padding-right: 1px;
    }

    .pd-xs-r-2 {
      padding-left: 2px;
    }

    .pd-xs-l-2 {
      padding-right: 2px;
    }

    .pd-xs-r-3 {
      padding-left: 3px;
    }

    .pd-xs-l-3 {
      padding-right: 3px;
    }

    .pd-xs-r-4 {
      padding-left: 4px;
    }

    .pd-xs-l-4 {
      padding-right: 4px;
    }

    .pd-xs-r-5 {
      padding-left: 5px;
    }

    .pd-xs-l-5 {
      padding-right: 5px;
    }

    .pd-xs-r-6 {
      padding-left: 6px;
    }

    .pd-xs-l-6 {
      padding-right: 6px;
    }

    .pd-xs-r-7 {
      padding-left: 7px;
    }

    .pd-xs-l-7 {
      padding-right: 7px;
    }

    .pd-xs-r-8 {
      padding-left: 8px;
    }

    .pd-xs-l-8 {
      padding-right: 8px;
    }

    .pd-xs-r-9 {
      padding-left: 9px;
    }

    .pd-xs-l-9 {
      padding-right: 9px;
    }

    .pd-xs-r-10 {
      padding-left: 10px;
    }

    .pd-xs-l-10 {
      padding-right: 10px;
    }

    .pd-xs-r-15 {
      padding-left: 15px;
    }

    .pd-xs-l-15 {
      padding-right: 15px;
    }

    .pd-xs-r-20 {
      padding-left: 20px;
    }

    .pd-xs-l-20 {
      padding-right: 20px;
    }

    .pd-xs-r-25 {
      padding-left: 25px;
    }

    .pd-xs-l-25 {
      padding-right: 25px;
    }

    .pd-xs-r-30 {
      padding-left: 30px;
    }

    .pd-xs-l-30 {
      padding-right: 30px;
    }

    .pd-xs-r-35 {
      padding-left: 35px;
    }

    .pd-xs-l-35 {
      padding-right: 35px;
    }

    .pd-xs-r-40 {
      padding-left: 40px;
    }

    .pd-xs-l-40 {
      padding-right: 40px;
    }

    .pd-xs-r-45 {
      padding-left: 45px;
    }

    .pd-xs-l-45 {
      padding-right: 45px;
    }

    .pd-xs-r-50 {
      padding-left: 50px;
    }

    .pd-xs-l-50 {
      padding-right: 50px;
    }

    .pd-xs-r-55 {
      padding-left: 55px;
    }

    .pd-xs-l-55 {
      padding-right: 55px;
    }

    .pd-xs-r-60 {
      padding-left: 60px;
    }

    .pd-xs-l-60 {
      padding-right: 60px;
    }

    .pd-xs-r-65 {
      padding-left: 65px;
    }

    .pd-xs-l-65 {
      padding-right: 65px;
    }

    .pd-xs-r-70 {
      padding-left: 70px;
    }

    .pd-xs-l-70 {
      padding-right: 70px;
    }

    .pd-xs-r-75 {
      padding-left: 75px;
    }

    .pd-xs-l-75 {
      padding-right: 75px;
    }

    .pd-xs-r-80 {
      padding-left: 80px;
    }

    .pd-xs-l-80 {
      padding-right: 80px;
    }

    .pd-xs-r-85 {
      padding-left: 85px;
    }

    .pd-xs-l-85 {
      padding-right: 85px;
    }

    .pd-xs-r-90 {
      padding-left: 90px;
    }

    .pd-xs-l-90 {
      padding-right: 90px;
    }

    .pd-xs-r-95 {
      padding-left: 95px;
    }

    .pd-xs-l-95 {
      padding-right: 95px;
    }

    .pd-xs-r-100 {
      padding-left: 100px;
    }

    .pd-xs-l-100 {
      padding-right: 100px;
    }

    .pd-xs-r-110 {
      padding-left: 110px;
    }

    .pd-xs-l-110 {
      padding-right: 110px;
    }

    .pd-xs-r-120 {
      padding-left: 120px;
    }

    .pd-xs-l-120 {
      padding-right: 120px;
    }

    .pd-xs-r-130 {
      padding-left: 130px;
    }

    .pd-xs-l-130 {
      padding-right: 130px;
    }

    .pd-xs-r-140 {
      padding-left: 140px;
    }

    .pd-xs-l-140 {
      padding-right: 140px;
    }

    .pd-xs-r-150 {
      padding-left: 150px;
    }

    .pd-xs-l-150 {
      padding-right: 150px;
    }

    .pd-xs-r-160 {
      padding-left: 160px;
    }

    .pd-xs-l-160 {
      padding-right: 160px;
    }

    .pd-xs-r-170 {
      padding-left: 170px;
    }

    .pd-xs-l-170 {
      padding-right: 170px;
    }

    .pd-xs-r-180 {
      padding-left: 180px;
    }

    .pd-xs-l-180 {
      padding-right: 180px;
    }

    .pd-xs-r-190 {
      padding-left: 190px;
    }

    .pd-xs-l-190 {
      padding-right: 190px;
    }

    .pd-xs-r-200 {
      padding-left: 200px;
    }

    .pd-xs-l-200 {
      padding-right: 200px;
    }
  }

  @media (min-width: 576px) {
    .pd-sm-r-0 {
      padding-left: 0px;
    }

    .pd-sm-l-0 {
      padding-right: 0px;
    }

    .pd-sm-r-1 {
      padding-left: 1px;
    }

    .pd-sm-l-1 {
      padding-right: 1px;
    }

    .pd-sm-r-2 {
      padding-left: 2px;
    }

    .pd-sm-l-2 {
      padding-right: 2px;
    }

    .pd-sm-r-3 {
      padding-left: 3px;
    }

    .pd-sm-l-3 {
      padding-right: 3px;
    }

    .pd-sm-r-4 {
      padding-left: 4px;
    }

    .pd-sm-l-4 {
      padding-right: 4px;
    }

    .pd-sm-r-5 {
      padding-left: 5px;
    }

    .pd-sm-l-5 {
      padding-right: 5px;
    }

    .pd-sm-r-6 {
      padding-left: 6px;
    }

    .pd-sm-l-6 {
      padding-right: 6px;
    }

    .pd-sm-r-7 {
      padding-left: 7px;
    }

    .pd-sm-l-7 {
      padding-right: 7px;
    }

    .pd-sm-r-8 {
      padding-left: 8px;
    }

    .pd-sm-l-8 {
      padding-right: 8px;
    }

    .pd-sm-r-9 {
      padding-left: 9px;
    }

    .pd-sm-l-9 {
      padding-right: 9px;
    }

    .pd-sm-r-10 {
      padding-left: 10px;
    }

    .pd-sm-l-10 {
      padding-right: 10px;
    }

    .pd-sm-r-15 {
      padding-left: 15px;
    }

    .pd-sm-l-15 {
      padding-right: 15px;
    }

    .pd-sm-r-20 {
      padding-left: 20px;
    }

    .pd-sm-l-20 {
      padding-right: 20px;
    }

    .pd-sm-r-25 {
      padding-left: 25px;
    }

    .pd-sm-l-25 {
      padding-right: 25px;
    }

    .pd-sm-r-30 {
      padding-left: 30px;
    }

    .pd-sm-l-30 {
      padding-right: 30px;
    }

    .pd-sm-r-35 {
      padding-left: 35px;
    }

    .pd-sm-l-35 {
      padding-right: 35px;
    }

    .pd-sm-r-40 {
      padding-left: 40px;
    }

    .pd-sm-l-40 {
      padding-right: 40px;
    }

    .pd-sm-r-45 {
      padding-left: 45px;
    }

    .pd-sm-l-45 {
      padding-right: 45px;
    }

    .pd-sm-r-50 {
      padding-left: 50px;
    }

    .pd-sm-l-50 {
      padding-right: 50px;
    }

    .pd-sm-r-55 {
      padding-left: 55px;
    }

    .pd-sm-l-55 {
      padding-right: 55px;
    }

    .pd-sm-r-60 {
      padding-left: 60px;
    }

    .pd-sm-l-60 {
      padding-right: 60px;
    }

    .pd-sm-r-65 {
      padding-left: 65px;
    }

    .pd-sm-l-65 {
      padding-right: 65px;
    }

    .pd-sm-r-70 {
      padding-left: 70px;
    }

    .pd-sm-l-70 {
      padding-right: 70px;
    }

    .pd-sm-r-75 {
      padding-left: 75px;
    }

    .pd-sm-l-75 {
      padding-right: 75px;
    }

    .pd-sm-r-80 {
      padding-left: 80px;
    }

    .pd-sm-l-80 {
      padding-right: 80px;
    }

    .pd-sm-r-85 {
      padding-left: 85px;
    }

    .pd-sm-l-85 {
      padding-right: 85px;
    }

    .pd-sm-r-90 {
      padding-left: 90px;
    }

    .pd-sm-l-90 {
      padding-right: 90px;
    }

    .pd-sm-r-95 {
      padding-left: 95px;
    }

    .pd-sm-l-95 {
      padding-right: 95px;
    }

    .pd-sm-r-100 {
      padding-left: 100px;
    }

    .pd-sm-l-100 {
      padding-right: 100px;
    }

    .pd-sm-r-110 {
      padding-left: 110px;
    }

    .pd-sm-l-110 {
      padding-right: 110px;
    }

    .pd-sm-r-120 {
      padding-left: 120px;
    }

    .pd-sm-l-120 {
      padding-right: 120px;
    }

    .pd-sm-r-130 {
      padding-left: 130px;
    }

    .pd-sm-l-130 {
      padding-right: 130px;
    }

    .pd-sm-r-140 {
      padding-left: 140px;
    }

    .pd-sm-l-140 {
      padding-right: 140px;
    }

    .pd-sm-r-150 {
      padding-left: 150px;
    }

    .pd-sm-l-150 {
      padding-right: 150px;
    }

    .pd-sm-r-160 {
      padding-left: 160px;
    }

    .pd-sm-l-160 {
      padding-right: 160px;
    }

    .pd-sm-r-170 {
      padding-left: 170px;
    }

    .pd-sm-l-170 {
      padding-right: 170px;
    }

    .pd-sm-r-180 {
      padding-left: 180px;
    }

    .pd-sm-l-180 {
      padding-right: 180px;
    }

    .pd-sm-r-190 {
      padding-left: 190px;
    }

    .pd-sm-l-190 {
      padding-right: 190px;
    }

    .pd-sm-r-200 {
      padding-left: 200px;
    }

    .pd-sm-l-200 {
      padding-right: 200px;
    }
  }

  @media (min-width: 992px) {
    .pd-md-r-0 {
      padding-left: 0px;
    }

    .pd-md-l-0 {
      padding-right: 0px;
    }

    .pd-md-r-1 {
      padding-left: 1px;
    }

    .pd-md-l-1 {
      padding-right: 1px;
    }

    .pd-md-r-2 {
      padding-left: 2px;
    }

    .pd-md-l-2 {
      padding-right: 2px;
    }

    .pd-md-r-3 {
      padding-left: 3px;
    }

    .pd-md-l-3 {
      padding-right: 3px;
    }

    .pd-md-r-4 {
      padding-left: 4px;
    }

    .pd-md-l-4 {
      padding-right: 4px;
    }

    .pd-md-r-5 {
      padding-left: 5px;
    }

    .pd-md-l-5 {
      padding-right: 5px;
    }

    .pd-md-r-6 {
      padding-left: 6px;
    }

    .pd-md-l-6 {
      padding-right: 6px;
    }

    .pd-md-r-7 {
      padding-left: 7px;
    }

    .pd-md-l-7 {
      padding-right: 7px;
    }

    .pd-md-r-8 {
      padding-left: 8px;
    }

    .pd-md-l-8 {
      padding-right: 8px;
    }

    .pd-md-r-9 {
      padding-left: 9px;
    }

    .pd-md-l-9 {
      padding-right: 9px;
    }

    .pd-md-r-10 {
      padding-left: 10px;
    }

    .pd-md-l-10 {
      padding-right: 10px;
    }

    .pd-md-r-15 {
      padding-left: 15px;
    }

    .pd-md-l-15 {
      padding-right: 15px;
    }

    .pd-md-r-20 {
      padding-left: 20px;
    }

    .pd-md-l-20 {
      padding-right: 20px;
    }

    .pd-md-r-25 {
      padding-left: 25px;
    }

    .pd-md-l-25 {
      padding-right: 25px;
    }

    .pd-md-r-30 {
      padding-left: 30px;
    }

    .pd-md-l-30 {
      padding-right: 30px;
    }

    .pd-md-r-35 {
      padding-left: 35px;
    }

    .pd-md-l-35 {
      padding-right: 35px;
    }

    .pd-md-r-40 {
      padding-left: 40px;
    }

    .pd-md-l-40 {
      padding-right: 40px;
    }

    .pd-md-r-45 {
      padding-left: 45px;
    }

    .pd-md-l-45 {
      padding-right: 45px;
    }

    .pd-md-r-50 {
      padding-left: 50px;
    }

    .pd-md-l-50 {
      padding-right: 50px;
    }

    .pd-md-r-55 {
      padding-left: 55px;
    }

    .pd-md-l-55 {
      padding-right: 55px;
    }

    .pd-md-r-60 {
      padding-left: 60px;
    }

    .pd-md-l-60 {
      padding-right: 60px;
    }

    .pd-md-r-65 {
      padding-left: 65px;
    }

    .pd-md-l-65 {
      padding-right: 65px;
    }

    .pd-md-r-70 {
      padding-left: 70px;
    }

    .pd-md-l-70 {
      padding-right: 70px;
    }

    .pd-md-r-75 {
      padding-left: 75px;
    }

    .pd-md-l-75 {
      padding-right: 75px;
    }

    .pd-md-r-80 {
      padding-left: 80px;
    }

    .pd-md-l-80 {
      padding-right: 80px;
    }

    .pd-md-r-85 {
      padding-left: 85px;
    }

    .pd-md-l-85 {
      padding-right: 85px;
    }

    .pd-md-r-90 {
      padding-left: 90px;
    }

    .pd-md-l-90 {
      padding-right: 90px;
    }

    .pd-md-r-95 {
      padding-left: 95px;
    }

    .pd-md-l-95 {
      padding-right: 95px;
    }

    .pd-md-r-100 {
      padding-left: 100px;
    }

    .pd-md-l-100 {
      padding-right: 100px;
    }

    .pd-md-r-110 {
      padding-left: 110px;
    }

    .pd-md-l-110 {
      padding-right: 110px;
    }

    .pd-md-r-120 {
      padding-left: 120px;
    }

    .pd-md-l-120 {
      padding-right: 120px;
    }

    .pd-md-r-130 {
      padding-left: 130px;
    }

    .pd-md-l-130 {
      padding-right: 130px;
    }

    .pd-md-r-140 {
      padding-left: 140px;
    }

    .pd-md-l-140 {
      padding-right: 140px;
    }

    .pd-md-r-150 {
      padding-left: 150px;
    }

    .pd-md-l-150 {
      padding-right: 150px;
    }

    .pd-md-r-160 {
      padding-left: 160px;
    }

    .pd-md-l-160 {
      padding-right: 160px;
    }

    .pd-md-r-170 {
      padding-left: 170px;
    }

    .pd-md-l-170 {
      padding-right: 170px;
    }

    .pd-md-r-180 {
      padding-left: 180px;
    }

    .pd-md-l-180 {
      padding-right: 180px;
    }

    .pd-md-r-190 {
      padding-left: 190px;
    }

    .pd-md-l-190 {
      padding-right: 190px;
    }

    .pd-md-r-200 {
      padding-left: 200px;
    }

    .pd-md-l-200 {
      padding-right: 200px;
    }
  }

  @media (min-width: 992px) {
    .pd-lg-r-0 {
      padding-left: 0px;
    }

    .pd-lg-l-0 {
      padding-right: 0px;
    }

    .pd-lg-r-1 {
      padding-left: 1px;
    }

    .pd-lg-l-1 {
      padding-right: 1px;
    }

    .pd-lg-r-2 {
      padding-left: 2px;
    }

    .pd-lg-l-2 {
      padding-right: 2px;
    }

    .pd-lg-r-3 {
      padding-left: 3px;
    }

    .pd-lg-l-3 {
      padding-right: 3px;
    }

    .pd-lg-r-4 {
      padding-left: 4px;
    }

    .pd-lg-l-4 {
      padding-right: 4px;
    }

    .pd-lg-r-5 {
      padding-left: 5px;
    }

    .pd-lg-l-5 {
      padding-right: 5px;
    }

    .pd-lg-r-6 {
      padding-left: 6px;
    }

    .pd-lg-l-6 {
      padding-right: 6px;
    }

    .pd-lg-r-7 {
      padding-left: 7px;
    }

    .pd-lg-l-7 {
      padding-right: 7px;
    }

    .pd-lg-r-8 {
      padding-left: 8px;
    }

    .pd-lg-l-8 {
      padding-right: 8px;
    }

    .pd-lg-r-9 {
      padding-left: 9px;
    }

    .pd-lg-l-9 {
      padding-right: 9px;
    }

    .pd-lg-r-10 {
      padding-left: 10px;
    }

    .pd-lg-l-10 {
      padding-right: 10px;
    }

    .pd-lg-r-15 {
      padding-left: 15px;
    }

    .pd-lg-l-15 {
      padding-right: 15px;
    }

    .pd-lg-r-20 {
      padding-left: 20px;
    }

    .pd-lg-l-20 {
      padding-right: 20px;
    }

    .pd-lg-r-25 {
      padding-left: 25px;
    }

    .pd-lg-l-25 {
      padding-right: 25px;
    }

    .pd-lg-r-30 {
      padding-left: 30px;
    }

    .pd-lg-l-30 {
      padding-right: 30px;
    }

    .pd-lg-r-35 {
      padding-left: 35px;
    }

    .pd-lg-l-35 {
      padding-right: 35px;
    }

    .pd-lg-r-40 {
      padding-left: 40px;
    }

    .pd-lg-l-40 {
      padding-right: 40px;
    }

    .pd-lg-r-45 {
      padding-left: 45px;
    }

    .pd-lg-l-45 {
      padding-right: 45px;
    }

    .pd-lg-r-50 {
      padding-left: 50px;
    }

    .pd-lg-l-50 {
      padding-right: 50px;
    }

    .pd-lg-r-55 {
      padding-left: 55px;
    }

    .pd-lg-l-55 {
      padding-right: 55px;
    }

    .pd-lg-r-60 {
      padding-left: 60px;
    }

    .pd-lg-l-60 {
      padding-right: 60px;
    }

    .pd-lg-r-65 {
      padding-left: 65px;
    }

    .pd-lg-l-65 {
      padding-right: 65px;
    }

    .pd-lg-r-70 {
      padding-left: 70px;
    }

    .pd-lg-l-70 {
      padding-right: 70px;
    }

    .pd-lg-r-75 {
      padding-left: 75px;
    }

    .pd-lg-l-75 {
      padding-right: 75px;
    }

    .pd-lg-r-80 {
      padding-left: 80px;
    }

    .pd-lg-l-80 {
      padding-right: 80px;
    }

    .pd-lg-r-85 {
      padding-left: 85px;
    }

    .pd-lg-l-85 {
      padding-right: 85px;
    }

    .pd-lg-r-90 {
      padding-left: 90px;
    }

    .pd-lg-l-90 {
      padding-right: 90px;
    }

    .pd-lg-r-95 {
      padding-left: 95px;
    }

    .pd-lg-l-95 {
      padding-right: 95px;
    }

    .pd-lg-r-100 {
      padding-left: 100px;
    }

    .pd-lg-l-100 {
      padding-right: 100px;
    }

    .pd-lg-r-110 {
      padding-left: 110px;
    }

    .pd-lg-l-110 {
      padding-right: 110px;
    }

    .pd-lg-r-120 {
      padding-left: 120px;
    }

    .pd-lg-l-120 {
      padding-right: 120px;
    }

    .pd-lg-r-130 {
      padding-left: 130px;
    }

    .pd-lg-l-130 {
      padding-right: 130px;
    }

    .pd-lg-r-140 {
      padding-left: 140px;
    }

    .pd-lg-l-140 {
      padding-right: 140px;
    }

    .pd-lg-r-150 {
      padding-left: 150px;
    }

    .pd-lg-l-150 {
      padding-right: 150px;
    }

    .pd-lg-r-160 {
      padding-left: 160px;
    }

    .pd-lg-l-160 {
      padding-right: 160px;
    }

    .pd-lg-r-170 {
      padding-left: 170px;
    }

    .pd-lg-l-170 {
      padding-right: 170px;
    }

    .pd-lg-r-180 {
      padding-left: 180px;
    }

    .pd-lg-l-180 {
      padding-right: 180px;
    }

    .pd-lg-r-190 {
      padding-left: 190px;
    }

    .pd-lg-l-190 {
      padding-right: 190px;
    }

    .pd-lg-r-200 {
      padding-left: 200px;
    }

    .pd-lg-l-200 {
      padding-right: 200px;
    }
  }

  @media (min-width: 1200px) {
    .pd-xl-r-0 {
      padding-left: 0px;
    }

    .pd-xl-l-0 {
      padding-right: 0px;
    }

    .pd-xl-r-1 {
      padding-left: 1px;
    }

    .pd-xl-l-1 {
      padding-right: 1px;
    }

    .pd-xl-r-2 {
      padding-left: 2px;
    }

    .pd-xl-l-2 {
      padding-right: 2px;
    }

    .pd-xl-r-3 {
      padding-left: 3px;
    }

    .pd-xl-l-3 {
      padding-right: 3px;
    }

    .pd-xl-r-4 {
      padding-left: 4px;
    }

    .pd-xl-l-4 {
      padding-right: 4px;
    }

    .pd-xl-r-5 {
      padding-left: 5px;
    }

    .pd-xl-l-5 {
      padding-right: 5px;
    }

    .pd-xl-r-6 {
      padding-left: 6px;
    }

    .pd-xl-l-6 {
      padding-right: 6px;
    }

    .pd-xl-r-7 {
      padding-left: 7px;
    }

    .pd-xl-l-7 {
      padding-right: 7px;
    }

    .pd-xl-r-8 {
      padding-left: 8px;
    }

    .pd-xl-l-8 {
      padding-right: 8px;
    }

    .pd-xl-r-9 {
      padding-left: 9px;
    }

    .pd-xl-l-9 {
      padding-right: 9px;
    }

    .pd-xl-r-10 {
      padding-left: 10px;
    }

    .pd-xl-l-10 {
      padding-right: 10px;
    }

    .pd-xl-r-15 {
      padding-left: 15px;
    }

    .pd-xl-l-15 {
      padding-right: 15px;
    }

    .pd-xl-r-20 {
      padding-left: 20px;
    }

    .pd-xl-l-20 {
      padding-right: 20px;
    }

    .pd-xl-r-25 {
      padding-left: 25px;
    }

    .pd-xl-l-25 {
      padding-right: 25px;
    }

    .pd-xl-r-30 {
      padding-left: 30px;
    }

    .pd-xl-l-30 {
      padding-right: 30px;
    }

    .pd-xl-r-35 {
      padding-left: 35px;
    }

    .pd-xl-l-35 {
      padding-right: 35px;
    }

    .pd-xl-r-40 {
      padding-left: 40px;
    }

    .pd-xl-l-40 {
      padding-right: 40px;
    }

    .pd-xl-r-45 {
      padding-left: 45px;
    }

    .pd-xl-l-45 {
      padding-right: 45px;
    }

    .pd-xl-r-50 {
      padding-left: 50px;
    }

    .pd-xl-l-50 {
      padding-right: 50px;
    }

    .pd-xl-r-55 {
      padding-left: 55px;
    }

    .pd-xl-l-55 {
      padding-right: 55px;
    }

    .pd-xl-r-60 {
      padding-left: 60px;
    }

    .pd-xl-l-60 {
      padding-right: 60px;
    }

    .pd-xl-r-65 {
      padding-left: 65px;
    }

    .pd-xl-l-65 {
      padding-right: 65px;
    }

    .pd-xl-r-70 {
      padding-left: 70px;
    }

    .pd-xl-l-70 {
      padding-right: 70px;
    }

    .pd-xl-r-75 {
      padding-left: 75px;
    }

    .pd-xl-l-75 {
      padding-right: 75px;
    }

    .pd-xl-r-80 {
      padding-left: 80px;
    }

    .pd-xl-l-80 {
      padding-right: 80px;
    }

    .pd-xl-r-85 {
      padding-left: 85px;
    }

    .pd-xl-l-85 {
      padding-right: 85px;
    }

    .pd-xl-r-90 {
      padding-left: 90px;
    }

    .pd-xl-l-90 {
      padding-right: 90px;
    }

    .pd-xl-r-95 {
      padding-left: 95px;
    }

    .pd-xl-l-95 {
      padding-right: 95px;
    }

    .pd-xl-r-100 {
      padding-left: 100px;
    }

    .pd-xl-l-100 {
      padding-right: 100px;
    }

    .pd-xl-r-110 {
      padding-left: 110px;
    }

    .pd-xl-l-110 {
      padding-right: 110px;
    }

    .pd-xl-r-120 {
      padding-left: 120px;
    }

    .pd-xl-l-120 {
      padding-right: 120px;
    }

    .pd-xl-r-130 {
      padding-left: 130px;
    }

    .pd-xl-l-130 {
      padding-right: 130px;
    }

    .pd-xl-r-140 {
      padding-left: 140px;
    }

    .pd-xl-l-140 {
      padding-right: 140px;
    }

    .pd-xl-r-150 {
      padding-left: 150px;
    }

    .pd-xl-l-150 {
      padding-right: 150px;
    }

    .pd-xl-r-160 {
      padding-left: 160px;
    }

    .pd-xl-l-160 {
      padding-right: 160px;
    }

    .pd-xl-r-170 {
      padding-left: 170px;
    }

    .pd-xl-l-170 {
      padding-right: 170px;
    }

    .pd-xl-r-180 {
      padding-left: 180px;
    }

    .pd-xl-l-180 {
      padding-right: 180px;
    }

    .pd-xl-r-190 {
      padding-left: 190px;
    }

    .pd-xl-l-190 {
      padding-right: 190px;
    }

    .pd-xl-r-200 {
      padding-left: 200px;
    }

    .pd-xl-l-200 {
      padding-right: 200px;
    }
  }

  .pd-r-12 {
    padding-left: 12px;
  }

  .pd-l-12 {
    padding-right: 12px;
  }

  .pd-r-13 {
    padding-left: 13px;
  }

  .pd-l-13 {
    padding-right: 13px;
  }

  .r-0 {
    left: 0px;
  }

  .l-0 {
    right: 0px;
  }

  .r--0 {
    left: -0px;
  }

  .l--0 {
    right: -0px;
  }

  .a-0 {
    left: 0px;
    right: 0px;
  }

  .r-5 {
    left: 5px;
  }

  .l-5 {
    right: 5px;
  }

  .r--5 {
    left: -5px;
  }

  .l--5 {
    right: -5px;
  }

  .r-10 {
    left: 10px;
  }

  .l-10 {
    right: 10px;
  }

  .r--10 {
    left: -10px;
  }

  .l--10 {
    right: -10px;
  }

  .r-15 {
    left: 15px;
  }

  .l-15 {
    right: 15px;
  }

  .r--15 {
    left: -15px;
  }

  .l--15 {
    right: -15px;
  }

  .r-20 {
    left: 20px;
    right: inherit;
  }

  .l-20 {
    right: 20px;
  }

  .r--20 {
    left: -20px;
  }

  .l--20 {
    right: -20px;
  }

  .r-25 {
    left: 25px;
  }

  .l-25 {
    right: 25px;
  }

  .r--25 {
    left: -25px;
  }

  .l--25 {
    right: -25px;
  }

  .r-30 {
    left: 30px;
  }

  .l-30 {
    right: 30px;
  }

  .r--30 {
    left: -30px;
  }

  .l--30 {
    right: -30px;
  }

  .r-35 {
    left: 35px;
  }

  .l-35 {
    right: 35px;
  }

  .r--35 {
    left: -35px;
  }

  .l--35 {
    right: -35px;
  }

  .r-40 {
    left: 40px;
  }

  .l-40 {
    right: 40px;
  }

  .r--40 {
    left: -40px;
  }

  .l--40 {
    right: -40px;
  }

  .r-45 {
    left: 45px;
  }

  .l-45 {
    right: 45px;
  }

  .r--45 {
    left: -45px;
  }

  .l--45 {
    right: -45px;
  }

  .r-50 {
    left: 50px;
  }

  .l-50 {
    right: 50px;
  }

  .r--50 {
    left: -50px;
  }

  .l--50 {
    right: -50px;
  }

  .r-55 {
    left: 55px;
  }

  .l-55 {
    right: 55px;
  }

  .r--55 {
    left: -55px;
  }

  .l--55 {
    right: -55px;
  }

  .r-60 {
    left: 60px;
  }

  .l-60 {
    right: 60px;
  }

  .r--60 {
    left: -60px;
  }

  .l--60 {
    right: -60px;
  }

  .r-65 {
    left: 65px;
  }

  .l-65 {
    right: 65px;
  }

  .r--65 {
    left: -65px;
  }

  .l--65 {
    right: -65px;
  }

  .r-70 {
    left: 70px;
  }

  .l-70 {
    right: 70px;
  }

  .r--70 {
    left: -70px;
  }

  .l--70 {
    right: -70px;
  }

  .r-75 {
    left: 75px;
  }

  .l-75 {
    right: 75px;
  }

  .r--75 {
    left: -75px;
  }

  .l--75 {
    right: -75px;
  }

  .r-80 {
    left: 80px;
  }

  .l-80 {
    right: 80px;
  }

  .r--80 {
    left: -80px;
  }

  .l--80 {
    right: -80px;
  }

  .r-85 {
    left: 85px;
  }

  .l-85 {
    right: 85px;
  }

  .r--85 {
    left: -85px;
  }

  .l--85 {
    right: -85px;
  }

  .r-90 {
    left: 90px;
  }

  .l-90 {
    right: 90px;
  }

  .r--90 {
    left: -90px;
  }

  .l--90 {
    right: -90px;
  }

  .r-95 {
    left: 95px;
  }

  .l-95 {
    right: 95px;
  }

  .r--95 {
    left: -95px;
  }

  .l--95 {
    right: -95px;
  }

  .r-100 {
    left: 100px;
  }

  .l-100 {
    right: 100px;
  }

  .r--100 {
    left: -100px;
  }

  .l--100 {
    right: -100px;
  }

  .r--5 {
    left: -5px;
  }

  @media (min-width: 480px) {
    .r-xs-auto {
      left: auto;
    }

    .l-xs-auto {
      right: auto;
    }
  }

  @media (min-width: 576px) {
    .r-sm-auto {
      left: auto;
    }

    .l-sm-auto {
      right: auto;
    }
  }

  @media (min-width: 992px) {
    .r-md-auto {
      left: auto;
    }

    .l-md-auto {
      right: auto;
    }
  }

  @media (min-width: 992px) {
    .r-lg-auto {
      left: auto;
    }

    .l-lg-auto {
      right: auto;
    }
  }

  @media (min-width: 1200px) {
    .r-xl-auto {
      left: auto;
    }

    .l-xl-auto {
      right: auto;
    }
  }

  .tx-right {
    text-align: left;
  }

  .tx-left {
    text-align: right;
  }

  @media (min-width: 480px) {
    .tx-xs-right {
      text-align: left;
    }

    .tx-xs-left {
      text-align: right;
    }
  }

  @media (min-width: 576px) {
    .tx-sm-right {
      text-align: left;
    }

    .tx-sm-left {
      text-align: right;
    }
  }

  @media (min-width: 992px) {
    .tx-md-right {
      text-align: left;
    }

    .tx-md-left {
      text-align: right;
    }
  }

  @media (min-width: 992px) {
    .tx-lg-right {
      text-align: left;
    }

    .tx-lg-left {
      text-align: right;
    }
  }

  @media (min-width: 1200px) {
    .tx-xl-right {
      text-align: left;
    }

    .tx-xl-left {
      text-align: right;
    }
  }

  .text-start {
    text-align: right !important;
  }

  .text-end {
    text-align: left !important;
  }

  @media (min-width: 576px) {
    .text-sm-left {
      text-align: left !important;
    }

    .text-sm-right {
      text-align: right !important;
    }
  }

  @media (min-width: 992px) {
    .text-md-left {
      text-align: left !important;
    }

    .text-md-right {
      text-align: right !important;
    }
  }

  @media (min-width: 992px) {
    .text-lg-left {
      text-align: left !important;
    }

    .text-lg-right {
      text-align: right !important;
    }
  }

  @media (min-width: 1200px) {
    .text-xl-left {
      text-align: left !important;
    }

    .text-xl-right {
      text-align: right !important;
    }
  }

  .app-sidebar .mCSB_draggerContainer {
    left: -11px !important;
  }

  @media (min-width: 992px) {
    .app.sidenav-toggled .app-content {
      margin-right: 80px;
    }

    .app.sidenav-toggled .app-sidebar {
      right: 0;
    }
  }

  @media (max-width: 991px) {
    .app .app-sidebar {
      right: -240px;
    }

    .app.sidenav-toggled .app-content {
      margin-right: 0;
    }

    .app.sidenav-toggled .app-sidebar {
      right: 0;
    }

    .app.sidebar-gone.sidenav-toggled .app-sidebar {
      right: 0;
    }

    .app.sidenav-toggled .side-menu .side-menu__icon {
      margin-left: 13px !important;
      margin-right: 0;
    }
  }

  @media (min-width: 992px) {
    .app-content {
      margin-right: 240px;
      margin-left: inherit;
    }
  }

  .app-header {
    padding-left: 15px;
  }

  @media (min-width: 992px) {
    .app-header {
      padding-left: 30px;
    }
  }

  .app-search {
    margin-left: 15px;
  }

  .app-search__input {
    padding-left: 30px;
  }

  .app-search__button {
    left: 0;
  }

  .app-notification__icon {
    padding-left: 10px;
  }

  .app-sidebar {
    right: 0;
    left: inherit !important;
    border-left: 1px solid #ededf5;
    border-right: 0px;
    transition: right 320ms ease, width 320ms ease;
  }

  &.dark-theme {
    // .card-pay .tabs-menu.nav li {
    //   border-left: 1px solid #404353;
    // }

    .app-sidebar {
      border-left: 1px solid #404353;
      border-right: inherit;
    }

    .main-sidebar-header {
      border-left: 1px solid #404353;
    }

    @media (min-width: 992px) {
      .main-header-left .btn {
        border-left: 1px solid transparent !important;
      }
    }
  }

  .app-sidebar__user-avatar {
    margin-left: 15px;
  }

  .side-menu .slide .side-menu__item {
    padding: 10px 30px 10px 91px;
  }

  .side-menu .slide .side-menu__item.active {
    border-left: 3px solid $primary;
    border-right: inherit;
  }

  @media (min-width: 992px) {
    .app.sidebar-mini.sidenav-toggled .profile-status {
      left: 12px;
    }

    .app.sidenav-toggled .side-menu__label {
      right: 0;
    }
  }

  .sub-slide .sub-angle {
    margin-right: auto;
    float: left;
  }

  .slide.is-expanded .sub-side-menu__item:before {
    right: 15px;
  }

  .slide.is-expanded .sub-slide-item2:before {
    right: 35px !important;
  }

  .side-menu .side-menu__icon {
    margin-left: 10px;
    margin-right: inherit;
  }

  .app-sidebar .slide .side-menu__item.active::before {
    right: 0;
  }

  .app-sidebar .side-item.side-item-category {
    padding: 0 25px 0 20px;
    text-align: right;
  }

  .slide-menu {
    padding-right: 29px;
    padding-left: inherit;
  }

  .slide-item {
    padding: 8px 35px 8px 43px;
  }

  .slide-item .icon {
    margin-left: 5px;
  }

  @media (min-width: 992px) {
    .sidenav-toggled .profile-img {
      left: 19px;
    }

    .sidenav-toggled .app-content {
      margin-right: 0;
    }

    .sidenav-toggled .app-sidebar {
      right: 0;
    }

    .sidenav-toggled .side-menu__label {
      right: 50px;
      padding: 12px 20px 12px 5px;
      margin-right: -3px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .sidenav-toggled .slide-menu {
      right: 90px;
    }

    .app.sidenav-toggled .side-menu__item {
      border-right: 0;
    }

    .app.sidenav-toggled .side-menu__label {
      right: 0;
    }

    .app.sidenav-toggled .nav-badge {
      left: 28px;
    }
  }

  .dropdown-menu.dropdown-menu-end {
    right: auto;
  }

  .app-breadcrumb {
    text-align: left;
  }

  .sidenav-toggled .main-wrapper .side-menu .side-menu__item .nav-badge {
    right: 57px;
  }

  .side-header .header-brand-img.desktop-logo {
    margin-left: 0;
  }

  .sidenav-toggled .app-sidebar .side-header .header-brand-img.toggle-logo {
    margin-left: 0;
  }

  .sidebar-navs .nav li:last-child a {
    margin-left: 0 !important;
  }

  .side-menu h3:after {
    right: 21px;
  }

  .sub-slide-item {
    padding: 8px 25px 8px 0px !important;
  }

  .sub-slide-menu {
    padding-right: 0;
  }

  .sub-side-menu__item {
    padding-right: 40px !important;
    padding-left: 20px !important;
  }

  .sub-slide.is-expanded .sub-slide-item {
    padding-right: 42px !important;
  }

  .sub-slide-menu .sub-slide-item2 {
    padding: 6px 48px 6px 0px !important;
  }

  .sub-slide .sub-angle2 {
    margin-right: 68px;
  }

  .app-sidebar .slide-menu .sub-slide-menu a:before {
    right: 25px;
  }

  .app-sidebar .slide-menu .slide-item:before {
    right: 20px;
  }

  @media (min-width: 992px) {
    .app.sidebar-mini.sidenav-toggled-open .app-sidebar {
      right: 0;
    }

    .app.sidebar-mini.sidenav-toggled-open .side-menu .slide .side-menu__item {
      padding: 10px 22px 10px 20px;
      text-align: right;
    }

    .app.sidebar-mini.sidenav-toggled-open .side-menu .side-menu__icon {
      margin-left: 12px;
    }

    .app.sidebar-mini.sidenav-toggled-open .ps>.ps__rail-y>.ps__thumb-y {
      right: 3px;
    }

    .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .profile-status {
      left: 19px;
    }

    .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
      right: 0;
    }
  }

  @media (min-width: 1200px) {
    .main-sidebar-sticky+.main-content {
      margin-right: 240px;
    }
  }

  .sub-slide .sub-angle2 {
    margin-right: auto;
    float: left;
  }

  .side-menu .side-menu__icon:before {
    left: 3px;
  }

  .sidebar-mini.sidenav-toggled .side-menu .slide .side-menu__item.active {
    border-left: 0 !important;
  }

  .sidebar-mini .side-menu {
    margin-right: 0 !important;
  }

  @media (min-width: 992px) {
    .icontext-menu.app.sidenav-toggled .main-header {
      padding-right: 110px !important;
    }

    .icontext-menu.app.sidenav-toggled .app-content {
      margin-right: 110px;
    }
  }

  @media (min-width: 992px) {
    .closed-menu.app.sidenav-toggled .app-content {
      margin-right: 0px !important;
    }

    .closed-menu.app.sidenav-toggled .main-header {
      padding-right: 0px !important;
    }
  }

  @media (min-width: 992px) {
    .hover-submenu .slide-item {
      padding: 8px 35px 8px 18px;
    }

    .hover-submenu .slide-menu {
      border-radius: 0px 0px 5px 5px;
    }

    .hover-submenu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
      right: 108px;
    }

    .hover-submenu.app.sidenav-toggled-open .slide-menu {
      padding-right: 0 !important;
      right: 110px;
    }

    .hover-submenu.app.sidenav-toggled-open .side-menu__label {
      right: 0;
    }

    .hover-submenu.app.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
      border-left: 0 !important;
    }

    .hover-submenu.app.sidenav-toggled .main-header {
      padding-right: 110px;
    }

    .hover-submenu.app.sidenav-toggled .app-content {
      margin-right: 110px;
    }

    .hover-submenu.app.sidenav-toggled .app-sidebar {
      right: 0;
    }
  }

  @media (min-width: 992px) {
    .hover-submenu1 .slide-item {
      padding: 8px 35px 8px 18px;
    }

    .hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
      right: 108px;
    }

    .hover-submenu1.app.sidenav-toggled-open .slide-menu {
      padding-right: 0 !important;
      right: 110px;
    }

    .hover-submenu1.app.sidenav-toggled-open .side-menu__label {
      right: 0;
    }

    .hover-submenu1.app.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
      border-left: 0 !important;
    }

    .hover-submenu1.app.sidenav-toggled .main-header {
      padding-right: 110px;
    }

    .hover-submenu1.app.sidenav-toggled .app-content {
      margin-right: 110px;
    }
  }

  @media (min-width: 992px) {
    &.app.sidenav-toggled {
      .app-content {
        margin-right: 80px;
        margin-left: 0;
      }

      .main-header {
        padding-right: 80px;
        padding-left: 0px;
      }
    }
  }

  .example {
    .group-btn .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    .group-btn .btn-group>.btn:nth-child(n+3) {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-top-left-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
    }

    .btn-toolbar .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    .btn-toolbar .btn-group>.btn:nth-child(n+2) {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .card-header {
    .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    .btn-group>.btn:nth-child(n+2) {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}

body.rtl {
  text-align: right !important;
}

.rtl .main-body.app.sidebar-mini.rtl.dark-theme.light-header.light-menu {
  .app-sidebar {
    border-left: 1px solid #ededf5;
  }

  .main-sidebar-header {
    border-left: 1px solid #ededf5;
  }
}

.rtl .main-sidebar-header {
  border-left: 1px solid $border;
  border-right: inherit !important;
}

@media (min-width: 992px) {
  .rtl .horizontal .horizontal-main .sub-slide2 .sub-slide-menu1 {
    right: 180px;
  }
}

// @media (max-width: 991px) {
//   .rtl .table.table-bordered.dataTable {
//     border-left-width: 2px !important;
//   }
// }

.rtl.transparent-theme .main-nav .nav-link+.nav-link {
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  border-left: inherit;
}

.rtl .feature-1 {
  .feature {
    i {
      float: right;
    }
  }
}

@media (min-width: 992px) {
  .rtl.horizontal .horizontal-main .sub-slide2 .sub-slide-menu1 {
    right: 180px;
    left: auto;
  }
}

.rtl .featured_icon1 {
  margin-right: -34px !important;
}

.rtl.dark-theme .border-end {
  border-left: 1px solid #3c3c60 !important;
  border-right: inherit !important;
}

.rtl table.table-bordered.dataTable th:first-child,
.rtl table.table-bordered.dataTable th:first-child,
.rtl table.table-bordered.dataTable td:first-child,
.rtl table.table-bordered.dataTable td:first-child {
  border-right-width: 1px !important;
}

.rtl.dark-theme table.table-bordered.dataTable th:first-child,
.rtl.dark-theme table.table-bordered.dataTable th:first-child,
.rtl.dark-theme table.table-bordered.dataTable td:first-child,
.rtl.dark-theme table.table-bordered.dataTable td:first-child {
  border-right-width: 0px !important;
}

.rtl.transparent-theme table.table-bordered.dataTable th:first-child,
.rtl.transparent-theme table.table-bordered.dataTable th:first-child,
.rtl.transparent-theme table.table-bordered.dataTable td:first-child,
.rtl.transparent-theme table.table-bordered.dataTable td:first-child {
  border-right-width: 0px !important;
}

.rtl {
  .icon.ion-ios-arrow-forward {
    transform: rotate(180deg);
  }

  .icon.ion-ios-arrow-back {
    transform: rotate(180deg);
  }

  .bootstrap-tagsinput .badge [data-role="remove"] {
    margin-right: 8px;
    margin-left: inherit;
  }

  .tab_wrapper .content_wrapper .accordian_header.active .arrow {
    transform: rotate(-45deg);
  }

  .tab_wrapper .content_wrapper .accordian_header .arrow {
    transform: rotate(135deg);
  }

  .vtimeline .timeline-wrapper {
    padding-left: 90px;
    padding-right: inherit;
  }

  @media (max-width: 992px) {
    &.dark-theme {
      .vtimeline .timeline-wrapper .timeline-panel:after {
        border-left: 14px solid #353949 !important;
        border-right: 0 solid #2a2e3f !important;
      }
    }

    &.transparent-theme {
      .vtimeline .timeline-wrapper .timeline-panel:after {
        border-left: 14px solid $white-1  !important;
        border-right: 0 solid $white-1  !important;
      }
    }
  }

  div.table-responsive>div.dataTables_wrapper>div.row>div[class^="col-"]:last-child {
    padding-left: 0;
    padding-right: inherit;
  }

  .file-image-1 .icons {
    left: 7px;
    right: inherit;
    text-align: left;
  }

  .main-signup-header {
    .nav.panel-tabs {
      padding-right: 0;
    }
  }

  .smartphoto-dismiss {
    left: 10px;
    right: inherit;
  }

  .smartphoto-dismiss {
    left: 22px !important;
    right: inherit !important;
  }

  .dropify-wrapper.touch-fallback .dropify-preview .dropify-infos .dropify-infos-inner {
    padding: 5px 0px 5px 90px;
  }

  .dropify-wrapper.touch-fallback .dropify-preview .dropify-infos .dropify-infos-inner p {
    text-align: right;
  }

  .tree ul:before {
    right: -10px;
    left: inherit;
    border-right: 1px solid #ededf5;
    border-left: inherit;
  }

  .tree ul {
    margin-right: 1em;
    margin-left: inherit;
  }

  .tree ul li:before {
    right: -10px;
    left: inherit;
  }

  table.dataTable>thead>tr>th:not(.sorting_disabled),
  table.dataTable>thead>tr>td:not(.sorting_disabled) {
    padding-right: 15px;
    padding-left: 30px;
  }

  .dropdown.btn-group>.btn.btn-rounded:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
  }

  .counter-side-badge {
    left: 0;
    right: inherit;
  }

  .counter-side-badge .badge {
    right: -60px;
    left: inherit;
    float: right;
    transform: rotate(45deg);
  }

  .select2-container {
    direction: rtl;
  }

  .note-editor {

    input[type=radio],
    input[type=checkbox] {
      margin-left: 7px;
      margin-right: 0;
    }
  }

  .image-pic {
    right: 5px;
    left: inherit;
  }

  .btn-group.file-attach {
    .btn.rounded-pill:not(:last-child):not(.dropdown-toggle) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 50px !important;
      border-bottom-right-radius: 50px !important;
    }

    .btn-close {
      border-radius: 4px 0 0 4px;

      &.rounded-pill {
        border-radius: 50px 0 0 50px !important;
      }

      &.btn-primary-light {
        border: 1px solid $primary-02;
        border-right: 0;
      }

      &.btn-secondary-light {
        border: 1px solid rgba($secondary, 0.2);
        border-right: 0;
      }

      &.btn-info-light {
        border: 1px solid rgba($info, 0.2);
        border-right: 0;
      }

      &.btn-success-light {
        border: 1px solid rgba($success, 0.2);
        border-right: 0;
      }

      &.btn-warning-light {
        border: 1px solid rgba($warning, 0.2);
        border-right: 0;
      }

      &.btn-danger-light {
        border: 1px solid rgba($danger, 0.2);
        border-right: 0;
      }
    }
  }

  .info-widget h3:before {
    left: 0.5em;
    right: inherit;
  }

  .info-widget h3:after {
    right: 0.5em;
    left: inherit;
  }

  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px;
  }

  .nice-select {
    text-align: right;
  }

  .nice-select .option {
    text-align: right;
  }

  .nice-select:after {
    left: 12px;
    right: inherit;
  }
}

.rtl {

  [type=email],
  [type=number],
  [type=tel],
  [type=url] {
    direction: rtl;
  }
}

.rtl {

  .was-validated .is-loading>.form-control:valid~.spinner-border,
  .was-validated .is-loading>.form-control:valid~.spinner-grow,
  .is-loading>.form-control.is-valid~.spinner-border,
  .is-loading>.form-control.is-valid~.spinner-grow {
    left: calc((1.75rem + 2px) + 3px);
    right: inherit;
  }

  .is-loading>.form-control~.spinner-border,
  .is-loading>.form-control~.spinner-grow,
  .is-loading>.form-control-plaintext~.spinner-border,
  .is-loading>.form-control-plaintext~.spinner-grow,
  .is-loading>.custom-select~.spinner-border,
  .is-loading>.custom-select~.spinner-grow,
  .is-loading>textarea[rows="1"].form-control~.spinner-border,
  .is-loading>textarea[rows="1"].form-control~.spinner-grow {
    left: calc(0.375em + 0.1875rem);
    right: inherit;
  }

  .was-validated .is-loading>.form-control:valid,
  .is-loading>.form-control.is-valid {
    padding-left: calc((1.5em + 0.75rem) + 25px);
    padding-right: 0.75rem;
  }

  .is-loading>.form-control,
  .is-loading>.form-control-plaintext,
  .is-loading>.custom-select,
  .is-loading>textarea[rows="1"].form-control {
    padding-left: calc(1.5em + 0.75rem);
    padding-right: 0.75rem;
  }

  .was-validated .is-loading>.form-control:invalid~.spinner-border,
  .was-validated .is-loading>.form-control:invalid~.spinner-grow,
  .is-loading>.form-control.is-invalid~.spinner-border,
  .is-loading>.form-control.is-invalid~.spinner-grow {
    left: calc((1.75rem + 2px) + 3px);
    right: inherit;
  }

  .is-loading {
    .form-control-plaintext {
      padding-right: 7px;
      padding-left: inherit;
    }

    select option {
      padding-right: 7px;
      padding-left: inherit;
    }
  }

  .input-group {
    .is-loading.spinner-append {
      .form-control {
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }
    }
  }
}

.rtl {
  .dropdown-menu {
    float: right;
    text-align: right;
  }

  .input-group.is-loading> :first-child:not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-radius: 0 5px 5px 0 !important;
  }

  .input-group.is-loading> :not(:last-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-radius: 5px 0 0 5px !important;
  }

  .input-group.input-group-rtl> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .search-icon {
    margin: auto -6px auto 6px !important;
  }

  div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:first-child {
    padding-left: inherit;
    padding-right: 0;
  }

  .breadcrumb-header {
    .breadcrumb-item+.breadcrumb-item::before {
      transform: rotate(180deg);
    }
  }

  table.table-bordered.dataTable th:last-child,
  table.table-bordered.dataTable td:last-child {
    border-left-width: 2px;
  }

  @media screen and (min-width:1389px) {

    table.table-bordered.dataTable th:last-child,
    table.table-bordered.dataTable td:last-child {
      border-left-width: 1px;
    }
  }

  table.table-bordered.dataTable {
    border-left-width: 0;
  }

  #ui_notifIt {
    left: 10px !important;
    right: auto;
  }

  @media (min-width: 768px) {
    .table-responsive.deleted-table .data-table-btn {
      right: 185px;
      left: auto;
    }
  }
  .nav-tabs .nav-link {
    padding: 10px 15px;
  }
}